<template>
  <v-select
    :label="label"
    :value="value"
    :items="options"
    filled
    background-color="transparent"
    hide-details
    readonly
  >
    <template v-slot:append><div></div></template>
  </v-select>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value", "options"],
};
</script>
