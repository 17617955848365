<template>
  <v-dialog :value="show" persistent max-width="800">
    <v-card>
      <v-card-title>
        <span>未确认告警</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title class="py-0">
        <v-btn text class="px-0" @click="orgShow = true">
          <v-icon class="mr-1">mdi-sitemap</v-icon>
          <span>{{ orgName }}</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="
                confirmMode = '-1';
                confirmText = '';
                confirmShow = true;
              "
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </template>
          <span>确认选中</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="
                confirmMode = '0';
                confirmText = '';
                confirmShow = true;
              "
            >
              <v-icon>mdi-check-all</v-icon>
            </v-btn>
          </template>
          <span>确认全部</span>
        </v-tooltip>
        <v-btn icon @click="query(1)">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        v-model="rows"
        dense
        :headers="headers"
        :items="records"
        hide-default-footer
        disable-pagination
        show-select
        style="height: 360px; overflow-y: auto"
      >
        <template v-slot:[`item.eventtype`]="{ item }">
          <span v-if="item.eventtype == 2">监控主机</span>
          <span v-if="item.eventtype == 3">设备</span>
          <span v-if="item.eventtype == 4">测点</span>
        </template>
        <template v-slot:[`item.stateAlarm`]="{ item }">
          <span v-if="item.stateAlarm == 1">超上限告警</span>
          <span v-if="item.stateAlarm == 2">超下限告警</span>
          <span v-if="item.stateAlarm == 3">临上限告警</span>
          <span v-if="item.stateAlarm == 4">临下限告警</span>
          <span v-if="item.stateAlarm == 5">数据异常告警</span>
          <span v-if="item.stateAlarm == 6">状态异常告警</span>
          <span v-if="item.stateAlarm == 7">告警恢复</span>
          <span v-if="item.stateAlarm == 8">联机恢复</span>
          <span v-if="item.stateAlarm == 9">脱机告警</span>
          <span v-if="item.stateAlarm == 10">文本报警</span>
          <span v-if="item.stateAlarm == 11">极上限告警</span>
          <span v-if="item.stateAlarm == 12">极下限告警</span>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-pagination
        v-model="pageIndex"
        :length="pageCount"
        :total-visible="7"
        @input="query($event)"
      ></v-pagination>
      <v-navigation-drawer
        v-model="orgShow"
        temporary
        left
        absolute
        width="240"
      >
        <v-treeview
          :items="orgs"
          :active.sync="selected"
          :open.sync="open"
          item-children="child"
          hoverable
          activatable
          transition
          dense
          @update:active="orgShow = false"
        >
          <template v-slot:prepend="{ item }">
            <v-icon>
              {{ item.orgtype == 1 ? "mdi-home-city" : "mdi-home-variant" }}
            </v-icon>
          </template>
        </v-treeview>
      </v-navigation-drawer>
    </v-card>
    <v-dialog v-model="confirmShow" persistent max-width="400">
      <v-card>
        <v-card-title>
          {{ confirmMode == "-1" ? "确认选中" : "确认全部" }}
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="confirmText"
            label="处理意见"
            rows="5"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="confirm">确定</v-btn>
          <v-btn text @click="confirmShow = false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";

export default {
  model: {
    prop: "show",
    event: "change",
  },
  props: { show: Boolean },
  data() {
    return {
      orgShow: false,
      orgs: [],
      open: [],
      selected: [],

      headers: [
        {
          text: "编号",
          value: "id",
          sortable: false,
          width: 64,
        },
        {
          text: "产生时间",
          value: "datatime",
          sortable: false,
          width: 110,
        },
        {
          text: "告警对象",
          value: "eventtype",
          sortable: false,
          width: 80,
        },
        {
          text: "告警类型",
          value: "stateAlarm",
          sortable: false,
          width: 80,
        },
        {
          text: "告警级别",
          value: "level",
          sortable: false,
          width: 80,
        },
        {
          text: "告警描述",
          value: "description",
          sortable: false,
        },
      ],
      records: [],
      pageIndex: 0,
      pageCount: 0,
      recordCount: 0,

      rows: [],
      confirmMode: "-1",
      confirmText: "",
      confirmShow: false,
    };
  },
  computed: {
    orgName() {
      if (this.selected.length) {
        let id = this.selected[0];
        let org = this.findModel(id, this.orgs);
        if (org) return org.name;
      }
      return "当前机构";
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.initOrganizations();
      },
    },
    selected: {
      handler() {
        this.query(1);
      },
    },
  },
  methods: {
    async initOrganizations() {
      client.$emit(
        "startBusy",
        "UnConfirmAlarmDialog.initOrganizations",
        "正在获取机构,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.organizationMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
            parentId: client.project.id,
          },
        });
        this.deepSort(res.orgs);
        this.orgs = res.orgs;
        if (res.orgs && res.orgs.length) {
          this.open = [res.orgs[0].id];
          this.selected = [res.orgs[0].id];
        } else {
          this.open = [];
          this.selected = [];
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "UnConfirmAlarmDialog.initOrganizations");
    },
    deepSort(orgs) {
      orgs.sort((a, b) => {
        return a.sequence - b.sequence;
      });
      for (let i in orgs) {
        if (orgs[i].child && orgs[i].child.length > 0) {
          this.deepSort(orgs[i].child);
        }
      }
    },
    findModel(id, root) {
      for (let i in root) {
        let model = root[i];
        if (model.id == id) {
          return model;
        }
        if (model.child) {
          let child = this.findModel(id, model.child);
          if (child) {
            return child;
          }
        }
      }
      return null;
    },
    async query(page) {
      let result = [];
      client.$emit(
        "startBusy",
        "UnConfirmAlarmDialog.query",
        "正在获取记录,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.histEventMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            orgid: this.selected[0],
          },
          histeventClause: {
            alarmtype: 0,
            act: 2,
            timeContion: 1,
            begintime: "1970-01-01",
            alarmlevel: 0,
          },
          rpp: 20,
          pageindex: page - 1,
          bContainReset: false,
        });
        this.pageIndex = res.pageindex + 1;
        this.pageCount = res.pagecount || 0;
        this.recordCount = res.recordcount || 0;
        if (res.heo && res.heo.length) {
          res.heo.sort((a, b) => {
            return b.datatime.localeCompare(a.datatime);
          });
          for (let i = 0; i < res.heo.length; i++) {
            result.push(res.heo[i]);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "UnConfirmAlarmDialog.query");
      this.records = result;
    },
    async confirm() {
      client.$emit(
        "startBusy",
        "UnConfirmAlarmDialog.confirm",
        "正在确认,请稍候..."
      );
      await proto.sleep(100);
      try {
        let heo;
        if (this.confirmMode == "-1") {
          heo = this.rows;
        } else {
          heo = [{ id: 0 }];
        }
        for (let i in heo) {
          heo[i].confirmation = this.confirmText;
        }
        await client.send(proto.MESSAGE_TYPE.histEventMessage, {
          mcd: {
            operate: proto.OperateMode.createOpt,
            orgid: this.selected[0],
            range: this.confirmMode,
          },
          histeventClause: {
            alarmtype: 0,
            act: 2,
            timeContion: 1,
            begintime: "1970-01-01",
            alarmlevel: 0,
          },
          rpp: 0,
          heo,
        });
        this.confirmShow = false;
        this.query(1);
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "UnConfirmAlarmDialog.confirm");
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>