<template>
  <div class="main">
    <v-navbar></v-navbar>
    <v-expansion-panels dark class="flex-shrink-0">
      <v-expansion-panel style="background-color: transparent">
        <v-expansion-panel-header>筛选条件</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row align="baseline">
              <v-col cols="6" sm="3" lg="2">
                <v-select
                  label="事件类型"
                  v-model="eventType"
                  :items="eventTypes"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3" lg="2" v-if="eventType == 0">
                <v-select
                  label="告警对象"
                  v-model="alarmObject"
                  :items="alarmObjects"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3" lg="2" v-if="eventType == 0">
                <v-select
                  label="告警类型"
                  v-model="alarmType"
                  :items="alarmTypes"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3" lg="2" v-if="eventType == 0">
                <v-select
                  label="告警级别"
                  v-model="alarmLevel"
                  :items="alarmLevels"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3" lg="4">
                <v-text-field
                  label="告警描述"
                  v-model="decription"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" lg="2">
                <v-select
                  label="时间条件"
                  v-model="condition"
                  :items="conditions"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3" lg="2">
                <v-menu
                  v-model="dateStartShow"
                  :close-on-content-click="false"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateStart"
                      :label="condition == 6 ? '开始日期' : '日期'"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateStart"
                    :max="dateEnd"
                    @input="dateStartShow = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="3" lg="2" v-if="condition == 6">
                <v-menu
                  v-model="dateEndShow"
                  :close-on-content-click="false"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateEnd"
                      label="结束日期"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateEnd"
                    :min="dateStart"
                    @input="dateEndShow = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="3" lg="2">
                <v-btn block color="rgba(255,255,255,0.2)" @click="query(1)">
                  查询
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="scroll flex-grow-1 flex-shrink-1">
      <v-data-table
        :headers="headers"
        :items="records"
        dark
        style="background-color: transparent"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.eventtype`]="{ item }">
          <span>{{ alarmObjectNames(item.eventtype) }}</span>
        </template>
        <template v-slot:[`item.stateAlarm`]="{ item }">
          <span>{{ alarmTypeNames(item.stateAlarm) }}</span>
        </template>
      </v-data-table>
    </div>
    <v-divider></v-divider>
    <v-pagination
      v-model="pageIndex"
      :length="pageCount"
      :total-visible="7"
      @input="query($event)"
    ></v-pagination>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  data() {
    return {
      eventType: 0,
      eventTypes: [
        { text: "系统事件", value: 1 },
        { text: "运维事件", value: 7 },
        { text: "告警事件", value: 0 },
      ],

      alarmObject: 0,
      alarmObjects: [
        { text: "全部", value: 0 },
        { text: "监控单元", value: 2 },
        { text: "设备", value: 3 },
        { text: "测点", value: 4 },
      ],

      alarmType: 0,
      alarmTypes: [
        { text: "全部", value: 0 },
        { text: "极上限告警", value: 11 },
        { text: "极下限告警", value: 12 },
        { text: "超上限告警", value: 1 },
        { text: "超下限告警", value: 2 },
        { text: "临上限告警", value: 3 },
        { text: "临下限告警", value: 4 },
        { text: "数据异常告警", value: 5 },
        { text: "状态异常告警", value: 6 },
        { text: "告警恢复", value: 7 },
        { text: "联机恢复", value: 8 },
        { text: "脱机告警", value: 9 },
        { text: "文本报警", value: 10 },
      ],

      alarmLevel: 0,
      alarmLevels: [
        { text: "全部", value: 0 },
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
        { text: "4", value: 4 },
        { text: "5", value: 5 },
        { text: "6", value: 6 },
        { text: "7", value: 7 },
        { text: "8", value: 8 },
        { text: "9", value: 9 },
        { text: "10", value: 10 },
      ],

      decription: "",

      condition: 1,
      conditions: [
        { text: "=", value: 1 },
        { text: ">", value: 2 },
        { text: ">=", value: 4 },
        { text: "<", value: 3 },
        { text: "<=", value: 5 },
        { text: "时间段", value: 6 },
      ],

      dateStartShow: false,
      dateStart: undefined,
      dateEndShow: false,
      dateEnd: undefined,

      recordType: 0,
      records: [],
      pageIndex: 0,
      pageCount: 0,
      recordCount: 0,
    };
  },
  computed: {
    headers: function () {
      if (this.recordType == 0) {
        return [
          {
            text: "产生时间",
            value: "datatime",
            sortable: false,
          },
          {
            text: "告警对象",
            value: "eventtype",
            sortable: false,
          },
          {
            text: "告警类型",
            value: "stateAlarm",
            sortable: false,
          },
          {
            text: "告警级别",
            value: "level",
            sortable: false,
          },
          {
            text: "告警描述",
            value: "description",
            sortable: false,
          },
          {
            text: "确认人",
            value: "hander",
            sortable: false,
          },
          {
            text: "确认时间",
            value: "handledate",
            sortable: false,
          },
          {
            text: "处理意见",
            value: "confirmation",
            sortable: false,
          },
        ];
      } else {
        return [
          {
            text: "产生时间",
            value: "datatime",
            sortable: false,
          },
          {
            text: "事件描述",
            value: "description",
            sortable: false,
          },
          {
            text: "确认人",
            value: "hander",
            sortable: false,
          },
          {
            text: "确认时间",
            value: "handledate",
            sortable: false,
          },
          {
            text: "处理意见",
            value: "confirmation",
            sortable: false,
          },
        ];
      }
    },
  },
  mounted() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) month = "0" + month;
    let day = date.getDate();
    if (day < 10) day = "0" + day;
    this.dateStart = this.dateEnd = `${year}-${month}-${day}`;
    client.$on("orgChange", this.orgChange);
    this.orgChange();
  },
  beforeDestroy() {
    client.$off("orgChange", this.orgChange);
  },
  methods: {
    orgChange() {
      this.records = [];
      this.pageIndex = 0;
      this.pageCount = 0;
      this.recordCount = 0;
      this.query(1);
    },
    async query(page) {
      let result = [];
      client.$emit(
        "startBusy",
        "HistoryEvent.query",
        "正在获取记录,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.histEventMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
          histeventClause: {
            timeContion: this.condition,
            begintime: this.dateStart,
            endtime: this.dateEnd,
            eventtype: this.eventType == 0 ? this.alarmObject : this.eventType,
            alarmtype: this.eventType == 0 ? this.alarmType : 0,
            decription: this.decription,
            act: 2,
            alarmlevel: this.alarmLevel,
          },
          rpp: 20,
          pageindex: page - 1,
          bContainReset: true,
        });
        this.recordType = this.eventType;
        this.pageIndex = res.pageindex + 1;
        this.pageCount = res.pagecount || 0;
        this.recordCount = res.recordcount || 0;
        if (res.heo && res.heo.length) {
          res.heo.sort((a, b) => {
            return b.datatime.localeCompare(a.datatime);
          });
          for (let i = 0; i < res.heo.length; i++) {
            let record = res.heo[i];
            delete record.id;
            result.push(record);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "HistoryEvent.query");
      this.records = result;
    },
    alarmObjectNames(value) {
      switch (value) {
        case 2:
          return "监控单元";
        case 3:
          return "设备";
        case 4:
          return "测点";
      }
      return "未知";
    },
    alarmTypeNames(value) {
      switch (value) {
        case 1:
          return "超上限告警";
        case 2:
          return "超下限告警";
        case 3:
          return "临上限告警";
        case 4:
          return "临下限告警";
        case 5:
          return "数据异常告警";
        case 6:
          return "状态异常告警";
        case 7:
          return "告警恢复";
        case 8:
          return "联机恢复";
        case 9:
          return "脱机告警";
        case 10:
          return "文本报警";
        case 11:
          return "极上限告警";
        case 12:
          return "极下限告警";
      }
      return "未知";
    },
  },
};
</script>
