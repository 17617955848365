<template>
  <v-text-field
    :label="label"
    :value="valueDisplay"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>{{ label }}编辑</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mb-3"
                  @click="scriptText = templates"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-file-restore-outline</v-icon>
                </v-btn>
              </template>
              <div>模板</div>
            </v-tooltip>
            <v-textarea
              outlined
              no-resize
              rows="10"
              label="脚本编辑"
              v-model="scriptText"
              hide-details
            ></v-textarea>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      show: false,
      scriptText: "",
      templates: `function main()
{
    /********************
    1: upper limit
    2: lower limit
    3: near up limit
    4: near low limit
    5: except data
    6: state change
    7: alarm reset
    8: online
    9: offline
    10: text alarm
    11: ultra hig
    12: ultra low
    ***********************/
    var buffer = commObject.GetDevAlarms();

    //check the return buffer length
    if (buffer.length<2)
    {
        print("return buffer length error!");
        return false;
    }

    //return the results after computation
    return (buffer[0] == 1 ||  buffer[1] == 1);
}
`,
    };
  },
  computed: {
    valueDisplay() {
      if (this.value) return "已配置";
      else return "未配置";
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.scriptText = this.value;
        }
      },
    },
  },
  methods: {
    submit() {
      this.$emit("change", this.scriptText);
      this.show = false;
    },
  },
};
</script>
