<template>
  <v-dialog v-model="show" persistent max-width="480">
    <v-card>
      <v-card-title>添加绑定</v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="uuid"
          label="控件"
          item-value="uuid"
          item-text="name"
          :items="controls"
        ></v-autocomplete>
        <v-autocomplete
          v-model="prop"
          label="属性"
          :items="props"
        ></v-autocomplete>
        <v-select v-model="srcType" label="源类型" :items="srcTypes"></v-select>
        <v-select
          v-model="valueType"
          label="数据类型"
          :items="valueTypes"
        ></v-select>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain @click="ok">确定</v-btn>
        <v-btn plain @click="cancel">取消</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import client from "../utils/client";
import editors from "../zht-ui/editor";
import uuid from "../utils/uuid";
export default {
  props: { controls: Array },
  data() {
    return {
      show: false,
      uuid: "",
      prop: "",
      props: [],
      srcTypes: [
        { text: "机构", value: "org" },
        { text: "监控单元", value: "agent" },
        { text: "设备", value: "device" },
        { text: "测点", value: "spot" },
        { text: "实时统计", value: "statistics" },
        { text: "API", value: "api" },
      ],
      srcType: "",
      valueTypes: [],
      valueType: "",
    };
  },
  watch: {
    uuid: function() {
      this.prop = "";
      let result = [];
      if (this.uuid) {
        for (let i in this.controls) {
          if (this.controls[i].uuid == this.uuid) {
            let control = this.controls[i];
            let bases = ["size", "location", "style", "config"];
            for (let j in bases) {
              let base = bases[j];
              for (let attr in control[base]) {
                let value = `${base}.${attr}`;
                if (editors[value]) {
                  let text = editors[value].name;
                  result.push({ text, value });
                }
              }
            }
            break;
          }
        }
      }
      this.props = result;
    },
    srcType: async function() {
      this.valueType = "";
      let result = [];
      if (this.srcType) {
        switch (this.srcType) {
          case "org":
            result.push({ text: "告警状态", value: "alarm" });
            break;
          case "agent":
            result.push({ text: "告警状态", value: "alarm" });
            result.push({ text: "联机状态", value: "status" });
            break;
          case "device":
            result.push({ text: "告警状态", value: "alarm" });
            result.push({ text: "联机状态", value: "status" });
            break;
          case "spot":
            result.push({ text: "告警状态", value: "alarm" });
            result.push({ text: "实时数据", value: "data" });
            result.push({ text: "历史数据", value: "history" });
            break;
          case "statistics":
            result.push({ text: "实时统计值", value: "statistics" });
            break;
          case "api":
            result.push({
              text: "客户端脚本",
              value: "客户端脚本",
            });
            try {
              let host = client.getAddress();
              let res = await axios.post(`${host}/api/list`);
              if (res.data && res.data.api) {
                for (let i in res.data.api) {
                  result.push({
                    text: res.data.api[i],
                    value: res.data.api[i],
                  });
                }
              }
            } catch (e) {
              console.log(e);
            }
            break;
        }
      }
      this.valueTypes = result;
    },
  },
  mounted() {
    this.show = true;
  },
  methods: {
    ok() {
      if (!this.uuid || !this.prop || !this.srcType || !this.valueType) {
        client.$emit("toast", "配置不完整");
        return;
      }
      this.show = false;
      this.$emit("close", {
        id: uuid(),
        uuid: this.uuid,
        prop: this.prop,
        srcType: this.srcType,
        valueType: this.valueType,
        src: [],
        JScript: "return values.join(',')",
        interval: "",
        param: "",
      });
    },
    cancel() {
      this.show = false;
      this.$emit("close", null);
    },
  },
};
</script>
