<template>
  <div class="main">
    <v-navbar></v-navbar>
    <v-container fluid class="flex-grow-1">
      <v-row class="fill-height">
        <v-col cols="5" sm="3">
          <v-card
            dark
            color="rgba(255,255,255,0.1)"
            class="fill-height d-flex flex-column"
          >
            <v-card-title>
              <span>接警组</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="groupAdd">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>
            <div class="scroll-panel">
              <v-list
                class="scroll-content"
                style="background-color: transparent"
              >
                <v-list-item-group v-model="group" @change="getPersons">
                  <template v-for="item in groups">
                    <v-divider :key="`d-${item.id}`"></v-divider>
                    <v-list-item :key="item.id">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="my-0">
                        <div>
                          <v-btn icon @click="groupEdit(item)">
                            <v-icon>mdi-square-edit-outline</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            @click="
                              deleteGroup.model = item;
                              deleteGroup.show = true;
                            "
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </div>
          </v-card>
        </v-col>
        <v-col cols="7" sm="9">
          <v-card
            dark
            color="rgba(255,255,255,0.1)"
            class="fill-height d-flex flex-column"
          >
            <v-card-title>
              <span>用户</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="addPerson.show = group != undefined">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>
            <div class="scroll-panel">
              <v-data-table
                :headers="headers"
                :items="persons"
                class="scroll-content"
                style="background-color: transparent"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn icon @click="up(item)">
                    <v-icon>mdi-arrow-up</v-icon>
                  </v-btn>
                  <v-btn icon @click="down(item)">
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click="
                      deletePerson.model = item;
                      deletePerson.show = true;
                    "
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-confirm
      v-model="deleteGroup.show"
      label="是否删除接警组?"
      @ok="groupDelete"
    ></v-confirm>
    <v-confirm
      v-model="deletePerson.show"
      label="是否移除用户?"
      @ok="personDelete"
    ></v-confirm>
    <v-dialog v-model="editGroup.show" persistent width="360">
      <v-card v-if="editGroup.model">
        <v-card-title>
          {{ editGroup.model.id ? "编辑" : "添加" }}接警组
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="名称"
            v-model="editGroup.model.name"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="groupSubmit">确定</v-btn>
          <v-btn text @click="editGroup.show = false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addPerson.show" persistent width="480">
      <v-card>
        <v-card-title>添加用户</v-card-title>
        <v-data-table
          v-model="addPerson.person"
          :headers="addPerson.headers"
          :items="addPerson.persons"
          item-key="uuid"
          show-select
        ></v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="personAdd">确定</v-btn>
          <v-btn text @click="addPerson.show = false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
import uuid from "../utils/uuid";
export default {
  data() {
    return {
      headers: [
        { text: "姓名", value: "name" },
        { text: "所属机构", value: "orgname" },
        { text: "语音号码", value: "telephone" },
        { text: "短信号码", value: "mobilephone" },
        { text: "邮箱地址", value: "email" },
        { text: "操作", value: "actions", sortable: false, align: "right" },
      ],
      group: undefined,
      groups: [],
      persons: [],

      editGroup: {
        show: false,
        model: null,
      },

      deleteGroup: {
        show: false,
        model: null,
      },

      addPerson: {
        show: false,
        headers: [
          { text: "姓名", value: "name" },
          { text: "所属机构", value: "orgname" },
        ],
        person: [],
        persons: [],
      },

      deletePerson: {
        show: false,
        model: null,
      },
    };
  },
  mounted() {
    client.$on("orgChange", this.getGroups);
    this.getGroups();
  },
  beforeDestroy() {
    client.$off("orgChange", this.getGroups);
  },
  watch: {
    "addPerson.show": {
      handler() {
        if (this.addPerson.show) {
          this.addPerson.person = [];
          if (this.addPerson.persons.length == 0) {
            this.getAddPersons();
          }
        }
      },
    },
  },
  methods: {
    async getGroups() {
      let result = [];
      client.$emit(
        "startBusy",
        "ConfigReceive.getGroups",
        "正在获取接警组,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(
          proto.MESSAGE_TYPE.receivergroupMessage,
          {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
              range: "0",
            },
          }
        );
        if (res.groups && res.groups.length) {
          res.groups.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.groups.length; i++) {
            let group = res.groups[i];
            result.push(group);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigReceive.getGroups");
      this.groups = result;
    },
    async getPersons() {
      let result = [];
      if (this.group !== undefined) {
        client.$emit(
          "startBusy",
          "ConfigReceive.getPersons",
          "正在获取用户,请稍候..."
        );
        await proto.sleep(100);
        try {
          let res = await client.send(proto.MESSAGE_TYPE.personMessage, {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
              range: "0",
              parentId: this.groups[this.group].id,
            },
            orgGroup: 2,
          });
          if (res.Persons && res.Persons.length) {
            for (let i = 0; i < res.Persons.length; i++) {
              let person = res.Persons[i];
              result.push(person);
            }
          }
        } catch (error) {
          client.$emit("toast", error);
        }
        client.$emit("endBusy", "ConfigReceive.getPersons");
      }
      this.persons = result;
    },
    async getAddPersons() {
      let result = [];
      client.$emit(
        "startBusy",
        "ConfigReceive.getAddPersons",
        "正在获取用户,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.personMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
          orgGroup: 0,
        });
        if (res.Persons && res.Persons.length) {
          res.Persons.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.Persons.length; i++) {
            let person = res.Persons[i];
            result.push(person);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigReceive.getAddPersons");
      this.addPerson.persons = result;
    },
    groupAdd() {
      this.editGroup.model = {
        id: undefined,
        name: "接警组",
        description: "",
        orgid: client.org.id,
        persons: [],
      };
      this.editGroup.show = true;
    },
    groupEdit(item) {
      this.editGroup.model = JSON.parse(JSON.stringify(item));
      this.editGroup.show = true;
    },
    async groupSubmit() {
      let model = JSON.parse(JSON.stringify(this.editGroup.model));
      let operate;
      if (model.id) {
        operate = proto.OperateMode.updateOpt;
      } else {
        operate = proto.OperateMode.createOpt;
        model.id = uuid();
      }
      client.$emit(
        "startBusy",
        "ConfigReceive.groupSubmit",
        "正在提交接警组,请稍候..."
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.receivergroupMessage, {
          mcd: {
            operate,
            parentId: client.project.id,
          },
          groups: [model],
        });
        this.getGroups();
        this.editGroup.show = false;
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigReceive.groupSubmit");
    },
    async groupDelete() {
      client.$emit(
        "startBusy",
        "ConfigReceive.groupDelete",
        "正在删除接警组,请稍候..."
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.receivergroupMessage, {
          mcd: {
            operate: proto.OperateMode.deleteOpt,
            range: "-1",
          },
          groups: [this.deleteGroup.model],
        });
        this.getGroups();
        this.deleteGroup.show = false;
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigReceive.groupDelete");
    },
    async personAdd() {
      if (this.addPerson.person.length) {
        let group = JSON.parse(JSON.stringify(this.groups[this.group]));
        group.persons = [];
        for (let i in this.addPerson.person) {
          let person = this.addPerson.person[i];
          let exist = false;
          for (let j in this.persons) {
            if (this.persons[j].uuid == person.uuid) {
              exist = true;
              break;
            }
          }
          if (!exist) {
            group.persons.push({ id: person.uuid });
          }
        }
        if (group.persons.length) {
          client.$emit(
            "startBusy",
            "ConfigReceive.personAdd",
            "正在添加用户,请稍候..."
          );
          await proto.sleep(100);
          try {
            await client.send(proto.MESSAGE_TYPE.receivergroupMessage, {
              mcd: {
                operate: proto.OperateMode.addOpt,
              },
              groups: [group],
            });
            this.getPersons();
          } catch (error) {
            client.$emit("toast", error);
          }
          client.$emit("endBusy", "ConfigReceive.personAdd");
        }
      }
      this.addPerson.show = false;
    },
    up(item) {
      for (let i = 0; i < this.persons.length; i++) {
        if (this.persons[i].uuid == item.uuid) {
          if (i > 0) {
            this.persons.splice(i, 1);
            this.persons.splice(i - 1, 0, item);
            this.personEdit();
          }
          break;
        }
      }
    },
    down(item) {
      for (let i = 0; i < this.persons.length; i++) {
        if (this.persons[i].uuid == item.uuid) {
          if (i < this.persons.length - 1) {
            this.persons.splice(i, 1);
            this.persons.splice(i + 1, 0, item);
            this.personEdit();
          }
          break;
        }
      }
    },
    async personEdit() {
      let group = JSON.parse(JSON.stringify(this.groups[this.group]));
      group.persons = [];
      for (let i = 0; i < this.persons.length; i++) {
        group.persons.push({
          id: this.persons[i].uuid,
          pid: (i + 1).toString(),
        });
      }
      client.$emit(
        "startBusy",
        "ConfigReceive.personEdit",
        "正在调整用户顺序,请稍候..."
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.receivergroupMessage, {
          mcd: {
            operate: proto.OperateMode.modifyOpt,
          },
          groups: [group],
        });
        this.getPersons();
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigReceive.personEdit");
    },
    async personDelete() {
      let group = JSON.parse(JSON.stringify(this.groups[this.group]));
      group.persons = [
        {
          id: this.deletePerson.model.uuid,
        },
      ];
      client.$emit(
        "startBusy",
        "ConfigReceive.personDelete",
        "正在移除用户,请稍候..."
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.receivergroupMessage, {
          mcd: {
            operate: proto.OperateMode.removeOpt,
          },
          groups: [group],
        });
        this.getPersons();
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigReceive.personDelete");
    },
  },
};
</script>
