<template>
  <v-text-field
    :label="label"
    :value="valueDisplay"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>{{ label }}编辑</v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="editModel">
            <template v-if="tasktype == 2">
              <v-select
                label="巡检类型"
                v-model="editModel.srctype"
                :items="srctypes"
              ></v-select>
              <div>巡检内容</div>
              <v-card class="px-3">
                <v-chip-group
                  v-model="editModel.cps"
                  active-class="primary--text"
                  multiple
                  column
                >
                  <v-chip v-show="false"></v-chip>
                  <v-chip>当前测点告警总数</v-chip>
                  <v-chip>告警级别分类统计</v-chip>
                  <v-chip>告警类型分类统计</v-chip>
                  <v-chip v-show="editModel.srctype == 1">禁用数量</v-chip>
                  <v-chip>离线数量</v-chip>
                  <v-chip>在线数量</v-chip>
                </v-chip-group>
              </v-card>
              <div class="d-flex align-center">
                <span>巡检对象</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="selectorShow = true">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
              <v-card>
                <v-data-table :headers="headers" :items="editModel.ids" dense>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn icon @click="remove(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </template>
            <template v-if="tasktype != 5">
              <zht-editor-object
                label="短信发送器"
                :options="{ type: 'notifier' }"
                v-model="editModel.smsnotifier"
              ></zht-editor-object>
              <zht-editor-object
                label="邮件发送器"
                :options="{ type: 'notifier' }"
                v-model="editModel.emailnotifier"
              ></zht-editor-object>
              <zht-editor-object
                label="微信发送器"
                :options="{ type: 'notifier' }"
                v-model="editModel.wechatnotifier"
              ></zht-editor-object>
              <zht-editor-object
                label="接收用户"
                :options="{ type: 'person' }"
                v-model="editModel.persons"
              ></zht-editor-object>
            </template>
            <template v-if="tasktype == 5">
              <div class="d-flex align-center">
                <span>控制测点</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="selectorShow = true">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
              <v-card>
                <v-data-table :headers="headers" :items="editModel.ids" dense>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn icon @click="remove(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </template>
            <zht-editor-text
              v-if="tasktype >= 5"
              label="内容"
              v-model="editModel.content"
            ></zht-editor-text>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ObjectSelector
        v-model="selectorShow"
        :type="objectType"
        :single="false"
        @add="add"
      ></ObjectSelector>
    </template>
  </v-text-field>
</template>

<script>
import ObjectSelector from "./ObjectSelector.vue";
export default {
  components: {
    ObjectSelector,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value", "model"],
  data() {
    return {
      show: false,
      editModel: null,
      srctypes: [
        { text: "监控主机", value: 1 },
        { text: "设备", value: 2 },
      ],
      headers: [
        { text: "名称", value: "name", sortable: false },
        { text: "操作", value: "actions", sortable: false, align: "end" },
      ],
      selectorShow: false,
    };
  },
  computed: {
    tasktype() {
      return this.model.tasktype;
    },
    objectType() {
      if (this.tasktype == 5) {
        return "spot";
      } else if (this.editModel) {
        return this.editModel.srctype == 1 ? "agent" : "device";
      }
      return "";
    },
    valueDisplay() {
      if (!this.value) return "未配置";
      switch (this.tasktype) {
        case 1:
        case 2:
        case 4:
        case 6:
          return this.value.persons.length ? "已配置" : "未配置";
        case 5:
          return this.value.ids.length ? "已配置" : "未配置";
        default:
          return "";
      }
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          let model = this.value || {
            cps: [],
            srctype: 1,
            ids: [],
            smsnotifier: { id: "", name: "" },
            emailnotifier: { id: "", name: "" },
            persons: [],
            content: "",
            wechatnotifier: { id: "", name: "" },
          };
          this.editModel = JSON.parse(JSON.stringify(model));
        }
      },
    },
  },
  methods: {
    add(item) {
      for (let i in this.editModel.ids) {
        if (this.editModel.ids[i].id == item.id) {
          return;
        }
      }
      this.editModel.ids.push(item);
    },
    remove(item) {
      for (let i = 0; i < this.editModel.ids.length; i++) {
        if (this.editModel.ids[i].id == item.id) {
          this.editModel.ids.splice(i, 1);
        }
      }
    },
    submit() {
      this.$emit("change", this.editModel);
      this.show = false;
    },
  },
};
</script>
