import { render, staticRenderFns } from "./MonitorDevice.vue?vue&type=template&id=61204682&scoped=true&"
import script from "./MonitorDevice.vue?vue&type=script&lang=js&"
export * from "./MonitorDevice.vue?vue&type=script&lang=js&"
import style0 from "./MonitorDevice.vue?vue&type=style&index=0&id=61204682&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61204682",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBreadcrumbs,VBreadcrumbsItem,VBtn,VDataTable,VIcon,VTextField,VTooltip})
