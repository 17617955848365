<template>
  <v-dialog :value="show" persistent max-width="480">
    <v-card>
      <v-card-title>
        <div>AI语音选择</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="max-height: 360px; overflow-y: auto">
        <v-select label="声音" v-model="voice" :items="voices"></v-select>
        <v-slider
          label="语速"
          v-model="rate"
          :max="10"
          :min="0.1"
          :step="0.1"
          thumb-label
        ></v-slider>
        <v-slider
          label="音高"
          v-model="pitch"
          :max="10"
          :min="0.1"
          :step="0.1"
          thumb-label
        ></v-slider>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="submit">确定</v-btn>
        <v-btn text @click="close">取消</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../utils/store";
export default {
  model: {
    prop: "show",
    event: "change",
  },
  props: { show: Boolean, model: Object },
  data() {
    return {
      voice: "zh-CN-YunyangNeural",
      rate: 1,
      pitch: 1,
      voices: [
        {
          text: "晓北--女--辽宁",
          value: "zh-CN-liaoning-XiaobeiNeural",
        },
        {
          text: "晓辰--女--大陆",
          value: "zh-CN-XiaochenNeural",
        },
        {
          text: "晓涵--女--大陆",
          value: "zh-CN-XiaohanNeural",
        },
        {
          text: "晓梦--女--大陆",
          value: "zh-CN-XiaomengNeural",
        },
        {
          text: "晓墨--女--大陆",
          value: "zh-CN-XiaomoNeural",
        },
        {
          text: "晓妮--女--陕西",
          value: "zh-CN-shaanxi-XiaoniNeural",
        },
        {
          text: "晓秋--女--大陆",
          value: "zh-CN-XiaoqiuNeural",
        },
        {
          text: "晓睿--女--大陆",
          value: "zh-CN-XiaoruiNeural",
        },
        {
          text: "晓双--女--大陆",
          value: "zh-CN-XiaoshuangNeural",
        },
        {
          text: "晓晓--女--大陆",
          value: "zh-CN-XiaoxiaoNeural",
        },
        {
          text: "晓萱--女--大陆",
          value: "zh-CN-XiaoxuanNeural",
        },
        {
          text: "晓颜--女--大陆",
          value: "zh-CN-XiaoyanNeural",
        },
        {
          text: "晓伊--女--大陆",
          value: "zh-CN-XiaoyiNeural",
        },
        {
          text: "晓悠--女--大陆",
          value: "zh-CN-XiaoyouNeural",
        },
        {
          text: "晓甄--女--大陆",
          value: "zh-CN-XiaozhenNeural",
        },
        {
          text: "曉佳--女--香港",
          value: "zh-HK-HiuGaaiNeural",
        },
        {
          text: "曉曼--女--香港",
          value: "zh-HK-HiuMaanNeural",
        },
        {
          text: "曉雨--女--台湾",
          value: "zh-TW-HsiaoYuNeural",
        },
        {
          text: "曉臻--女--台湾",
          value: "zh-TW-HsiaoChenNeural",
        },
        {
          text: "云登--男--河南",
          value: "zh-CN-henan-YundengNeural",
        },
        {
          text: "云枫--男--大陆",
          value: "zh-CN-YunfengNeural",
        },
        {
          text: "云皓--男--大陆",
          value: "zh-CN-YunhaoNeural",
        },
        {
          text: "云健--男--大陆",
          value: "zh-CN-YunjianNeural",
        },
        {
          text: "云希--男--大陆",
          value: "zh-CN-YunxiNeural",
        },
        {
          text: "云希--男--四川",
          value: "zh-CN-sichuan-YunxiNeural",
        },
        {
          text: "云夏--男--大陆",
          value: "zh-CN-YunxiaNeural",
        },
        {
          text: "云翔--男--山东",
          value: "zh-CN-shandong-YunxiangNeural",
        },
        {
          text: "云扬--男--大陆",
          value: "zh-CN-YunyangNeural",
        },
        {
          text: "云野--男--大陆",
          value: "zh-CN-YunyeNeural",
        },
        {
          text: "云泽--男--大陆",
          value: "zh-CN-YunzeNeural",
        },
        {
          text: "雲龍--男--香港",
          value: "zh-HK-WanLungNeural",
        },
        {
          text: "雲哲--男--台湾",
          value: "zh-TW-YunJheNeural",
        },
      ],
    };
  },
  computed: {
    isEdit() {
      return this.model.uuid !== undefined;
    },
  },
  watch: {
    show: function() {
      if (this.show) {
        this.voice = store.get("voice") || "zh-CN-YunyangNeural";
        this.rate = parseFloat(store.get("rate") || "1");
        this.pitch = parseFloat(store.get("pitch") || "1");
      }
    },
  },
  methods: {
    submit() {
      this.$emit("change", false);
      store.set("voice", this.voice);
      store.set("rate", this.rate);
      store.set("pitch", this.pitch);
      this.$emit("voice", {
        voice: this.voice,
        rate: this.rate,
        pitch: this.pitch,
      });
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>
