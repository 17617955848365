<template>
  <v-text-field
    :label="label"
    :value="valueDisplay"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>
            <div>{{ label }}编辑</div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-toolbar flat>
            <v-select
              label="对象类型"
              v-model="type"
              :items="types"
              hide-details
              outlined
              dense
              @change="items = []"
            ></v-select>
            <v-btn icon @click="selectorShow = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-header
            :hide-default-footer="singleSelect"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon @click="remove(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
          <ObjectSelector
            v-model="selectorShow"
            :type="objectType"
            :single="singleSelect"
            @select="select"
            @add="add"
          ></ObjectSelector>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import ObjectSelector from "./ObjectSelector.vue";
export default {
  components: {
    ObjectSelector,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      show: false,

      headers: [
        { text: "名称", value: "name" },
        { text: "操作", value: "actions", align: "end" },
      ],
      items: [],

      type: 1,
      types: [
        { text: "监控主机", value: 1 },
        { text: "设备", value: 2 },
        { text: "测点", value: 3 },
      ],

      selectorShow: false,
    };
  },
  computed: {
    objectType() {
      if (this.type == 1) return "agent";
      if (this.type == 2) return "device";
      return "spot";
    },
    valueDisplay() {
      let list;
      if (this.singleSelect) list = [this.value];
      else list = this.value;
      let names = [];
      for (let i in list) {
        names.push(list[i].name);
      }
      return names.join(",");
    },
    singleSelect() {
      return Object.prototype.toString.call(this.value) !== "[object Array]";
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          let list;
          if (this.singleSelect) list = [this.value];
          else list = this.value;
          if (list.length) this.type = list[0].linkType;
          this.items = JSON.parse(JSON.stringify(list));
        }
      },
    },
  },
  methods: {
    select(item) {
      this.items = [{ id: item.id, name: item.name, linkType: this.type }];
    },
    add(item) {
      for (let i in this.items) {
        if (this.items[i].id == item.id) {
          return;
        }
      }
      this.items.push({ id: item.id, name: item.name, linkType: this.type });
    },
    remove(item) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id == item.id) {
          this.items.splice(i, 1);
        }
      }
    },
    submit() {
      if (this.singleSelect) {
        if (this.items.length) {
          this.$emit("change", this.items[0]);
        } else {
          this.$emit("change", { id: "", name: "", linkType: 1 });
        }
      } else {
        this.$emit("change", this.items);
      }
      this.show = false;
    },
  },
};
</script>
