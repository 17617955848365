<style>
.login-bg {
  background-image: url("./assets/login-bg.jpg") !important;
  background-size: cover !important;
  background-position: center !important;
}
.bg {
  background-image: linear-gradient(
      to right bottom,
      rgba(16, 71, 156, 0.5),
      rgba(61, 204, 85, 0.3)
    ),
    url("./assets/bg.jpg") !important;
  background-size: cover !important;
  background-position: center !important;
}
.bg-editor {
  background-image: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url("./assets/texture.jpg");
}
.alarm {
  position: fixed;
  right: 12px;
  bottom: 8px;
}
.alarm .v-alert {
  padding: 4px 8px;
  margin-bottom: 4px;
}
.main {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.pointer {
  cursor: pointer;
}
.scroll {
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
}
.scroll-panel {
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
}
.scroll-content {
  position: absolute;
  left: 0;
  right: 0;
}
.link-text {
  height: auto !important;
  min-width: 0 !important;
  padding: 0 !important;
  margin-left: 8px;
}
.version {
  position: absolute;
  bottom: 12px;
  text-align: center;
  color: white;
  width: 100%;
}
/** 地图隐藏标记 */
.anchorBL {
  display: none !important;
}
</style>

<template>
  <v-app class="bg">
    <div class="version" v-if="showLogin">
      Ver {{ app.version }}.{{ buildno }}
    </div>
    <Welcome :show="showWelcome"></Welcome>
    <Login :show="showLogin"></Login>
    <OrgDrawer :hide="!showMenu"></OrgDrawer>
    <ViewDrawer v-if="showMenu"></ViewDrawer>
    <!-- navigation -->
    <v-toolbar
      v-if="showToolbar"
      dark
      color="rgba(255,255,255,0.2)"
      class="flex-grow-0"
      style="z-index: 2"
    >
      <v-btn icon @click="globalEmit('showMenu')">
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      <v-btn
        text
        class="px-0"
        @click="globalEmit('showMenu')"
        v-text="viewName"
      ></v-btn>
      <v-spacer></v-spacer>
      <div style="flex: 0 0 auto">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="needUpdate"
              icon
              v-bind="attrs"
              v-on="on"
              @click="showUpdateTips"
            >
              <v-badge color="error" dot overlap>
                <v-icon>mdi-new-box</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>发现新版本，请刷新此页面以获取最新体验。</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
              @click="infoShow = true"
              v-text="account"
            ></v-btn>
          </template>
          <span>用户信息</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="showLogout = true">
              mdi-power
            </v-icon>
          </template>
          <span>登出</span>
        </v-tooltip>
      </div>
    </v-toolbar>
    <!-- views -->
    <component v-if="showView" :is="viewId"></component>
    <!-- busy -->
    <v-overlay
      :value="busy.length || connecting || reconnecting"
      z-index="999999"
    >
      <div class="d-flex flex-column align-center">
        <v-progress-circular
          indeterminate
          size="64"
          width="8"
        ></v-progress-circular>
        <h3 v-show="connecting">正在连接，请稍候...</h3>
        <h3 v-show="reconnecting">正在重连，请稍候...</h3>
        <v-btn v-show="connecting" class="my-3" text @click="logout">
          取消
        </v-btn>
        <v-btn v-show="reconnecting" class="my-3" text @click="logout">
          返回登录
        </v-btn>
        <h3 v-for="item in busy" :key="item.key">
          {{ item.message }}
        </h3>
      </div>
    </v-overlay>
    <!-- alarm -->
    <div class="d-flex flex-column align-end alarm">
      <v-alert
        v-for="alarm in alarms"
        :key="alarm.key"
        :value="alarm.show"
        :color="alarm.color"
        dark
        transition="slide-x-reverse-transition"
        style="opacity: 0.75"
      >
        <div class="d-flex align-start flex-nowrap">
          <div class="flex-grow-1 mr-1">{{ alarm.message }}</div>
          <v-btn icon x-small @click="dismiss(alarm)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-alert>
    </div>
    <!-- toast -->
    <v-snackbar
      v-model="toast.show"
      :color="toast.color"
      centered
      rounded="pill"
      style="z-index: 1000000"
    >
      <span v-text="` ${toast.message} `"></span>
      <template v-slot:action="{ attrs }">
        <v-icon v-bind="attrs" @click="toast.show = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <!-- dialog -->
    <UserInfoDialog v-model="infoShow"></UserInfoDialog>
    <v-confirm
      v-model="showLogout"
      label="是否退出用户?"
      @ok="logout"
    ></v-confirm>
    <!-- NoProject-->
    <div
      :style="{ display: showNoProject ? 'flex' : 'none' }"
      class="flex-grow-1 flex-column align-center justify-center"
    >
      <h1 class="white--text">未找到工程，请从iShow中配置工程</h1>
      <v-btn dark class="my-3" text @click="logout">返回登录</v-btn>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import proto from "./utils/proto";
import client from "./utils/client";
import Welcome from "./components/Welcome.vue";
import Login from "./components/Login.vue";
import OrgDrawer from "./components/OrgDrawer.vue";
import ViewDrawer from "./components/ViewDrawer.vue";
import UserInfoDialog from "./components/UserInfoDialog.vue";
import app from "../package.json";
import buildno from "./buildno";

export default {
  components: { Welcome, Login, OrgDrawer, ViewDrawer, UserInfoDialog },
  data() {
    return {
      app: app,
      buildno,
      toast: {
        show: false,
        message: "",
        color: "error",
      },
      alarms: [],
      busy: [],
      isFull: false,
      showLogout: false,
      infoShow: false,

      modelShow: {
        Welcome: true,
        Login: false,
        OrgDrawer: false,
        ViewDrawer: false,
        Toolbar: false,
        Views: false,
      },

      timer: null,
      updateTimer: null,
      needUpdate: false,
    };
  },
  computed: {
    showWelcome() {
      return client.appState == proto.AppState.init;
    },
    showLogin() {
      return client.appState == proto.AppState.anonymous;
    },
    showMenu() {
      return (
        client.appState == proto.AppState.login &&
        client.project.id &&
        client.org.id
      );
    },
    showToolbar() {
      return (
        client.appState == proto.AppState.login &&
        client.project.id &&
        client.org.id &&
        !client.fullscreen
      );
    },
    showView() {
      return (
        client.appState == proto.AppState.login &&
        client.project.id &&
        client.org.id &&
        client.view.id
      );
    },
    showNoProject() {
      return (
        client.appState == proto.AppState.login &&
        client.chnState == proto.ChnState.ready &&
        !client.project.id
      );
    },
    viewId() {
      return client.view.id;
    },
    viewName() {
      return client.view.name;
    },
    account() {
      return client.user.account;
    },
    connecting() {
      return (
        client.appState == proto.AppState.anonymous &&
        client.chnState != proto.ChnState.close
      );
    },
    reconnecting() {
      return (
        client.appState == proto.AppState.login &&
        client.chnState != proto.ChnState.ready
      );
    },
  },
  created() {
    this.timer = setInterval(this.tick, 500);
    this.updateTimer = setInterval(this.checkUpdate, 60000);
    client.$on("toast", this.onToast);
    client.$on("alarm", this.onAlarm);
    client.$on("startBusy", this.startBusy);
    client.$on("endBusy", this.endBusy);
    client.$on("chnState", this.chnStateChange);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.updateTimer);
    client.$off("toast", this.onToast);
    client.$off("alarm", this.onAlarm);
    client.$off("startBusy", this.startBusy);
    client.$off("endBusy", this.endBusy);
    client.$off("chnState", this.chnStateChange);
  },
  methods: {
    showUpdateTips() {
      client.$emit(
        "toast",
        "发现新版本，请刷新此页面以获取最新体验。",
        "success"
      );
    },
    checkUpdate() {
      axios
        .get(`version.txt?t=${Date.now()}`)
        .then((res) => {
          this.needUpdate = res.data.trim() != buildno;
        })
        .catch(() => {
          this.needUpdate = false;
        });
      axios
        .request({
          method: "post",
          url: "/api/ping",
          headers: { "X-Client-Time": Date.now() },
        })
        .then(() => {})
        .catch(() => {});
    },
    async chnStateChange(state) {
      if (state == proto.ChnState.ready) {
        if (!client.project.id) await this.getProject();
        if (!client.org.id) await this.getOrganization();
        if (!this.viewId) {
          client.view.id = "monitor-page";
          client.view.name = "页面监控";
        }
      }
    },
    async getProject() {
      try {
        let res = await client.send(proto.MESSAGE_TYPE.projectMessage, {
          mcd: { operate: proto.OperateMode.retrieveOpt, range: "0" },
        });
        let no = res.def;
        for (let i in res.projects) {
          let project = res.projects[i];
          if (project.no == no) {
            client.setProject(project.uuid, project.name);
            document.title = `${project.name} - iVisual`;
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
    },
    async getOrganization() {
      try {
        let res = await client.send(proto.MESSAGE_TYPE.organizationMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
            parentId: client.project.id,
          },
        });
        if (res.orgs.length) {
          let org = res.orgs[0];
          client.setOrg(org.id, org.name);
        }
      } catch (error) {
        client.$emit("toast", error);
      }
    },
    onToast(message, color) {
      if (!message) message = "未知错误";
      message = message.toString();
      if (!message) message = "未知错误";
      if (!color) color = "rgba(255,82,82,0.75)";
      this.toast.message = message;
      this.toast.color = color;
      this.toast.show = true;
    },
    onAlarm(message, color) {
      if (!message) message = "未知错误";
      message = message.toString();
      if (!message) message = "未知错误";
      if (!color) color = "error";
      let key = Math.random()
        .toFixed(10)
        .substring(2);
      let show = false;
      let time = 0;
      this.alarms.push({ key, show, message, color, time });
    },
    startBusy(key, message) {
      let exist = false;
      for (let i = 0; i < this.busy.length; i++) {
        if (this.busy[i].key == key) {
          exist = true;
          this.busy[i].message = message;
          break;
        }
      }
      if (!exist) {
        this.busy.push({ key, message });
      }
    },
    endBusy(key) {
      for (let i = 0; i < this.busy.length; i++) {
        if (this.busy[i].key == key) {
          this.busy.splice(i, 1);
          break;
        }
      }
    },
    dismiss(alarm) {
      alarm.show = false;
    },
    tick() {
      let now = Date.now();
      let offset = 0;
      while (offset < this.alarms.length) {
        let alarm = this.alarms[offset];
        if (alarm.time == 0) {
          alarm.time = now + 10000;
          alarm.show = true;
          offset++;
        } else if (alarm.time < now && alarm.show) {
          alarm.show = false;
          offset++;
        } else if (alarm.time > 0 && !alarm.show) {
          this.alarms.splice(offset, 1);
        } else {
          offset++;
        }
      }
      if (this.alarms.length > 5) {
        this.alarms.splice(0, this.alarms.length - 5);
      }
    },
    globalEmit(eventName) {
      client.$emit(eventName);
    },
    logout() {
      this.busy = [];
      client.logout();
    },
  },
};
</script>
