<template>
  <v-text-field
    :label="label"
    :value="value"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>插件选择</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-select label="主被动" v-model="way" :items="ways"></v-select>
            <v-select
              v-if="isNet"
              label="传输协议"
              v-model="protocal"
              :items="protocals"
            ></v-select>
            <v-select
              v-if="isNet"
              label="连接模式"
              v-model="mode"
              :items="modes"
            ></v-select>
            <v-select
              v-if="!isNet"
              label="波特率"
              v-model="baudrate"
              :items="baudrates"
            ></v-select>
            <v-select
              v-if="!isNet"
              label="数据位"
              v-model="databit"
              :items="databits"
            ></v-select>
            <v-select
              v-if="!isNet"
              label="校验位"
              v-model="paritybit"
              :items="paritybits"
            ></v-select>
            <v-select
              v-if="!isNet"
              label="停止位"
              v-model="stopbit"
              :items="stopbits"
            ></v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value", "model"],
  data() {
    return {
      show: false,

      way: "1",
      ways: [
        { text: "被动", value: "0" },
        { text: "主动", value: "1" },
      ],

      protocal: "TCP",
      protocals: [
        { text: "TCP", value: "TCP" },
        { text: "UDP", value: "UDP" },
      ],

      mode: "Client",
      modes: [
        { text: "Client", value: "Client" },
        { text: "Server", value: "Server" },
      ],

      baudrate: "9600",
      baudrates: [
        { text: "600", value: "600" },
        { text: "1200", value: "1200" },
        { text: "1800", value: "1800" },
        { text: "2400", value: "2400" },
        { text: "4800", value: "4800" },
        { text: "7200", value: "7200" },
        { text: "9600", value: "9600" },
        { text: "14400", value: "14400" },
        { text: "19200", value: "19200" },
        { text: "38400", value: "38400" },
        { text: "57600", value: "57600" },
        { text: "115200", value: "115200" },
      ],

      databit: "7",
      databits: [
        { text: "7", value: "7" },
        { text: "8", value: "8" },
      ],

      paritybit: "none",
      paritybits: [
        { text: "none", value: "none" },
        { text: "odd", value: "odd" },
        { text: "even", value: "even" },
        { text: "mark", value: "mark" },
        { text: "space", value: "space" },
      ],

      stopbit: "1",
      stopbits: [
        { text: "1", value: "1" },
        { text: "1.5", value: "1.5" },
        { text: "2", value: "2" },
      ],
    };
  },
  computed: {
    isNet() {
      return this.model && this.model.portType == 1;
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          if (this.value) {
            let token = this.value.split(",");
            if (token.length == 3) {
              this.way = token[0];
              this.protocal = token[1];
              this.mode = token[2];
            } else if (token.length == 5) {
              this.way = token[0];
              this.baudrate = token[1];
              this.databit = token[2];
              this.paritybit = token[3];
              this.stopbit = token[4];
            }
          }
        }
      },
    },
  },
  methods: {
    submit() {
      if (this.isNet) {
        this.$emit("change", `${this.way},${this.protocal},${this.mode}`);
      } else {
        this.$emit(
          "change",
          `${this.way},${this.baudrate},${this.databit},${this.paritybit},${this.stopbit}`
        );
      }
      this.show = false;
    },
  },
};
</script>
