<template>
  <v-text-field
    :label="label"
    :value="valueDisplay"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>告警描述</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <template v-for="item in items">
              <v-text-field
                :key="`alarm${item.alarmtype}`"
                :label="typeName(item.alarmtype, 'alarm')"
                v-model="item.alarmdesc"
                hide-details
              ></v-text-field>
              <v-text-field
                :key="`reset${item.alarmtype}`"
                :label="typeName(item.alarmtype, 'reset')"
                v-model="item.recoverdesc"
                hide-details
              ></v-text-field>
            </template>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import proto from "../utils/proto";
import uuid from "../utils/uuid";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value", "model"],
  data() {
    return {
      show: false,

      items: [],
    };
  },
  computed: {
    valueDisplay() {
      for (let i in this.value) {
        if (this.value[i].alarmdesc || this.value[i].recoverdesc)
          return "已配置";
      }
      return "未配置";
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          let result = [];
          let dict = {};
          if (this.model) {
            switch (this.model.modelType) {
              case "agent":
              case "device":
                dict[`${proto.ALARM_TYPE.offline}`] = {
                  type: proto.ALARM_TYPE.online,
                  alarm: "",
                  reset: "",
                };
                break;
              case "spot":
                switch (this.model.sv.valueType) {
                  case 1:
                    dict[`${proto.ALARM_TYPE.upperlim}`] = {
                      alarm: "",
                      reset: "",
                    };
                    dict[`${proto.ALARM_TYPE.lowerlim}`] = {
                      alarm: "",
                      reset: "",
                    };
                    if (this.model.sap.limitway > 1) {
                      dict[`${proto.ALARM_TYPE.nearuplim}`] = {
                        alarm: "",
                        reset: "",
                      };
                      dict[`${proto.ALARM_TYPE.nearlowlim}`] = {
                        alarm: "",
                        reset: "",
                      };
                    }
                    if (this.model.sap.limitway > 2) {
                      dict[`${proto.ALARM_TYPE.ultra_hi}`] = {
                        alarm: "",
                        reset: "",
                      };
                      dict[`${proto.ALARM_TYPE.ultra_low}`] = {
                        alarm: "",
                        reset: "",
                      };
                    }
                    break;
                  case 3:
                    dict[`${proto.ALARM_TYPE.state_change}`] = {
                      alarm: "",
                      reset: "",
                    };
                    break;
                }
                break;
            }
          }
          for (let i in this.value) {
            let desc = this.value[i];
            dict[`${desc.alarmtype}`] = dict[`${desc.alarmtype}`] || {
              alarm: "",
              reset: "",
            };
            dict[`${desc.alarmtype}`].alarm = desc.alarmdesc;
            dict[`${desc.alarmtype}`].reset = desc.recoverdesc;
          }
          let keys = Object.keys(dict);
          for (let i in keys) {
            let key = keys[i];
            result.push({
              uuid: uuid(),
              alarmtype: parseInt(key),
              alarmdesc: dict[`${key}`].alarm,
              recoverdesc: dict[`${key}`].reset,
            });
          }
          this.items = result;
        }
      },
    },
  },
  methods: {
    typeName(type, kind) {
      let desc = kind == "alarm" ? "告警描述" : "恢复描述";
      switch (type) {
        case 1:
          return `超上限${desc}`;
        case 2:
          return `超下限${desc}`;
        case 3:
          return `临上限${desc}`;
        case 4:
          return `临下限${desc}`;
        case 6:
          return `状态变化${desc}`;
        case 9:
          return `离线${desc}`;
        case 11:
          return `极上限${desc}`;
        case 12:
          return `极下限${desc}`;
        default:
          return "";
      }
    },
    submit() {
      this.$emit("change", this.items);
      this.show = false;
    },
  },
};
</script>
