<style scoped>
h1 {
  text-align: center;
}
</style>

<template>
  <v-row v-show="show" align="center" class="login-bg white--text">
    <v-col>
      <h1>欢迎使用</h1>
      <h1>IMCP综合监控平台</h1>
      <h1>iVisual</h1>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: { show: Boolean },
};
</script>