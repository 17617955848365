export default [
    { key: "zht-div", name: "区块", icon: "mdi-border-all-variant" },
    { key: "zht-component", name: "子页浏览器", icon: "mdi-view-compact" },
    { key: "zht-tabs", name: "导航", icon: "mdi-menu" },
    { key: "zht-csv", name: "CSV表格", icon: "mdi-table" },
    { key: "zht-svg", name: "SVG矢量图", icon: "mdi-image" },
    { key: "zht-bar", name: "柱状图", icon: "mdi-chart-bar" },
    { key: "zht-line", name: "曲线图", icon: "mdi-chart-bell-curve-cumulative", },
    { key: "zht-pie", name: "饼图", icon: "mdi-chart-arc" },
    { key: "zht-gauge", name: "仪表", icon: "mdi-gauge" },
    { key: "zht-chart", name: "自定义图表", icon: "mdi-chart-multiple" },
    { key: "zht-field", name: "颜色场", icon: "mdi-cast-connected" },
    { key: "zht-textbox", name: "输入框", icon: "mdi-form-textbox" },
    { key: "zht-combobox", name: "选择框", icon: "mdi-form-dropdown" },
    { key: "zht-button", name: "按钮", icon: "mdi-gesture-tap-button" },
    { key: "zht-icon", name: "图标", icon: "mdi-emoticon-cool-outline" },
    { key: "zht-map", name: "行政地图", icon: "mdi-map" },
    { key: "zht-bmap", name: "定位地图", icon: "mdi-map-marker" },
    { key: "zht-rtsp", name: "RTSP视频", icon: "mdi-webcam" },
    { key: "zht-rtsp-playback", name: "RTSP回放", icon: "mdi-video" },
    { key: "zht-spotlist", name: "测点列表", icon: "mdi-format-list-numbered-rtl" },
    { key: "zht-browser", name: "外链网页", icon: "mdi-application" },
];
