<style scoped>
.card--text {
  color: #424242 !important;
}
.alarm--text {
  color: #ff6a6a;
}
.reset--text,
.online--text {
  color: #00d200;
}
.offline--text {
  color: #cbcbcb;
}
</style>

<template>
  <div class="main">
    <v-navbar>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="breadcrumbs.length > 1"
            icon
            v-bind="attrs"
            v-on="on"
            @click="backword"
          >
            <v-icon>mdi-arrow-left-bold-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>返回上一级</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="unConfirmAlarmShow = true"
          >
            <v-badge
              :value="unConfirmAlarmCount"
              :content="unConfirmAlarmCount"
              color="error"
              overlap
            >
              <v-icon>mdi-alert</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span>未确认告警</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="alarmDetailShow = true">
            <v-icon>mdi-format-list-bulleted-triangle</v-icon>
          </v-btn>
        </template>
        <span>告警列表</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="audioSwitch">
            <v-icon v-if="speakEnable">mdi-account-voice</v-icon>
            <v-icon v-else>mdi-voice-off</v-icon>
          </v-btn>
        </template>
        <span v-if="speakEnable">关闭AI语音播报</span>
        <span v-else>开启AI语音播报</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="showAlarm = !showAlarm">
            <v-icon v-if="showAlarm">mdi-playlist-check</v-icon>
            <v-icon v-else>mdi-playlist-remove</v-icon>
          </v-btn>
        </template>
        <span v-if="showAlarm">关闭告警字幕</span>
        <span v-else>开启告警字幕</span>
      </v-tooltip>
      <v-text-field
        v-model="filter"
        label="搜索"
        hide-details
        dense
        outlined
        prepend-inner-icon="mdi-magnify"
        style="max-width: 150px"
      ></v-text-field>
    </v-navbar>
    <v-breadcrumbs dark class="pb-0 px-0" :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          class="white--text"
          v-text="item.name"
        ></v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <div class="scroll">
      <v-data-table
        :headers="headers"
        :items="matchItems"
        dark
        style="background-color: transparent"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.name`]="{ item }">
          <a
            v-if="item.modelType != 'spot'"
            style="color:#FFF"
            @click="view(item)"
          >
            {{ item.name }}
          </a>
          <span v-else>{{ item.name }}</span>
        </template>
        <template v-slot:[`item.modelType`]="{ item }">
          <span v-if="item.modelType == 'agent'">监控主机</span>
          <span v-if="item.modelType == 'device'">设备</span>
          <span v-if="item.modelType == 'spot'">测点</span>
        </template>
        <template v-slot:[`item.address`]="{ item }">
          <span v-if="item.modelType == 'agent'">{{ item.ip }}</span>
          <span v-if="item.modelType == 'device'">
            {{ `${item.ipaddr}:${item.ipport}` }}
          </span>
        </template>
        <template v-slot:[`item.stateType`]="{ item }">
          <span v-if="item.stateType == 0" class="offline--text">离线</span>
          <span v-if="item.stateType == 1" class="online--text">在线</span>
          <span v-if="item.stateType == 2" class="offline--text">禁用</span>
        </template>
        <template v-slot:[`item.alarmType`]="{ item }">
          <span v-if="item.alarmType == 1" class="error--text">
            超上限告警
          </span>
          <span v-if="item.alarmType == 2" class="error--text">
            超下限告警
          </span>
          <span v-if="item.alarmType == 3" class="error--text">
            临上限告警
          </span>
          <span v-if="item.alarmType == 4" class="alarm--text">
            临下限告警
          </span>
          <span v-if="item.alarmType == 5" class="alarm--text">
            数据异常告警
          </span>
          <span v-if="item.alarmType == 6" class="alarm--text">
            状态异常告警
          </span>
          <span v-if="item.alarmType == 7" class="reset--text">正常</span>
          <span v-if="item.alarmType == 8" class="reset--text">正常</span>
          <span v-if="item.alarmType == 9" class="alarm--text">脱机告警</span>
          <span v-if="item.alarmType == 10" class="alarm--text">文本告警</span>
          <span v-if="item.alarmType == 11" class="alarm--text">
            极上限告警
          </span>
          <span v-if="item.alarmType == 12" class="alarm--text">
            极下限告警
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            text
            class="link-text"
            v-if="item.modelType != 'spot'"
            @click="view(item)"
          >
            <span>查看</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            v-if="item.modelType == 'spot' && item.rw == 2"
            @click="control(item)"
          >
            <span>控制</span>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <SpotControlDialog
      v-model="controlShow"
      :uuid="controlId"
      :name="controlName"
      @submit="spotControl"
    ></SpotControlDialog>
    <AlarmDetailDialog v-model="alarmDetailShow"></AlarmDetailDialog>
    <UnConfirmAlarmDialog v-model="unConfirmAlarmShow"></UnConfirmAlarmDialog>
    <VoiceDialog v-model="voiceShow" @voice="audioStart"></VoiceDialog>
    <audio
      ref="audio"
      style="display: none"
      @ended="audioEnd"
      @error="audioError"
    ></audio>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
import base64 from "../utils/base64";
import store from "../utils/store";
import SpotControlDialog from "../components/SpotControlDialog.vue";
import AlarmDetailDialog from "../components/AlarmDetailDialog.vue";
import UnConfirmAlarmDialog from "../components/UnConfirmAlarmDialog.vue";
import VoiceDialog from "../components/VoiceDialog.vue";

export default {
  components: {
    SpotControlDialog,
    AlarmDetailDialog,
    UnConfirmAlarmDialog,
    VoiceDialog,
  },
  data() {
    return {
      showAlarm: true,

      loading: true,
      filter: "",
      items: [],
      current: null,
      breadcrumbs: [],

      controlShow: false,
      controlId: "",
      controlName: "",

      alarmDetailShow: false,
      unConfirmAlarmShow: false,

      voiceShow: false,
      speakEnable: false,
      speakOnce: true,
      speakList: [],
      speakUrl: "",
    };
  },
  computed: {
    unConfirmAlarmCount() {
      return client.statistics.s8;
    },
    matchItems() {
      if (this.filter) {
        return this.items.filter(this.matches);
      } else {
        return this.items;
      }
    },
    headers() {
      let result = [];
      if (this.current) {
        let type = this.current.modelType;
        result.push({ text: "名称", value: "name" });
        switch (type) {
          case "org":
            result.push({ text: "类型", value: "modelType" });
            result.push({ text: "地址", value: "address", sortable: false });
            result.push({ text: "联机状态", value: "stateType" });
            result.push({ text: "告警状态", value: "alarmType" });
            break;
          case "agent":
            result.push({ text: "地址", value: "address", sortable: false });
            result.push({ text: "联机状态", value: "stateType" });
            result.push({ text: "告警状态", value: "alarmType" });
            break;
          case "device":
            result.push({ text: "编号", value: "no" });
            result.push({ text: "告警状态", value: "alarmType" });
            result.push({ text: "当前值", value: "value" });
            break;
        }
        result.push({
          text: "操作",
          value: "actions",
          sortable: false,
          align: "end",
        });
      }
      return result;
    },
  },
  watch: {
    speakEnable(value) {
      store.set("speakEnable", value ? "true" : "false");
      if (value) {
        this.startSpeak();
      }
    },
    showAlarm(value) {
      store.set("showAlarm", value ? "true" : "false");
    },
  },
  mounted() {
    this.audioUrl();
    this.speakEnable = store.get("speakEnable") == "true";
    this.showAlarm = store.get("showAlarm") == "true";
    client.$on("orgChange", this.orgChange);
    client.$on("brocast", this.brocast);
    client.$on("chnState", this.chnState);
    this.orgChange();
  },
  beforeDestroy() {
    client.$off("orgChange", this.orgChange);
    client.$off("brocast", this.brocast);
    client.$off("chnState", this.chnState);
  },
  methods: {
    chnState(chnState) {
      if (chnState == proto.ChnState.ready) {
        this.view(this.current);
      }
    },
    matches(item) {
      return item.name.indexOf(this.filter) != -1;
    },
    cardIcon(item) {
      switch (item.modelType) {
        case "agent":
          return "mdi-server";
        case "device":
          return "mdi-devices";
        case "spot":
          return "mdi-alarm-light";
      }
      return "";
    },
    onlineIcon(item) {
      if (item.modelType != "spot") {
        if (item.stateType == 1) return "mdi-lan-connect";
        else return "mdi-lan-disconnect";
      }
      return "";
    },
    cardColor(item) {
      let result = {};
      if (item.alarmType == 0) {
        result["blue-grey"] = true;
      } else if (item.modelType == "spot") {
        if (item.alarmType == 7) result.green = true;
        else result.red = true;
      } else {
        if (item.stateType != 1 && item.alarmType == 7)
          result["blue-grey"] = true;
        else if (item.alarmType == 7 || item.alarmType == 8)
          result.green = true;
        else result.red = true;
      }
      return result;
    },
    async orgChange() {
      this.breadcrumbs = [];
      await this.view({
        uuid: "org",
        name: "",
        modelType: "org",
      });
    },
    async view(item) {
      if (!item) return;
      this.loading = true;
      this.items = [];
      let exist;
      for (let i in this.breadcrumbs) {
        let breadcrumb = this.breadcrumbs[i];
        if (breadcrumb.uuid == item.uuid) {
          exist = breadcrumb;
          this.breadcrumbs = this.breadcrumbs.slice(0, parseInt(i) + 1);
          break;
        }
      }
      if (!exist) {
        exist = item;
        this.breadcrumbs.push(item);
      }
      this.current = item;
      await this.clearFilter();
      await this.getAgents(item);
      await this.getDevices(item);
      await this.getSpots(item);
      await this.initStatus();
      await this.initData(item);
      this.loading = false;
    },
    backword() {
      let len = this.breadcrumbs.length;
      if (len > 1) {
        this.view(this.breadcrumbs[len - 2]);
      }
    },
    control(item) {
      this.controlId = item.uuid;
      this.controlName = item.name;
      this.controlShow = true;
    },
    async spotControl(uuid, value) {
      client.$emit(
        "startBusy",
        `MonitorDevice.spotControl.${uuid}`,
        "正在控制测点,请稍候..."
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.realTimeDataMessage, {
          mcd: {
            operate: proto.OperateMode.updateOpt,
            range: uuid,
          },
          rtdata: [
            {
              uuid,
              sv: {
                valueData: base64.encode(value),
              },
            },
          ],
        });
        client.$emit("toast", "控制成功", "success");
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", `MonitorDevice.spotControl.${uuid}`);
    },
    async getAgents(item) {
      if (item.modelType != "org") return;
      client.$emit(
        "startBusy",
        "MonitorDevice.getAgents",
        "正在获取监控主机,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.agentMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
          },
        });
        if (res.agents && res.agents.length) {
          res.agents.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.agents.length; i++) {
            let agent = res.agents[i];
            agent.modelType = "agent";
            agent.stateType = 0;
            agent.alarmType = 0;
            this.items.push(agent);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "MonitorDevice.getAgents");
    },
    async getDevices(item) {
      if (item.modelType != "org" && item.modelType != "agent") return;
      client.$emit(
        "startBusy",
        "MonitorDevice.getDevices",
        "正在获取设备,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.deviceMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
          },
          act: item.modelType == "org" ? 2 : 3,
          agents: item.modelType == "org" ? [] : [item.uuid],
        });
        if (res.devices && res.devices.length) {
          res.devices.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.devices.length; i++) {
            let device = res.devices[i];
            device.modelType = "device";
            device.stateType = 0;
            device.alarmType = 0;
            this.items.push(device);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "MonitorDevice.getDevices");
    },
    async getSpots(item) {
      if (item.modelType != "device") return;
      client.$emit(
        "startBusy",
        "MonitorDevice.getSpots",
        "正在获取测点,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.spotMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
          },
          act: 1,
          devices: [item.uuid],
        });
        if (res.spots && res.spots.length) {
          res.spots.sort((a, b) => {
            return a.no > b.no ? 1 : -1;
          });
          for (let i = 0; i < res.spots.length; i++) {
            let spot = res.spots[i];
            spot.modelType = "spot";
            spot.stateType = -1;
            spot.alarmType = 0;
            spot.value = "/";
            this.items.push(spot);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "MonitorDevice.getSpots");
    },
    async initStatus() {
      client.$emit(
        "startBusy",
        "MonitorDevice.initStatus",
        "正在获取状态,请稍候..."
      );
      await proto.sleep(100);
      try {
        let so = [];
        for (let i in this.items) {
          let item = this.items[i];
          so.push({ uuid: item.uuid });
        }
        if (so.length) {
          let res = await client.send(proto.MESSAGE_TYPE.statusMessage, {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
              range: "-1",
            },
            so,
          });
          this.brocast(proto.MESSAGE_TYPE.statusMessage, res);
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "MonitorDevice.initStatus");
    },
    async clearFilter() {
      try {
        await client.send(proto.MESSAGE_TYPE.realTimeDataMessage, {
          mcd: {
            operate: proto.OperateMode.createOpt,
          },
          rtdata: [],
        });
      } catch (error) {
        client.$emit("toast", error);
      }
    },
    async initData(item) {
      if (item.modelType != "device") return;
      client.$emit(
        "startBusy",
        "MonitorDevice.initData",
        "正在获取数据,请稍候..."
      );
      await proto.sleep(100);
      try {
        let rtdata = [];
        for (let i in this.items) {
          let item = this.items[i];
          rtdata.push({ uuid: item.uuid });
        }
        if (rtdata.length) {
          let res = await client.send(proto.MESSAGE_TYPE.realTimeDataMessage, {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
            },
            filterType: 1,
            rtdata,
          });
          this.brocast(proto.MESSAGE_TYPE.realTimeDataMessage, res);
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "MonitorDevice.initData");
    },
    brocast(type, msg) {
      switch (type) {
        case proto.MESSAGE_TYPE.realTimeEventMessage:
          if (msg.rtevent) {
            for (let i in msg.rtevent) {
              let event = msg.rtevent[i];
              for (let i in this.items) {
                let item = this.items[i];
                if (item.uuid == event.uuid) {
                  item.alarmType = event.stateAlarm;
                  break;
                }
              }
              if (event.description) {
                if (event.et != proto.EVENT_TYPE.sys_event) {
                  this.speakList.unshift(event.description);
                  while (this.speakList.length > 5) this.speakList.pop();
                  if (this.showAlarm) {
                    client.$emit(
                      "alarm",
                      `[${event.level}] ${event.description}`,
                      event.level ? "error" : "success"
                    );
                  }
                }
              }
            }
          }
          this.startSpeak();
          break;
        case proto.MESSAGE_TYPE.statusMessage:
          if (msg.so) {
            for (let i in msg.so) {
              let so = msg.so[i];
              for (let i in this.items) {
                let item = this.items[i];
                if (item.uuid == so.uuid) {
                  item.stateType = so.state;
                  item.alarmType = so.stateAlarm;
                  if (so.agentip) item.ip = so.agentip;
                  break;
                }
              }
            }
          }
          break;
        case proto.MESSAGE_TYPE.realTimeDataMessage:
          if (msg.rtdata) {
            for (let i in msg.rtdata) {
              let data = msg.rtdata[i];
              for (let i in this.items) {
                let item = this.items[i];
                if (item.uuid == data.uuid) {
                  let value = "/";
                  if (data.sv && data.sv.valueData)
                    value = base64.decode(data.sv.valueData);
                  if (/^[0-9]+\.?[0-9]*$/.test(value)) value = parseFloat(value);
                  item.value = value;
                  break;
                }
              }
            }
          }
          break;
      }
    },
    /** speak */
    audioSwitch() {
      if (this.speakEnable == true) {
        this.speakEnable = false;
      } else {
        this.voiceShow = true;
      }
    },
    audioStart() {
      this.audioUrl();
      this.speakList.push("语音播报已开启");
      this.speakEnable = true;
    },
    audioUrl() {
      let id = store.get("voice") || "zh-CN-YunyangNeural";
      let rate = parseFloat(store.get("rate") || "1");
      let pitch = parseFloat(store.get("pitch") || "1");
      this.speakUrl = `//tts.szzht.com/tts?id=${id}&rate=${rate}&pitch=${pitch}&text=$word`;
    },
    audioEnd() {
      this.startSpeak();
    },
    audioError() {
      this.speakOnce = true;
      client.$emit("toast", "当前网络无法进行语音播报");
      this.startSpeak();
    },
    startSpeak() {
      if (this.speakEnable && this.speakUrl) {
        if (this.$refs.audio) {
          if (this.$refs.audio.ended || this.speakOnce) {
            if (this.speakList.length > 0) {
              let words = this.speakList.pop();
              this.speakOnce = false;
              this.$refs.audio.src = this.speakUrl.replace(
                "$word",
                encodeURIComponent(words)
              );
              try {
                this.$refs.audio.play();
              } catch (e) {
                console.log(e);
              }
            }
          }
        }
      }
    },
  },
};
</script>
