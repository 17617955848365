<template>
  <div class="main">
    <v-navbar>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="
              editModel = {};
              editShow = true;
            "
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>新建用户</span>
      </v-tooltip>
      <v-text-field
        v-model="filter"
        label="搜索"
        hide-details
        dense
        outlined
        prepend-inner-icon="mdi-magnify"
        style="max-width: 250px"
      ></v-text-field>
    </v-navbar>
    <div class="scroll">
      <v-data-table
        :headers="headers"
        :items="matchItems"
        :mobile-breakpoint="800"
        dark
        style="background-color: transparent"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.user`]="{ item }">
          <span>{{ item.user ? item.user.name : "" }}</span>
        </template>
        <template v-slot:[`item.sex`]="{ item }">
          <span v-if="item.sex == 1">男</span>
          <span v-if="item.sex == 2">女</span>
        </template>
        <template v-slot:[`item.usergroup`]="{ item }">
          <span>
            {{ item.usergroup.map((o) => o.name).join(",") }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            text
            class="link-text"
            @click="
              pwdId = item.user.id;
              pwdShow = true;
            "
          >
            <span>修改密码</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            @click="
              moveModel = item;
              moveShow = true;
            "
          >
            <span>迁出</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            @click="
              deleteModel = item;
              deleteShow = true;
            "
          >
            <span>删除</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            @click="
              editModel = item;
              editShow = true;
            "
          >
            <span>编辑</span>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <UserDialog
      v-model="editShow"
      :model="editModel"
      @update="getUsers"
    ></UserDialog>
    <OrgSelector
      v-model="moveShow"
      label="请选择要迁入的机构"
      @select="changeOrg"
    ></OrgSelector>
    <ChangePasswordDialog
      v-model="pwdShow"
      :userId="pwdId"
    ></ChangePasswordDialog>
    <v-confirm
      v-model="deleteShow"
      :label="`是否删除用户 ${deleteModel.name} ?`"
      @ok="deleteUser"
    ></v-confirm>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
import UserDialog from "../components/UserDialog.vue";
import OrgSelector from "../components/OrgSelector.vue";
import ChangePasswordDialog from "../components/ChangePasswordDialog.vue";

export default {
  components: { UserDialog, OrgSelector, ChangePasswordDialog },
  data() {
    return {
      loading: true,

      filter: "",
      headers: [
        { text: "用户名", value: "user" },
        { text: "姓名", value: "name" },
        { text: "性别", value: "sex" },
        { text: "工号", value: "empno" },
        { text: "部门", value: "company" },
        { text: "职位", value: "position" },
        { text: "语音号码", value: "telephone" },
        { text: "短信号码", value: "mobilephone" },
        { text: "邮箱", value: "email" },
        { text: "角色", value: "usergroup" },
        { text: "操作", value: "actions", sortable: false, align: "end" },
      ],
      items: [],

      editShow: false,
      editModel: {},

      deleteShow: false,
      deleteModel: {},

      moveShow: false,
      moveModel: {},

      pwdShow: false,
      pwdId: "",
    };
  },
  computed: {
    matchItems() {
      if (this.filter) {
        return this.items.filter(this.matches);
      } else {
        return this.items;
      }
    },
  },
  mounted() {
    client.$on("orgChange", this.getUsers);
    this.getUsers();
  },
  beforeDestroy() {
    client.$off("orgChange", this.getUsers);
  },
  methods: {
    matches(item) {
      return item.name.indexOf(this.filter) != -1;
    },
    async getUsers() {
      let result = [];
      client.$emit(
        "startBusy",
        "ConfigUser.getUsers",
        "正在获取用户,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.personMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
            parentId: client.org.id,
          },
          orgGroup: 3,
        });
        if (res.Persons && res.Persons.length) {
          res.Persons.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.Persons.length; i++) {
            let person = res.Persons[i];
            result.push(person);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigUser.getUsers");
      this.items = result;
      this.loading = false;
    },
    async deleteUser() {
      client.$emit(
        "startBusy",
        "ConfigUser.deleteUser",
        "正在删除用户,请稍候..."
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.personMessage, {
          mcd: {
            operate: proto.OperateMode.deleteOpt,
            range: this.deleteModel.uuid,
          },
        });
        await client.send(proto.MESSAGE_TYPE.userMessage, {
          mcd: {
            operate: proto.OperateMode.deleteOpt,
            range: this.deleteModel.uuid,
          },
          users: [
            {
              uuid: this.deleteModel.user.id,
              account: this.deleteModel.user.name,
              password: "del",
              type: 2,
            },
          ],
        });
        this.getUsers();
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigUser.deleteUser");
    },
    async changeOrg(org) {
      client.$emit(
        "startBusy",
        "ConfigUser.changeOrg",
        "正在迁移用户,请稍候..."
      );
      await proto.sleep(100);
      try {
        if (!org || !org.id) throw "机构信息有误";
        if (!this.moveModel) throw "用户信息缺失";
        await client.send(proto.MESSAGE_TYPE.personMessage, {
          mcd: {
            operate: proto.OperateMode.modifyOpt,
            parentId: org.id,
          },
          Persons: [this.moveModel],
        });
        this.getUsers();
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigUser.changeOrg");
    },
  },
};
</script>
