<template>
  <v-text-field
    :label="label"
    :value="value"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>插件选择</v-card-title>
          <v-divider></v-divider>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            single-select
            item-key="id"
            show-select
            hide-default-header
          ></v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      show: false,

      headers: [
        {
          text: "名称",
          value: "name",
          sortable: false,
        },
      ],
      items: [],
      selected: [],
    };
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          if (this.value) {
            this.selected = [{ id: this.value }];
          } else {
            this.selected = [];
          }
          this.initSource();
        }
      },
    },
  },
  methods: {
    async initSource() {
      let result = [];
      client.$emit(
        "startBusy",
        "ObjectEditor.getPeriods",
        "正在获取时段,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.sysPluginMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
        });
        if (res.plugins) {
          res.plugins.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i in res.plugins) {
            result.push({ id: res.plugins[i].uuid, name: res.plugins[i].name });
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ObjectEditor.getPeriods");
      this.items = result;
    },
    submit() {
      if (this.selected.length) {
        this.$emit("change", this.selected[0].id);
      } else {
        this.$emit("change", "");
      }
      this.show = false;
    },
  },
};
</script>
