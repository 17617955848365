<template>
  <v-text-field
    :label="label"
    :value="valueDisplay"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="640" persistent>
        <v-card>
          <v-card-title>
            <span>状态量告警配置</span>
            <v-spacer></v-spacer>
            <v-icon @click="add">mdi-plus</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="items"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:[`item.statevalue`]="{ item }">
              <v-text-field
                solo
                flat
                v-model="item.statevalue"
                hide-details
              ></v-text-field>
            </template>
            <template v-slot:[`item.statename`]="{ item }">
              <v-text-field
                solo
                flat
                v-model="item.statename"
                hide-details
              ></v-text-field>
            </template>
            <template v-slot:[`item.alarmdesc`]="{ item }">
              <v-text-field
                solo
                flat
                v-model="item.alarmdesc"
                hide-details
              ></v-text-field>
            </template>
            <template v-slot:[`item.isalarm`]="{ item }">
              <v-switch dense v-model="item.isalarm"></v-switch>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon @click="remove(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value", "model"],
  data() {
    return {
      show: false,
      headers: [
        {
          value: "statevalue",
          text: "状态值",
          sortable: false,
        },
        {
          value: "statename",
          text: "状态名",
          sortable: false,
        },
        {
          value: "alarmdesc",
          text: "告警描述",
          sortable: false,
        },
        {
          value: "isalarm",
          text: "是否告警",
          sortable: false,
        },
        {
          value: "actions",
          text: "操作",
          sortable: false,
        },
      ],
      items: [],
    };
  },
  computed: {
    valueDisplay() {
      if (this.value) {
        for (let i in this.value) {
          if (this.value[i].isalarm) return "已配置";
        }
      }
      return "未配置";
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.items = JSON.parse(JSON.stringify(this.value));
        }
      },
    },
  },
  methods: {
    add() {
      this.items.push({
        statevalue: "",
        statename: "",
        alarmdesc: "",
        isalarm: false,
        uuid: "",
      });
    },
    remove(item) {
      for (let i in this.items) {
        if (this.items[i] == item) {
          this.items.splice(parseInt(i), 1);
          break;
        }
      }
    },
    submit() {
      this.$emit("change", this.items);
      this.show = false;
    },
  },
};
</script>
