<template>
  <div class="main">
    <v-navbar>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="add.show = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>添加策略</span>
      </v-tooltip>
    </v-navbar>
    <div class="scroll">
      <v-data-table
        :headers="headers"
        :items="items"
        dark
        style="background-color: transparent"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.modelType`]="{ item }">
          <span v-if="item.modelType == 'linkAlarm'">告警关联</span>
          <span v-if="item.modelType == 'devLinkage'">设备联动</span>
          <span v-if="item.modelType == 'timer'">定时任务</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            text
            class="link-text"
            @click="
              current = item;
              editorShow = true;
            "
          >
            <span>编辑</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            @click="
              remove.model = item;
              remove.show = true;
            "
          >
            <span>删除</span>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-navigation-drawer
      v-model="editorShow"
      absolute
      temporary
      right
      width="240"
    >
      <div v-if="editModel" class="d-flex flex-column fill-height">
        <div class="flex-grow-0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ editModel.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                text
                class="link-text"
                @click="
                  cancel = true;
                  editorShow = false;
                "
              >
                放弃修改
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
        <v-divider></v-divider>
        <div class="flex-grow-1 bg-editor" style="overflow: auto">
          <template v-for="attr in editors">
            <component
              v-if="
                editModel[attr.key] !== undefined &&
                showEditor(editModel, attr.key)
              "
              :is="attr.editor"
              :key="`model-${attr.key}`"
              :label="attr.name"
              :options="attr.options"
              :model="editModel"
              v-model="editModel[attr.key]"
            ></component>
          </template>
        </div>
      </div>
    </v-navigation-drawer>
    <v-dialog v-model="add.show" persistent width="360">
      <v-card>
        <v-card-title>添加策略</v-card-title>
        <v-card-text>
          <v-select
            label="类型"
            v-model="add.policy"
            :items="add.policies"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="addSubmit">确定</v-btn>
          <v-btn text @click="add.show = false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-confirm
      v-model="remove.show"
      label="是否删除此策略?"
      @ok="removeSubmit"
    ></v-confirm>
  </div>
</template>

<script>
const editors = [
  {
    key: "id",
    name: "ID",
    editor: "zht-editor-text",
    options: { readonly: true },
  },
  {
    key: "uuid",
    name: "ID",
    editor: "zht-editor-text",
    options: { readonly: true },
  },
  {
    key: "name",
    name: "名称",
    editor: "zht-editor-text",
  },
  {
    key: "linkSrc",
    name: "关联源",
    editor: "link-object-editor",
  },
  {
    key: "linkvalue",
    name: "关联条件",
    editor: "zht-editor-enum",
    options: [
      { text: "告警", value: true },
      { text: "不告警", value: false },
    ],
  },
  {
    key: "linkDsts",
    name: "关联目标",
    editor: "link-object-editor",
  },
  {
    key: "src",
    name: "联动源",
    editor: "dev-link-src-editor",
  },
  {
    key: "usejs",
    name: "使用脚本",
    editor: "zht-editor-enum",
    options: [
      { text: "使用", value: true },
      { text: "不使用", value: false },
    ],
  },
  {
    key: "jscript",
    name: "联动脚本",
    editor: "dev-link-script-editor",
  },
  {
    key: "operatortype",
    name: "触发条件",
    editor: "zht-editor-enum",
    options: [
      { text: "=", value: 1 },
      { text: ">", value: 2 },
      { text: "<", value: 3 },
      { text: "≠", value: 4 },
    ],
  },
  {
    key: "convalue",
    name: "条件值",
    editor: "zht-editor-text",
  },
  {
    key: "periodsid",
    name: "时段",
    editor: "zht-editor-object",
    options: {
      type: "period",
    },
  },
  {
    key: "dst",
    name: "联动目标",
    editor: "dev-link-dst-editor",
  },
  {
    key: "delaytime",
    name: "延时时间(秒)",
    editor: "zht-editor-text",
  },
  {
    key: "intervaltime",
    name: "间隔时间(秒)",
    editor: "zht-editor-text",
  },
  {
    key: "repeattimes",
    name: "重复次数",
    editor: "zht-editor-text",
  },
  {
    key: "tasktype",
    name: "任务类型",
    editor: "zht-editor-enum",
    options: [
      { text: "系统运行状况", value: 1 },
      { text: "智能巡检", value: 2 },
      { text: "授权到期提醒", value: 4 },
      { text: "定时控制", value: 5 },
      { text: "定时提醒", value: 6 },
    ],
  },
  {
    key: "timers",
    name: "执行时间",
    editor: "timer-trigger-editor",
  },
  {
    key: "content",
    name: "任务内容",
    editor: "timer-content-editor",
  },
];
import proto from "../utils/proto";
import client from "../utils/client";
import uuid from "../utils/uuid";
import LinkObjectEditor from "../components/LinkObjectEditor.vue";
import DevLinkSrcEditor from "../components/DevLinkSrcEditor.vue";
import DevLinkDstEditor from "../components/DevLinkDstEditor.vue";
import DevLinkScriptEditor from "../components/DevLinkScriptEditor.vue";
import TimerTriggerEditor from "../components/TimerTriggerEditor.vue";
import TimerContentEditor from "../components/TimerContentEditor.vue";

export default {
  components: {
    LinkObjectEditor,
    DevLinkSrcEditor,
    DevLinkDstEditor,
    DevLinkScriptEditor,
    TimerTriggerEditor,
    TimerContentEditor,
  },
  data() {
    return {
      headers: [
        { text: "名称", value: "name" },
        { text: "类型", value: "modelType" },
        {
          text: "操作",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
      items: [],

      editors,

      current: null,
      editorShow: false,
      editModel: null,
      cancel: false,

      add: {
        show: false,
        policy: undefined,
        policies: [
          { text: "告警关联", value: "linkAlarm" },
          { text: "设备联动", value: "devLinkage" },
          { text: "定时任务", value: "timer" },
        ],
      },

      remove: {
        show: false,
        model: null,
      },
    };
  },
  watch: {
    editorShow: {
      handler(val) {
        if (val) {
          this.editModel = JSON.parse(JSON.stringify(this.current));
          this.cancel = false;
        } else if (!this.cancel) {
          let newVal = JSON.stringify(this.editModel);
          let oldVal = JSON.stringify(this.current);
          if (newVal != oldVal) {
            this.save();
          }
        }
      },
    },
  },
  mounted() {
    client.$on("orgChange", this.orgChange);
    this.orgChange();
  },
  beforeDestroy() {
    client.$off("orgChange", this.orgChange);
  },
  methods: {
    showEditor(current, attr) {
      switch (attr) {
        case "jscript":
          return current.usejs;
        case "timers":
          return current.tasktype != 4;
      }
      return true;
    },
    async orgChange() {
      this.items = [];
      await this.getLinkAlarms();
      await this.getDevLinkages();
      await this.getTimers();
    },
    async getLinkAlarms() {
      client.$emit(
        "startBusy",
        "ConfigPolicy.getLinkAlarms",
        "正在获取告警关联,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.linkAlarmMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
        });
        if (res.links && res.links.length) {
          res.links.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.links.length; i++) {
            let link = res.links[i];
            link.modelType = "linkAlarm";
            this.items.push(link);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigPolicy.getLinkAlarms");
    },
    async getDevLinkages() {
      client.$emit(
        "startBusy",
        "ConfigPolicy.getDevLinkages",
        "正在获取设备联动,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.devLinkageMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
        });
        if (res.cco && res.cco.length) {
          res.cco.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.cco.length; i++) {
            let cco = res.cco[i];
            cco.modelType = "devLinkage";
            this.items.push(cco);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigPolicy.getDevLinkages");
    },
    async getTimers() {
      client.$emit(
        "startBusy",
        "ConfigPolicy.getTimers",
        "正在获取定时任务,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.timerMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
        });
        if (res.timers && res.timers.length) {
          res.timers.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.timers.length; i++) {
            let timer = res.timers[i];
            timer.modelType = "timer";
            this.items.push(timer);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigPolicy.getTimers");
    },
    async save() {
      try {
        let type;
        let msg = { mcd: {} };
        if (
          this.editModel.id == undefined &&
          this.editModel.modelType == "linkAlarm"
        ) {
          msg.mcd.operate = proto.OperateMode.createOpt;
          this.editModel.id = uuid();
        } else if (
          this.editModel.uuid === undefined &&
          this.editModel.modelType != "linkAlarm"
        ) {
          msg.mcd.operate = proto.OperateMode.createOpt;
          this.editModel.uuid = uuid();
        } else {
          msg.mcd.operate = proto.OperateMode.updateOpt;
        }
        switch (this.editModel.modelType) {
          case "linkAlarm":
            type = proto.MESSAGE_TYPE.linkAlarmMessage;
            msg.links = [this.editModel];
            msg.mcd.parentId = client.project.id;
            break;
          case "devLinkage":
            if (this.editModel.src.length == 0 || !this.editModel.dst.uuid) {
              throw "联动源或联动目标未设置";
            }
            type = proto.MESSAGE_TYPE.devLinkageMessage;
            msg.cco = [this.editModel];
            break;
          case "timer":
            type = proto.MESSAGE_TYPE.timerMessage;
            msg.timers = [this.editModel];
            break;
          default:
            throw "对象模型不正确";
        }
        client.$emit(
          "startBusy",
          "ConfigPolicy.save",
          "正在保存配置,请稍候..."
        );
        await proto.sleep(100);
        await client.send(type, msg);
        this.orgChange();
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigPolicy.save");
    },
    addSubmit() {
      if (!this.add.policy) {
        client.$emit("toast", "请选择类型");
        return;
      }
      let model;
      switch (this.add.policy) {
        case "linkAlarm":
          model = {
            id: undefined,
            name: this.newName("告警关联"),
            description: "",
            linkSrc: {
              id: "",
              name: "",
              linkType: 1,
            },
            linkvalue: true,
            linkDsts: [],
            orgid: client.org.id,
            modelType: "linkAlarm",
          };
          this.current = model;
          this.editorShow = true;
          break;
        case "devLinkage":
          model = {
            uuid: undefined,
            name: this.newName("设备联动"),
            src: [],
            usejs: false,
            jscript: "",
            operatortype: 1,
            convalue: 0,
            delaytime: 0,
            periodsid: { id: "", name: "" },
            dst: {
              uuid: "",
              name: "",
              dstvalue: "",
              priority: 1,
            },
            orgid: client.org.id,
            repeattimes: 0,
            intervaltime: 0,
            modelType: "devLinkage",
          };
          this.current = model;
          this.editorShow = true;
          break;
        case "timer":
          model = {
            uuid: undefined,
            name: this.newName("定时任务"),
            tasktype: 1,
            timers: [],
            content: {
              cps: [],
              srctype: 1,
              ids: [],
              smsnotifier: { id: "", name: "" },
              emailnotifier: { id: "", name: "" },
              persons: [],
              content: "",
              wechatnotifier: { id: "", name: "" },
            },
            orgid: client.org.id,
            modelType: "timer",
          };
          this.current = model;
          this.editorShow = true;
          break;
        default:
          break;
      }
      this.add.show = false;
    },
    async removeSubmit() {
      client.$emit(
        "startBusy",
        "ConfigPolicy.removeSubmit",
        "正在删除策略,请稍候..."
      );
      await proto.sleep(100);
      try {
        let type;
        let msg = { mcd: {} };
        msg.mcd.operate = proto.OperateMode.deleteOpt;
        switch (this.remove.model.modelType) {
          case "linkAlarm":
            type = proto.MESSAGE_TYPE.linkAlarmMessage;
            msg.mcd.range = "-1";
            msg.links = [this.remove.model];
            break;
          case "devLinkage":
            type = proto.MESSAGE_TYPE.devLinkageMessage;
            msg.cco = [this.remove.model];
            break;
          case "timer":
            type = proto.MESSAGE_TYPE.timerMessage;
            msg.mcd.range = "-1";
            msg.timers = [this.remove.model];
            break;
          default:
            throw "对象模型不正确";
        }
        await client.send(type, msg);
        this.orgChange();
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigPolicy.removeSubmit");
    },
    newName(name) {
      let index = 1;
      let result = name;
      let exist = true;
      while (exist) {
        result = `${name}${index}`;
        exist = false;
        for (let i in this.items) {
          let item = this.items[i];
          if (item.name == result) {
            exist = true;
            break;
          }
        }
        if (exist) index++;
        else break;
      }
      return result;
    },
  },
};
</script>
