<template>
  <v-dialog :value="show" max-width="800" persistent>
    <v-card>
      <v-card-title>
        <span>告警列表</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="getData">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table dense :headers="headers" :items="details">
        <template v-slot:[`item.obj`]="{ item }">
          <span>{{ objName(item.obj) }}</span>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span v-if="item.type == 1">监控主机</span>
          <span v-if="item.type == 2">设备</span>
          <span v-if="item.type == 3">测点</span>
          <span v-if="item.type == 4">页面</span>
          <span v-if="item.type == 5">机构</span>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";

export default {
  model: {
    prop: "show",
    event: "change",
  },
  props: { show: Boolean },
  data() {
    return {
      headers: [
        { text: "告警时间", value: "createtime", width: 168 },
        {
          text: "对象名称",
          value: "obj",
          width: 150,
          sort: (a, b) => {
            let aname = this.objName(a);
            let bname = this.objName(b);
            return aname.localeCompare(bname);
          },
        },
        { text: "对象类型", value: "type", width: 150 },
        { text: "告警描述", value: "description" },
      ],
      details: [],
    };
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.getData();
      },
    },
  },
  methods: {
    async getData() {
      client.$emit(
        "startBusy",
        "AlarmDetailDialog.getData",
        "正在获取数据,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(
          proto.MESSAGE_TYPE.realTimeDetailMessage,
          {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
            },
          }
        );
        this.details = res.details || [];
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "AlarmDetailDialog.getData");
    },
    objName(obj) {
      let result = [];
      if (obj.pname) result.push(obj.pname);
      if (obj.name) result.push(obj.name);
      return result.join("-");
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>