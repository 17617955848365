<style scoped>
.no-select {
  user-select: none;
}
</style>

<template>
  <div v-show="!hide">
    <v-navigation-drawer v-model="show" app temporary stateless width="320">
      <div class="d-flex flex-column fill-height">
        <div class="flex-grow-0">
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-sitemap</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="no-select">机构树</v-list-item-title>
              <v-list-item-subtitle class="no-select">Organization Tree</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="show = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
        <v-divider></v-divider>
        <v-toolbar class="flex-grow-0" dense flat>
          <v-toolbar-items>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="showAdd" v-bind="attrs" v-on="on">
                  <v-icon>mdi-plus-thick</v-icon>
                </v-btn>
              </template>
              <span>添加机构</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="showRemove" v-bind="attrs" v-on="on">
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </template>
              <span>删除机构</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="showEdit" v-bind="attrs" v-on="on">
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>编辑机构</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="upward" v-bind="attrs" v-on="on">
                  <v-icon>mdi-arrow-up-bold</v-icon>
                </v-btn>
              </template>
              <span>上移</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="downward" v-bind="attrs" v-on="on">
                  <v-icon>mdi-arrow-down-bold</v-icon>
                </v-btn>
              </template>
              <span>下移</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="initOrganizations" v-bind="attrs" v-on="on">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>刷新</span>
            </v-tooltip>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <div class="flex-grow-1" style="overflow: auto">
          <v-treeview
            :items="orgs"
            :active.sync="selected"
            :open.sync="open"
            @update:active="change"
            item-children="child"
            hoverable
            activatable
            transition
            dense
          >
            <template v-slot:prepend="{ item }">
              <v-icon>
                {{ item.orgtype == 1 ? "mdi-home-city" : "mdi-home-variant" }}
              </v-icon>
            </template>
          </v-treeview>
        </div>
      </div>
    </v-navigation-drawer>
    <v-dialog v-model="edit.show" persistent width="320">
      <v-card v-if="edit.model">
        <v-card-title>{{ edit.model.id ? "编辑" : "新建" }}机构</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="edit.model.name"
            label="名称"
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="submit">确定</v-btn>
          <v-btn text @click="edit.show = false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-confirm
      v-model="remove.show"
      label="是否删除机构?"
      @ok="removeSubmit"
    ></v-confirm>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
import uuid from "../utils/uuid";

export default {
  props: { hide: Boolean },
  data() {
    return {
      show: false,
      orgs: [],
      selected: [],
      open: [],
      last: null,

      edit: {
        show: false,
        model: null,
        parent: null,
      },

      remove: {
        show: false,
        model: null,
      },
    };
  },
  watch: {
    hide(value) {
      if (!value) this.initOrganizations();
    },
  },
  mounted() {
    client.$on("showOrganization", this.onShowOrganization);
  },
  beforeDestroy() {
    client.$off("showOrganization", this.onShowOrganization);
  },
  methods: {
    onShowOrganization() {
      this.show = true;
      this.initOrganizations();
    },
    async initOrganizations() {
      client.$emit(
        "startBusy",
        "OrgDrawer.initOrganizations",
        "正在获取机构,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.organizationMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
            parentId: client.project.id,
          },
        });
        this.deepSort(res.orgs);
        this.orgs = res.orgs;
        let open = [];
        if (client.org.id) {
          this.selected = [client.org.id];
          open = this.findPath(client.org.id, this.orgs);
        } else {
          open = [client.org.id];
        }
        this.open = open;
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "OrgDrawer.initOrganizations");
    },
    deepSort(orgs) {
      orgs.sort((a, b) => {
        return a.sequence - b.sequence;
      });
      for (let i in orgs) {
        if (orgs[i].child && orgs[i].child.length > 0) {
          this.deepSort(orgs[i].child);
        }
      }
    },
    findPath(id, items) {
      for (let i in items) {
        let item = items[i];
        if (item.id == id) {
          if (item.child) {
            return [item.id];
          } else {
            return [];
          }
        }
      }
      for (let i in items) {
        let item = items[i];
        if (item.child) {
          let sub = this.findPath(id, item.child);
          if (sub !== null) {
            sub.splice(0, 0, item.id);
            return sub;
          }
        }
      }
      return [];
    },
    findModel(id, root) {
      for (let i in root) {
        let model = root[i];
        if (model.id == id) {
          return model;
        }
        if (model.child) {
          let child = this.findModel(id, model.child);
          if (child) {
            return child;
          }
        }
      }
      return null;
    },
    change(selections) {
      if (selections.length) {
        let current = selections[0];
        if (client.org.id != current) {
          let model = this.findModel(current, this.orgs);
          if (model) {
            client.setOrg(model.id, model.name);
            this.show = false;
          }
        }
      } else if (client.org.id) {
        let model = this.findModel(client.org.id, this.orgs);
        if (model) {
          this.selected = [client.org.id];
        } else if (this.orgs.length) {
          this.selected = [this.orgs[0].id];
        } else {
          client.setOrg("", "无机构");
        }
      }
    },
    /** CRUD */
    showAdd() {
      if (this.selected.length) {
        let id = this.selected[0];
        let org = this.findModel(id, this.orgs);
        if (org) {
          let max = 0;
          for (let i in org.child) {
            if (max < org.child[i].sequence) {
              max = org.child[i].sequence;
            }
          }
          this.edit.model = {
            name: "",
            selfid: org.id,
            orgtype: 1,
            sequence: max + 1,
          };
          this.edit.show = true;
        }
      }
    },
    showEdit() {
      if (this.selected.length) {
        let id = this.selected[0];
        let org = this.findModel(id, this.orgs);
        if (org) {
          let model = JSON.parse(JSON.stringify(org));
          model.child = [];
          this.edit.model = model;
          this.edit.show = true;
        }
      }
    },
    async submit() {
      client.$emit("startBusy", "OrgDrawer.submit", "正在提交机构,请稍候...");
      await proto.sleep(100);
      try {
        let operate;
        let model = this.edit.model;
        if (model.id) {
          operate = proto.OperateMode.updateOpt;
        } else {
          operate = proto.OperateMode.createOpt;
          model.id = uuid();
        }
        await client.send(proto.MESSAGE_TYPE.organizationMessage, {
          mcd: { operate },
          orgs: [model],
        });
        this.initOrganizations();
        this.edit.show = false;
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "OrgDrawer.submit");
    },
    showRemove() {
      if (this.selected.length) {
        let id = this.selected[0];
        let org = this.findModel(id, this.orgs);
        if (org) {
          let model = JSON.parse(JSON.stringify(org));
          model.child = [];
          this.remove.model = model;
          this.remove.show = true;
        }
      }
    },
    async removeSubmit() {
      client.$emit(
        "startBusy",
        "OrgDrawer.removeSubmit",
        "正在删除机构,请稍候..."
      );
      await proto.sleep(100);
      try {
        let model = this.remove.model;
        await client.send(proto.MESSAGE_TYPE.organizationMessage, {
          mcd: { operate: proto.OperateMode.deleteOpt, range: "-1" },
          orgs: [model],
        });
        this.initOrganizations();
        this.remove.show = false;
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "OrgDrawer.removeSubmit");
    },
    upward() {
      if (this.selected.length) {
        let id = this.selected[0];
        let org = this.findModel(id, this.orgs);
        if (org) {
          let parentId = org.selfid;
          let parent = this.findModel(parentId, this.orgs);
          if (parent) {
            let array = JSON.parse(JSON.stringify(parent.child));
            for (let i = 0; i < array.length; i++) {
              if (array[i].id == id && i > 0) {
                let model = array[i];
                array.splice(i, 1);
                array.splice(i - 1, 0, model);
                break;
              }
            }
            for (let i = 0; i < array.length; i++) {
              array[i].sequence = 10000 + i;
            }
            this.sortSubmit(array);
          }
        }
      }
    },
    downward() {
      if (this.selected.length) {
        let id = this.selected[0];
        let org = this.findModel(id, this.orgs);
        if (org) {
          let parentId = org.selfid;
          let parent = this.findModel(parentId, this.orgs);
          if (parent) {
            let array = JSON.parse(JSON.stringify(parent.child));
            for (let i = 0; i < array.length; i++) {
              if (array[i].id == id && i < array.length - 1) {
                let model = array[i];
                array.splice(i, 1);
                array.splice(i + 1, 0, model);
                break;
              }
            }
            for (let i = 0; i < array.length; i++) {
              array[i].sequence = 10000 + i;
            }
            this.sortSubmit(array);
          }
        }
      }
    },
    async sortSubmit(orgs) {
      client.$emit(
        "startBusy",
        "OrgDrawer.sortSubmit",
        "正在排序机构,请稍候..."
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.organizationMessage, {
          mcd: { operate: proto.OperateMode.modifyOpt },
          orgs: orgs,
        });
        this.initOrganizations();
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "OrgDrawer.sortSubmit");
    },
  },
};
</script>
