<template>
  <v-dialog :value="show" persistent max-width="480">
    <v-form @submit.prevent="submit">
      <v-card>
        <v-card-title class="text-subtitle-1">测点控制</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field label="测点名称" :value="name" readonly></v-text-field>
          <v-text-field label="测点值" v-model="value" autofocus></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain type="submit" color="success">确定</v-btn>
          <v-btn plain @click="cancel">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  model: {
    prop: "show",
    event: "change",
  },
  props: ["uuid", "name", "show"],
  data() {
    return {
      value: "",
    };
  },
  methods: {
    submit() {
      this.$emit("submit", this.uuid, this.value);
      this.$emit("change", false);
    },
    cancel() {
      this.$emit("change", false);
    },
  },
};
</script>
