<style scoped>
.no-select {
  user-select: none;
}
</style>

<template>
  <div v-show="!hide">
    <v-navigation-drawer v-model="show" app temporary stateless width="268">
      <div class="d-flex flex-column fill-height">
        <div class="flex-grow-0">
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-apps</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="no-select">功能菜单</v-list-item-title>
              <v-list-item-subtitle class="no-select">
                Menu
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="show = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
        <v-divider></v-divider>
        <div class="fill-height" style="overflow: auto">
          <div v-for="item in items" :key="item.name">
            <template v-if="item.component">
              <v-btn
                large
                block
                tile
                depressed
                :color="viewId == item.name ? 'primary' : 'transparent'"
                style="justify-content: left"
                @click="changeView(item)"
              >
                <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                <span class="ml-3">{{ item.title }}</span>
              </v-btn>
            </template>
            <div v-else class="px-4 pt-4 pb-1">
              <h3 class="no-select">{{ item.title }}</h3>
              <v-divider></v-divider>
            </div>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import views from "../views";
import client from "../utils/client";

export default {
  props: { hide: Boolean },
  data() {
    return {
      show: false,
      items: [],
    };
  },
  computed: {
    viewId() {
      return client.view.id;
    },
  },
  created() {
    client.$on("showMenu", this.onShowMenu);
    this.items = views.views;
  },
  beforeDestroy() {
    client.$off("showMenu", this.onShowMenu);
  },
  methods: {
    onShowMenu() {
      this.show = true;
    },
    changeView(view) {
      client.view.id = view.name;
      client.view.name = view.title;
      client.$emit("viewChange", view.name);
      this.show = false;
    },
  },
};
</script>
