var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-text-field',{attrs:{"label":"页面绑定","value":((_vm.value.length) + "项"),"readonly":"","filled":"","background-color":"transparent","hide-details":""}},[_c('template',{slot:"append"},[_c('v-btn',{attrs:{"icon":"","tile":""},on:{"click":_vm.showDialog}},[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1),_c('v-dialog',{attrs:{"max-width":"640","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('div',[_vm._v("页面绑定配置")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.replaceShow = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-restore-outline")])],1)]}}])},[_c('span',[_vm._v("快速替换绑定源")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.addShow = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("添加绑定")])]),(_vm.addShow)?_c('AddBinding',{attrs:{"controls":_vm.controls},on:{"close":_vm.addClose}}):_vm._e(),_c('ReplaceBinding',{attrs:{"controls":_vm.controls,"bindings":_vm.items},on:{"submit":_vm.replaceSubmit},model:{value:(_vm.replaceShow),callback:function ($$v) {_vm.replaceShow=$$v},expression:"replaceShow"}})],1),_c('v-divider'),_c('v-data-table',{staticStyle:{"height":"360px","overflow-y":"auto"},attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.uuid",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"textContent":_vm._s(_vm.controlName(item.uuid))}})]}},{key:"item.prop",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"textContent":_vm._s(_vm.propertyName(item.prop))}})]}},{key:"item.srcType",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"textContent":_vm._s(_vm.srcTypes[item.srcType])}})]}},{key:"item.valueType",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"textContent":_vm._s(_vm.valueTypes[item.valueType] || item.valueType)}})]}},{key:"item.src",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"textContent":_vm._s(item.src.length)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)}),(_vm.editShow)?_c('BindingSource',{attrs:{"value":_vm.editModel,"controls":_vm.controls},on:{"close":_vm.editClose}}):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"success"},on:{"click":_vm.submit}},[_vm._v("确定")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v("取消")])],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }