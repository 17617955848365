<template>
  <v-text-field
    :label="label"
    :value="valueDisplay"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>作为告警发送器</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-switch label="启用" v-model="enable"></v-switch>
            <v-select
              label="发送器类型"
              v-model="method"
              :items="methods"
            ></v-select>
            <v-select
              label="发送时段"
              v-model="period"
              :items="periods"
            ></v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value", "model"],
  data() {
    return {
      show: false,

      enable: false,
      method: 0,
      methods: [
        { text: "告警展示", value: 0 },
        { text: "邮件告警", value: 1 },
        { text: "电话告警", value: 2 },
        { text: "短信告警", value: 4 },
        { text: "声光告警", value: 8 },
        { text: "广播告警", value: 16 },
        { text: "微信告警", value: 32 },
      ],
      period: "",
      periods: [],
    };
  },
  computed: {
    valueDisplay() {
      if (!this.value.spot || !this.value.spot.id) {
        return "";
      }
      switch (this.value.method) {
        case 0:
          return "告警展示";
        case 1:
          return "邮件告警";
        case 2:
          return "电话告警";
        case 4:
          return "短信告警";
        case 8:
          return "声光告警";
        case 16:
          return "广播告警";
        case 32:
          return "微信告警";
      }
      return "";
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          if (this.value.spot && this.value.spot.id) {
            this.enable = true;
          } else {
            this.enable = false;
          }
          this.method = this.value.method;
          if (this.value.periodsid && this.value.periodsid.id) {
            this.period = this.value.periodsid.id;
          } else {
            this.period = "";
          }
          this.initSource();
        }
      },
    },
  },
  methods: {
    async initSource() {
      let result = [{ text: "不设置", value: "" }];
      client.$emit(
        "startBusy",
        "NotifierEditor.initSource",
        "正在获取时段,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.periodMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
        });
        if (res.periods) {
          res.periods.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i in res.periods) {
            result.push({
              text: res.periods[i].name,
              value: res.periods[i].id,
            });
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "NotifierEditor.initSource");
      this.periods = result;
    },
    submit() {
      if (this.enable) {
        this.$emit("change", {
          device: null,
          spot: {
            id: this.model.uuid,
            name: this.model.name,
          },
          method: this.method,
          periodsid: {
            id: this.period,
          },
        });
      } else {
        this.$emit("change", {
          device: null,
          spot: null,
          method: 0,
          periodsid: null,
        });
      }
      this.show = false;
    },
  },
};
</script>
