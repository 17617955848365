<template>
  <v-text-field
    label="页面绑定"
    :value="`${value.length}项`"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="showDialog">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" max-width="640" persistent>
        <v-card>
          <v-card-title>
            <div>页面绑定配置</div>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="replaceShow = true"
                >
                  <v-icon>mdi-file-restore-outline</v-icon>
                </v-btn>
              </template>
              <span>快速替换绑定源</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="addShow = true">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>添加绑定</span>
            </v-tooltip>
            <AddBinding
              v-if="addShow"
              :controls="controls"
              @close="addClose"
            ></AddBinding>
            <ReplaceBinding
              v-model="replaceShow"
              :controls="controls"
              :bindings="items"
              @submit="replaceSubmit"
            ></ReplaceBinding>
          </v-card-title>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            disable-pagination
            style="height: 360px; overflow-y: auto"
          >
            <template v-slot:[`item.uuid`]="{ item }">
              <div v-text="controlName(item.uuid)"></div>
            </template>
            <template v-slot:[`item.prop`]="{ item }">
              <div v-text="propertyName(item.prop)"></div>
            </template>
            <template v-slot:[`item.srcType`]="{ item }">
              <div v-text="srcTypes[item.srcType]"></div>
            </template>
            <template v-slot:[`item.valueType`]="{ item }">
              <div v-text="valueTypes[item.valueType] || item.valueType"></div>
            </template>
            <template v-slot:[`item.src`]="{ item }">
              <div v-text="item.src.length"></div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon @click="edit(item)">
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-btn>
              <v-btn icon @click="remove(item)">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <BindingSource
            v-if="editShow"
            :value="editModel"
            :controls="controls"
            @close="editClose"
          ></BindingSource>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import editors from "../editor";
import AddBinding from "../../components/AddBinding.vue";
import ReplaceBinding from "../../components/ReplaceBinding.vue";
import BindingSource from "../../components/BindingSource.vue";
export default {
  components: { AddBinding, ReplaceBinding, BindingSource },
  model: {
    prop: "value",
    event: "change",
  },
  props: ["controls", "value"],
  data() {
    return {
      srcTypes: {
        org: "机构",
        agent: "监控单元",
        device: "设备",
        spot: "测点",
        statistics: "实时统计",
        api: "API",
      },
      valueTypes: {
        alarm: "告警状态",
        status: "联机状态",
        data: "实时数据",
        history: "历史数据",
        statistics: "实时统计值",
      },
      headers: [
        {
          text: "控件",
          value: "uuid",
        },
        {
          text: "属性",
          value: "prop",
        },
        {
          text: "源类型",
          value: "srcType",
        },
        {
          text: "数据类型",
          value: "valueType",
        },
        {
          text: "源数量",
          value: "src",
        },
        {
          text: "操作",
          value: "actions",
          sortable: false,
        },
      ],
      items: [],
      show: false,
      addShow: false,
      editShow: false,
      editModel: null,
      replaceShow: false,
    };
  },
  methods: {
    showDialog() {
      this.items = JSON.parse(JSON.stringify(this.value));
      this.show = true;
    },
    controlName(id) {
      for (let i in this.controls) {
        if (this.controls[i].uuid == id) return this.controls[i].name;
      }
      return "已删除";
    },
    propertyName(prop) {
      if (editors[prop]) return editors[prop].name;
      return "未知属性";
    },
    addClose(model) {
      this.addShow = false;
      if (model) {
        this.items.push(model);
        this.editModel = model;
        this.editShow = true;
      }
    },
    edit(item) {
      this.editModel = item;
      this.editShow = true;
    },
    editClose(model) {
      this.editShow = false;
      if (model) {
        this.editModel.src = model.src;
        this.editModel.JScript = model.JScript;
        this.editModel.interval = model.interval;
        this.editModel.param = model.param;
      }
    },
    remove(item) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i] == item) {
          this.items.splice(i, 1);
          break;
        }
      }
    },
    submit() {
      this.change(this.items);
      this.show = false;
    },
    change(value) {
      this.$emit("change", value);
    },
    replaceSubmit(changes) {
      for (let i in changes) {
        let change = changes[i];
        for (let j in this.items) {
          let item = this.items[j];
          if (change.uuid == item.uuid) {
            item.src = [{ id: change.spotId, name: change.spotName }];
          }
        }
      }
    },
  },
};
</script>
