var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hide),expression:"!hide"}]},[_c('v-navigation-drawer',{attrs:{"app":"","temporary":"","stateless":"","width":"320"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"d-flex flex-column fill-height"},[_c('div',{staticClass:"flex-grow-0"},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-sitemap")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"no-select"},[_vm._v("机构树")]),_c('v-list-item-subtitle',{staticClass:"no-select"},[_vm._v("Organization Tree")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-divider'),_c('v-toolbar',{staticClass:"flex-grow-0",attrs:{"dense":"","flat":""}},[_c('v-toolbar-items',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.showAdd}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-thick")])],1)]}}])},[_c('span',[_vm._v("添加机构")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.showRemove}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-forever")])],1)]}}])},[_c('span',[_vm._v("删除机构")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.showEdit}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}])},[_c('span',[_vm._v("编辑机构")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.upward}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-up-bold")])],1)]}}])},[_c('span',[_vm._v("上移")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.downward}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-down-bold")])],1)]}}])},[_c('span',[_vm._v("下移")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.initOrganizations}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("刷新")])])],1)],1),_c('v-divider'),_c('div',{staticClass:"flex-grow-1",staticStyle:{"overflow":"auto"}},[_c('v-treeview',{attrs:{"items":_vm.orgs,"active":_vm.selected,"open":_vm.open,"item-children":"child","hoverable":"","activatable":"","transition":"","dense":""},on:{"update:active":[function($event){_vm.selected=$event},_vm.change],"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(item.orgtype == 1 ? "mdi-home-city" : "mdi-home-variant")+" ")])]}}])})],1)],1)]),_c('v-dialog',{attrs:{"persistent":"","width":"320"},model:{value:(_vm.edit.show),callback:function ($$v) {_vm.$set(_vm.edit, "show", $$v)},expression:"edit.show"}},[(_vm.edit.model)?_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.edit.model.id ? "编辑" : "新建")+"机构")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"名称","hide-details":""},model:{value:(_vm.edit.model.name),callback:function ($$v) {_vm.$set(_vm.edit.model, "name", $$v)},expression:"edit.model.name"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"success"},on:{"click":_vm.submit}},[_vm._v("确定")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.edit.show = false}}},[_vm._v("取消")])],1)],1):_vm._e()],1),_c('v-confirm',{attrs:{"label":"是否删除机构?"},on:{"ok":_vm.removeSubmit},model:{value:(_vm.remove.show),callback:function ($$v) {_vm.$set(_vm.remove, "show", $$v)},expression:"remove.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }