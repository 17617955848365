<template>
  <v-dialog :value="value" persistent max-width="480">
    <v-card>
      <v-card-title class="text-subtitle-1">操作确认</v-card-title>
      <v-card-text
        v-text="label"
        class="text-h5 font-weight-bold text-center"
      ></v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain @click="ok" color="success">{{ okText || "确定" }}</v-btn>
        <v-btn plain @click="cancel">{{ cancelText || "取消" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value", "okText", "cancelText"],
  methods: {
    ok() {
      this.$emit("ok");
      this.$emit("change", false);
    },
    cancel() {
      this.$emit("cancel");
      this.$emit("change", false);
    },
  },
};
</script>
