<template>
  <v-dialog :value="show" persistent max-width="480">
    <v-card>
      <v-card-title>
        <div>{{ isEdit ? "编辑用户" : "新建用户" }}</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="max-height: 360px; overflow-y: auto">
        <v-text-field
          v-model="account"
          label="用户名*"
          :readonly="isEdit"
          hide-details
        ></v-text-field>
        <v-text-field
          v-if="!isEdit"
          v-model="password"
          label="密码*"
          type="password"
          hide-details
        ></v-text-field>
        <v-text-field
          v-if="!isEdit"
          v-model="passwordRe"
          label="确认密码*"
          type="password"
          hide-details
        ></v-text-field>
        <v-text-field v-model="name" label="姓名*" hide-details></v-text-field>
        <v-select
          v-model="sex"
          :items="sexs"
          label="性别"
          hide-details
        ></v-select>
        <v-text-field v-model="empno" label="工号" hide-details></v-text-field>
        <v-text-field
          v-model="company"
          label="部门"
          hide-details
        ></v-text-field>
        <v-text-field
          v-model="position"
          label="职位"
          hide-details
        ></v-text-field>
        <v-text-field
          v-model="telephone"
          label="语音号码"
          hide-details
        ></v-text-field>
        <v-text-field
          v-model="mobilephone"
          label="短信号码"
          hide-details
        ></v-text-field>
        <v-text-field v-model="email" label="邮箱" hide-details></v-text-field>
        <v-select
          v-model="usergroup"
          label="角色"
          :items="usergroups"
          chips
          multiple
          hide-details
        >
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" v-bind="attrs" v-on="on">
                  mdi-comment-question
                </v-icon>
              </template>
              <div v-for="tip in tips" :key="tip">{{ tip }}</div>
            </v-tooltip>
          </template>
        </v-select>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="submit">确定</v-btn>
        <v-btn text @click="close">取消</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import uuid from "../utils/uuid";
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  model: {
    prop: "show",
    event: "change",
  },
  props: { show: Boolean, model: Object },
  data() {
    return {
      sexs: [
        { text: "男", value: 1 },
        { text: "女", value: 2 },
      ],
      account: "",
      password: "",
      passwordRe: "",
      name: "",
      sex: 1,
      empno: "",
      company: "",
      position: "",
      telephone: "",
      mobilephone: "",
      email: "",
      usergroup: [],

      tips: [],
      usergroups: [],
    };
  },
  computed: {
    isEdit() {
      return this.model.uuid !== undefined;
    },
  },
  watch: {
    show: function () {
      if (this.show) {
        this.account = this.model.user ? this.model.user.name : "";
        this.password = "";
        this.passwordRe = "";
        this.name = this.model.name || "";
        this.sex = this.model.sex || 1;
        this.empno = this.model.empno || "";
        this.company = this.model.company || "";
        this.position = this.model.position || "";
        this.telephone = this.model.telephone || "";
        this.mobilephone = this.model.mobilephone || "";
        this.email = this.model.email || "";
        this.usergroup = [];
        if (this.model.usergroup) {
          for (let i in this.model.usergroup) {
            this.usergroup.push(this.model.usergroup[i].id);
          }
        }
      }
    },
  },
  mounted() {
    this.getUserGroups();
  },
  methods: {
    async getUserGroups() {
      let result = [];
      let tips = [];
      try {
        client.$emit(
          "startBusy",
          "UserDialog.getUserGroups",
          "正在获取信息,请稍候..."
        );
        await proto.sleep(100);
        let res = await client.send(proto.MESSAGE_TYPE.userGroupMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
        });
        if (res.userGroup && res.userGroup.length) {
          for (let i in res.userGroup) {
            let group = res.userGroup[i];
            result.push({
              text: group.name,
              value: group.id,
            });
            tips.push(`${group.name}：${group.description}`);
          }
        }
      } catch (e) {
        client.$emit("toast", e.toString());
      }
      client.$emit("endBusy", "UserDialog.getUserGroups");
      this.tips = tips;
      this.usergroups = result;
    },
    submit() {
      if (this.isEdit) {
        this.update();
      } else {
        this.add();
      }
    },
    async add() {
      try {
        if (
          !this.account ||
          !this.name ||
          !this.password ||
          this.password != this.passwordRe
        ) {
          client.$emit("toast", "信息不完整");
          return;
        }
        let userId = uuid();
        let personId = uuid();
        let groups = [];
        for (let i in this.usergroup) {
          groups.push({ id: this.usergroup[i] });
        }
        client.$emit("startBusy", "UserDialog.add1", "正在创建账号,请稍候...");
        await proto.sleep(100);
        await client.send(proto.MESSAGE_TYPE.userMessage, {
          mcd: {
            operate: proto.OperateMode.createOpt,
          },
          users: [
            {
              uuid: userId,
              account: this.account,
              password: this.password,
              type: 2,
              groups,
              loginPeriods: [
                {
                  id: uuid(),
                  begintime: "00:00:00",
                  endtime: "23:59:59",
                  week: 1,
                },
                {
                  id: uuid(),
                  begintime: "00:00:00",
                  endtime: "23:59:59",
                  week: 2,
                },
                {
                  id: uuid(),
                  begintime: "00:00:00",
                  endtime: "23:59:59",
                  week: 3,
                },
                {
                  id: uuid(),
                  begintime: "00:00:00",
                  endtime: "23:59:59",
                  week: 4,
                },
                {
                  id: uuid(),
                  begintime: "00:00:00",
                  endtime: "23:59:59",
                  week: 5,
                },
                {
                  id: uuid(),
                  begintime: "00:00:00",
                  endtime: "23:59:59",
                  week: 6,
                },
                {
                  id: uuid(),
                  begintime: "00:00:00",
                  endtime: "23:59:59",
                  week: 7,
                },
              ],
            },
          ],
        });
        client.$emit("startBusy", "UserDialog.add2", "正在创建用户,请稍候...");
        client.$emit("endBusy", "UserDialog.add1");
        await proto.sleep(100);
        await client.send(proto.MESSAGE_TYPE.personMessage, {
          mcd: {
            operate: proto.OperateMode.createOpt,
            parentId: client.org.id,
          },
          Persons: [
            {
              uuid: personId,
              name: this.name,
              sex: this.sex,
              empno: this.empno,
              company: this.company,
              position: this.position,
              telephone: this.telephone,
              mobilephone: this.mobilephone,
              email: this.email,
              user: {
                id: userId,
              },
              orgid: client.org.id,
              usergroup: [],
            },
          ],
        });
        this.$emit("update");
        this.close();
      } catch (e) {
        client.$emit("toast", e.toString());
      }
      client.$emit("endBusy", "UserDialog.add1");
      client.$emit("endBusy", "UserDialog.add2");
    },
    async update() {
      try {
        client.$emit(
          "startBusy",
          "UserDialog.update",
          "正在修改用户,请稍候..."
        );
        await proto.sleep(100);
        let model = JSON.parse(JSON.stringify(this.model));
        model.name = this.name;
        model.sex = this.sex;
        model.empno = this.empno;
        model.company = this.company;
        model.position = this.position;
        model.telephone = this.telephone;
        model.mobilephone = this.mobilephone;
        model.email = this.email;
        model.usergroup = [];
        for (let i in this.usergroup) {
          model.usergroup.push({ id: this.usergroup[i] });
        }
        await client.send(proto.MESSAGE_TYPE.personMessage, {
          mcd: {
            operate: proto.OperateMode.updateOpt,
          },
          Persons: [model],
        });
        this.$emit("update");
        this.close();
      } catch (e) {
        client.$emit("toast", e.toString());
      }
      client.$emit("endBusy", "UserDialog.update");
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>
