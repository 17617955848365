<template>
  <v-dialog :value="show" persistent max-width="480">
    <v-card>
      <v-card-title>
        <div>动作编辑</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-select
          v-model="action.type"
          label="动作类型"
          :items="types"
        ></v-select>
        <v-text-field
          v-show="action.type == 'navigate'"
          :value="action.page.name"
          label="页面"
          readonly
        >
          <template slot="append">
            <v-btn icon tile x-small @click="pageShow = true">
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <ObjectSelector
          v-model="pageShow"
          type="page"
          :single="true"
          @select="pageSelect"
        ></ObjectSelector>
        <v-text-field
          v-show="action.type == 'spot-ctrl'"
          :value="action.spot.name"
          label="测点"
          readonly
        >
          <template slot="append">
            <v-btn icon tile x-small @click="spotShow = true">
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <ObjectSelector
          v-model="spotShow"
          type="spot"
          :single="true"
          @select="spotSelect"
        ></ObjectSelector>
        <v-select
          v-show="action.type == 'spot-ctrl'"
          v-model="action.srcType"
          label="值来源"
          :items="srcTypes"
        ></v-select>
        <v-select
          v-show="
            action.type == 'prop-set' ||
            (action.type == 'spot-ctrl' && action.srcType == 'property')
          "
          v-model="action.uuid"
          label="控件"
          item-value="uuid"
          item-text="name"
          :items="controls"
        ></v-select>
        <v-select
          v-show="
            action.type == 'prop-set' ||
            (action.type == 'spot-ctrl' && action.srcType == 'property')
          "
          v-model="action.prop"
          label="属性"
          :items="props"
        ></v-select>
        <v-text-field
          v-show="
            action.type == 'prop-set' ||
            (action.type == 'spot-ctrl' && action.srcType == 'const')
          "
          v-model="action.value"
          label="值"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="submit">确定</v-btn>
        <v-btn text @click="close">取消</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ObjectSelector from "../components/ObjectSelector.vue";
import editors from "../zht-ui/editor";
export default {
  components: { ObjectSelector },
  model: {
    prop: "show",
    event: "change",
  },
  props: { show: Boolean, model: Object, controls: Array },
  data() {
    return {
      pageShow: false,
      spotShow: false,
      types: [
        { text: "设置控件属性", value: "prop-set" },
        { text: "设置测点值", value: "spot-ctrl" },
        { text: "页面跳转", value: "navigate" },
      ],
      srcTypes: [
        { text: "控件属性", value: "property" },
        { text: "常量", value: "const" },
      ],
      props: [],
      action: {
        type: "",
        srcType: "",
        spot: {
          uuid: "",
          name: "",
        },
        page: {
          uuid: "",
          name: "",
        },
        uuid: "",
        prop: "",
        value: "",
      },
    };
  },
  watch: {
    show: function () {
      if (this.show) {
        let baseAction = this.model.action;
        this.action.type = baseAction.type || "spot-ctrl";
        this.action.srcType = baseAction.srcType || "const";
        let spot = baseAction.spot || { uuid: "", name: "" };
        this.action.spot.uuid = spot.uuid || "";
        this.action.spot.name = spot.name || "";
        let page = baseAction.page || { uuid: "", name: "" };
        this.action.page.uuid = page.uuid || "";
        this.action.page.name = page.name || "";
        this.action.uuid = baseAction.uuid || "";
        this.action.prop = baseAction.prop || "";
        this.action.value = baseAction.value || "";
      }
    },
    "action.uuid": function () {
      let result = [];
      if (this.action.uuid) {
        for (let i in this.controls) {
          if (this.controls[i].uuid == this.action.uuid) {
            let control = this.controls[i];
            let bases = ["size", "location", "style", "config"];
            for (let j in bases) {
              let base = bases[j];
              for (let attr in control[base]) {
                let value = `${base}.${attr}`;
                if (editors[value]) {
                  let text = editors[value].name;
                  result.push({ text, value });
                }
              }
            }
            break;
          }
        }
      }
      this.props = result;
    },
  },
  methods: {
    pageSelect(model) {
      this.action.page.uuid = model.id;
      this.action.page.name = model.name;
    },
    spotSelect(model) {
      this.action.spot.uuid = model.id;
      this.action.spot.name = model.name;
    },
    submit() {
      this.$emit("submit", JSON.parse(JSON.stringify(this.action)));
      this.close();
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>
