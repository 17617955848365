<template>
  <div class="main">
    <v-navbar></v-navbar>
    <v-expansion-panels dark class="flex-shrink-0">
      <v-expansion-panel style="background-color: transparent">
        <v-expansion-panel-header>筛选条件</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row align="baseline">
              <v-col cols="6" sm="4">
                <v-autocomplete
                  label="监控单元"
                  v-model="agent"
                  :items="agentSuggest"
                  @update:search-input="agentName = $event"
                  @change="getDevices"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="4">
                <v-autocomplete
                  label="设备"
                  v-model="device"
                  :items="deviceSuggest"
                  @update:search-input="deviceName = $event"
                  @change="getSpots"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="4">
                <v-autocomplete
                  label="测点"
                  v-model="spot"
                  :items="spotSuggest"
                  @update:search-input="spotName = $event"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="4">
                <v-select
                  label="时间条件"
                  v-model="condition"
                  :items="conditions"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="6" sm="4">
                <v-menu
                  v-model="dateStartShow"
                  :close-on-content-click="false"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateStart"
                      :label="condition == 6 ? '开始日期' : '日期'"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateStart"
                    :max="dateEnd"
                    @input="dateStartShow = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="4" v-if="condition == 6">
                <v-menu
                  v-model="dateEndShow"
                  :close-on-content-click="false"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateEnd"
                      label="结束日期"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateEnd"
                    :min="dateStart"
                    @input="dateEndShow = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="4">
                <v-btn block color="rgba(255,255,255,0.2)" @click="query(1)">
                  查询
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="scroll flex-grow-1 flex-shrink-1">
      <v-data-table
        :headers="headers"
        :items="records"
        dark
        style="background-color: transparent"
        hide-default-footer
        disable-pagination
      ></v-data-table>
    </div>
    <v-divider></v-divider>
    <v-pagination
      v-model="pageIndex"
      :length="pageCount"
      :total-visible="7"
      @input="query($event)"
    ></v-pagination>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
import base64 from "../utils/base64";
export default {
  data() {
    return {
      headers: [
        {
          text: "监控单元",
          value: "aname",
          sortable: false,
        },
        {
          text: "设备",
          value: "dname",
          sortable: false,
        },
        {
          text: "测点",
          value: "sname",
          sortable: false,
        },
        {
          text: "测点值",
          value: "sv",
          sortable: false,
        },
        {
          text: "时间",
          value: "datetime",
          sortable: false,
        },
      ],

      agent: undefined,
      agentName: "",
      agents: [],

      device: undefined,
      deviceName: "",
      devices: [],

      spot: undefined,
      spotName: "",
      spots: [],

      condition: 1,
      conditions: [
        { text: "=", value: 1 },
        { text: ">", value: 2 },
        { text: ">=", value: 4 },
        { text: "<", value: 3 },
        { text: "<=", value: 5 },
        { text: "时间段", value: 6 },
      ],

      dateStartShow: false,
      dateStart: undefined,
      dateEndShow: false,
      dateEnd: undefined,

      records: [],
      pageIndex: 0,
      pageCount: 0,
      recordCount: 0,
    };
  },
  computed: {
    agentSuggest() {
      let result = JSON.parse(JSON.stringify(this.agents));
      if (this.agentName) {
        result.push({ text: this.agentName, value: this.agentName });
      }
      return result;
    },
    deviceSuggest() {
      let result = JSON.parse(JSON.stringify(this.devices));
      if (this.deviceName) {
        result.push({ text: this.deviceName, value: this.deviceName });
      }
      return result;
    },
    spotSuggest() {
      let result = JSON.parse(JSON.stringify(this.spots));
      if (this.spotName) {
        result.push({ text: this.spotName, value: this.spotName });
      }
      return result;
    },
  },
  mounted() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) month = "0" + month;
    let day = date.getDate();
    if (day < 10) day = "0" + day;
    this.dateStart = this.dateEnd = `${year}-${month}-${day}`;
    client.$on("orgChange", this.orgChange);
    this.orgChange();
  },
  beforeDestroy() {
    client.$off("orgChange", this.orgChange);
  },
  methods: {
    async orgChange() {
      await this.getAgents();
      this.records = [];
      this.pageIndex = 0;
      this.pageCount = 0;
      this.recordCount = 0;
      await this.query(1);
    },
    async getAgents() {
      let result = [];
      client.$emit(
        "startBusy",
        "HistoryData.getAgents",
        "正在获取监控单元,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.queryMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
          },
          rpp: 500,
          pageindex: 0,
          queryCondition: {
            queryObjType: 1, //Agent
            act: 2, //ORG
            scope: 1, //ALL
            project: client.project.id,
          },
        });
        if (res.results) {
          res.results.sort((a, b) => {
            return a.agentname.localeCompare(b.agentname);
          });
          for (let i in res.results) {
            let record = res.results[i];
            result.push({
              value: record.uuid,
              text: record.agentname,
            });
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "HistoryData.getAgents");
      this.agents = result;
    },
    async getDevices() {
      let result = [];
      if (this.agent != this.agentName) {
        client.$emit(
          "startBusy",
          "HistoryData.getDevices",
          "正在获取设备,请稍候..."
        );
        await proto.sleep(100);
        try {
          let res = await client.send(proto.MESSAGE_TYPE.queryMessage, {
            mcd: {
              operate: proto.OperateMode.queryOpt,
              range: "0",
            },
            rpp: 500,
            pageindex: 0,
            queryCondition: {
              queryObjType: 2, //Device
              act: 2, //ORG
              scope: 4, //Type
              queryConditionType: 2, //Agent
              queryId: this.agent,
              project: client.project.id,
            },
          });
          if (res.results) {
            res.results.sort((a, b) => {
              return a.devicename.localeCompare(b.devicename);
            });
            for (let i in res.results) {
              let record = res.results[i];
              result.push({
                value: record.uuid,
                text: record.devicename,
              });
            }
          }
        } catch (error) {
          client.$emit("toast", error);
        }
        client.$emit("endBusy", "HistoryData.getDevices");
      }
      this.device = undefined;
      this.devices = result;
    },
    async getSpots() {
      let result = [];
      if (this.device != this.deviceName) {
        client.$emit(
          "startBusy",
          "HistoryData.getSpots",
          "正在获取测点,请稍候..."
        );
        await proto.sleep(100);
        try {
          let res = await client.send(proto.MESSAGE_TYPE.queryMessage, {
            mcd: {
              operate: proto.OperateMode.queryOpt,
              range: "0",
            },
            rpp: 500,
            pageindex: 0,
            queryCondition: {
              queryObjType: 3, //Spot
              act: 2, //ORG
              scope: 4, //Type
              queryConditionType: 1, //Device
              queryId: this.device,
              project: client.project.id,
            },
          });
          if (res.results) {
            res.results.sort((a, b) => {
              return a.spotname.localeCompare(b.spotname);
            });
            for (let i in res.results) {
              let record = res.results[i];
              result.push({
                value: record.uuid,
                text: record.spotname,
              });
            }
          }
        } catch (error) {
          client.$emit("toast", error);
        }
        client.$emit("endBusy", "HistoryData.getSpots");
      }
      this.spot = undefined;
      this.spots = result;
    },
    async query(page) {
      let result = [];
      client.$emit("startBusy", "HistoryData.query", "正在获取记录,请稍候...");
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.histDataMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
          histdataClause: {
            timeContion: this.condition,
            begintime: this.dateStart,
            endtime: this.dateEnd,
            agentid: this.agentName == this.agent ? undefined : this.agent,
            deviceid: this.deviceName == this.device ? undefined : this.device,
            spotid: this.spotName == this.spot ? undefined : this.spot,
            agentname: this.agentName != this.agent ? undefined : this.agent,
            devicename:
              this.deviceName != this.device ? undefined : this.device,
            spotname: this.spotName != this.spot ? undefined : this.spot,
          },
          rpp: 20,
          pageindex: page - 1,
        });
        this.pageIndex = res.pageindex + 1;
        this.pageCount = res.pagecount || 0;
        this.recordCount = res.recordcount || 0;
        if (res.hds && res.hds.length) {
          res.hds.sort((a, b) => {
            return b.datetime.localeCompare(a.datetime);
          });
          for (let i = 0; i < res.hds.length; i++) {
            let record = res.hds[i];
            delete record.id;
            record.sv = base64.decode(record.sv);
            result.push(record);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "HistoryData.query");
      this.records = result;
    },
  },
};
</script>
