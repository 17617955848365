<template>
  <v-text-field
    :label="label"
    :value="valueDisplay"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>
            <div>{{ label }}编辑</div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-toolbar flat>
            <v-select
              class="mr-3"
              label="源类型"
              v-model="srcType"
              :items="srcTypes"
              hide-details
              outlined
              dense
              @change="
                actType = 1;
                items = [];
              "
            ></v-select>
            <v-select
              class="mr-3"
              label="值类型"
              v-model="actType"
              :items="actTypes"
              hide-details
              outlined
              dense
              @change="items = []"
            ></v-select>
            <v-btn icon @click="selectorShow = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-data-table :headers="headers" :items="items" hide-default-header>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon @click="remove(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
          <ObjectSelector
            v-model="selectorShow"
            :type="objectType"
            :single="false"
            @add="add"
          ></ObjectSelector>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import ObjectSelector from "./ObjectSelector.vue";
export default {
  components: {
    ObjectSelector,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      show: false,

      headers: [
        { text: "名称", value: "name" },
        { text: "操作", value: "actions", align: "end" },
      ],
      items: [],

      srcType: 1,
      srcTypes: [
        { text: "设备", value: 2 },
        { text: "测点", value: 1 },
      ],

      actType: 1,

      selectorShow: false,
    };
  },
  computed: {
    actTypes() {
      let result = [{ text: "告警状态", value: 1 }];
      if (this.srcType == 1) result.push({ text: "实时数据", value: 2 });
      return result;
    },
    objectType() {
      if (this.srcType == 2) return "device";
      return "spot";
    },
    valueDisplay() {
      let list = this.value;
      let names = [];
      for (let i in list) {
        names.push(list[i].name);
      }
      return names.join(",");
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          let list = this.value;
          if (list.length) {
            this.srcType = list[0].srctype;
            this.actType = list[0].acttype;
          }
          this.items = JSON.parse(JSON.stringify(list));
        }
      },
    },
  },
  methods: {
    add(item) {
      for (let i in this.items) {
        if (this.items[i].uuid == item.id) {
          return;
        }
      }
      this.items.push({
        uuid: item.id,
        name: item.name,
        srctype: this.srcType,
        acttype: this.actType,
      });
    },
    remove(item) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].uuid == item.uuid) {
          this.items.splice(i, 1);
        }
      }
    },
    submit() {
      this.$emit("change", this.items);
      this.show = false;
    },
  },
};
</script>
