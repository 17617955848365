<style>
.title-inset {
  position: absolute;
  bottom: 0px;
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}
.img-last {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.upload {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
</style>
<template>
  <div class="main">
    <v-navbar :hideOrg="true">
      <v-tooltip top activator=".upload">
        <span>导入资源</span>
      </v-tooltip>
      <v-file-input
        hide-input
        v-model="file"
        prepend-icon="mdi-file-import-outline"
        class="upload"
      ></v-file-input>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="exportResources">
            <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </template>
        <span>导出资源</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="
              model = {};
              editShow = true;
            "
          >
            <v-icon>mdi-file-upload</v-icon>
          </v-btn>
        </template>
        <span>上传新资源</span>
      </v-tooltip>
      <v-text-field
        v-model="filter"
        label="搜索"
        hide-details
        dense
        outlined
        prepend-inner-icon="mdi-magnify"
        style="max-width: 250px"
      ></v-text-field>
    </v-navbar>
    <v-container fluid class="flex-grow-1 overflow-y-auto">
      <v-row v-if="matchItems.length">
        <v-col
          v-for="item in matchItems"
          :key="item.uuid"
          cols="4"
          sm="3"
          md="2"
          lg="1"
        >
          <v-card>
            <v-img
              class="img-last"
              :aspect-ratio="1"
              :src="`data:image/png;base64,${item.thumbnail}`"
            ></v-img>
            <v-card-actions class="title-inset">
              <span v-text="item.name"></span>
              <v-spacer></v-spacer>
              <v-btn icon dark x-small @click="showMenu(item, $event)">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <div
        v-else-if="!loading"
        class="fill-height d-flex align-center justify-center"
      >
        <h1 class="white--text">没有资源</h1>
      </div>
    </v-container>
    <v-menu
      v-model="menuShow"
      :position-x="x"
      :position-y="y"
      :transition="false"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item @click="editShow = true">
          <v-list-item-title>编辑</v-list-item-title>
        </v-list-item>
        <v-list-item @click="download">
          <v-list-item-title>下载</v-list-item-title>
        </v-list-item>
        <v-list-item @click="shortcut">
          <v-list-item-title>生成缩略图</v-list-item-title>
        </v-list-item>
        <v-list-item @click="confirmShow = true">
          <v-list-item-title>删除</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <ResourceDialog
      v-model="editShow"
      :model="model"
      @update="getResources"
    ></ResourceDialog>
    <v-confirm
      v-model="confirmShow"
      :label="`是否删除 ${model.name} ?`"
      @ok="deleteResources"
    ></v-confirm>
  </div>
</template>

<script>
import base64 from "../utils/base64";
import proto from "../utils/proto";
import client from "../utils/client";
import ResourceDialog from "../components/ResourceDialog.vue";

export default {
  components: { ResourceDialog },
  data() {
    return {
      loading: true,

      filter: "",
      items: [],

      model: {},

      confirmShow: false,
      editShow: false,

      menuShow: false,
      x: 0,
      y: 0,

      file: undefined,
    };
  },
  computed: {
    matchItems() {
      if (this.filter) {
        return this.items.filter(this.matches);
      } else {
        return this.items;
      }
    },
  },
  watch: {
    file: {
      handler(val) {
        if (val) {
          this.importResources(val);
        }
      },
    },
  },
  mounted() {
    this.getResources();
  },
  methods: {
    matches(item) {
      return item.name.indexOf(this.filter) != -1;
    },
    async getResources() {
      let result = [];
      client.$emit(
        "startBusy",
        "ConfigResource.getResources",
        "正在获取资源,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.resourceMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
        });
        if (res.resources && res.resources.length) {
          res.resources.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.resources.length; i++) {
            let img = res.resources[i];
            result.push(img);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigResource.getResources");
      this.items = result;
      this.loading = false;
    },
    showMenu(item, e) {
      this.menuShow = false;
      this.model = item;
      this.x = e.clientX + 12;
      this.y = e.clientY + 12;
      setTimeout(
        function () {
          this.menuShow = true;
        }.bind(this),
        200
      );
    },
    async exportResources() {
      client.$emit(
        "startBusy",
        "ConfigResource.exportResources",
        "正在导出资源,请稍候..."
      );
      try {
        let array = [];
        for (let i in this.items) {
          let model = this.items[i];
          client.$emit(
            "startBusy",
            "ConfigResource.exportResources",
            `正在导出资源[${model.name}],请稍候...`
          );
          let res = await client.send(proto.MESSAGE_TYPE.resourceMessage, {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
              range: model.uuid,
            },
          });
          if (res.resources && res.resources.length) {
            let resource = res.resources[0];
            let uuid = resource.uuid,
              name = base64.encode(resource.name),
              mine,
              data,
              thumbnail;
            if (resource.type == 3) {
              mine = "image/gif";
              data = res.resources[0].content;
              thumbnail = res.resources[0].thumbnail;
            } else {
              mine = "image/png";
              data = res.resources[0].content;
              thumbnail = res.resources[0].thumbnail;
            }
            array.push({ uuid, name, type: "resource", mine, data, thumbnail });
          }
        }
        let file = JSON.stringify(array);
        file = window.btoa(file);
        let filename = `${client.project.name}-资源.json`;
        let url = `data:application/json;base64,${file}`;
        let dlLink = document.createElement("a");
        dlLink.download = filename;
        dlLink.href = url;
        dlLink.dataset.downloadurl = [
          "application/json",
          dlLink.download,
          dlLink.href,
        ].join(":");
        document.body.appendChild(dlLink);
        dlLink.click();
        document.body.removeChild(dlLink);
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigResource.exportResources");
    },
    async importResources(file) {
      client.$emit(
        "startBusy",
        "ConfigResource.importResources",
        "正在导入资源,请稍候..."
      );
      await proto.sleep(100);
      try {
        let url = this.getFileURL(file);
        let blob = await this.getFileBlob(url);
        let token = ";base64,";
        let index = blob.indexOf(token);
        if (index <= 0) throw "文件内容错误";
        blob = blob.slice(index + token.length);
        blob = window.atob(blob);
        if (!blob || blob[0] != "[") throw "文件内容错误";
        blob = JSON.parse(blob);
        let isArray = Object.prototype.toString.call(blob) == "[object Array]";
        let array = isArray ? blob : [blob];
        let createResources = [];
        let updateResources = [];
        for (let i in array) {
          let model = array[i];
          if (!model || model.type != "resource") throw "文件内容错误";
          let resource = {
            uuid: model.uuid,
            name: base64.decode(model.name),
            type: model.mine == "image/gif" ? 3 : 1,
            libtype: 1,
            content: model.data,
            thumbnail: model.thumbnail,
          };
          let exist = false;
          for (let i in this.items) {
            if (this.items[i].uuid == model.uuid) {
              exist = true;
              break;
            }
          }
          if (exist) {
            updateResources.push(resource);
          } else {
            createResources.push(resource);
          }
        }
        if (createResources.length) {
          for (let i in createResources) {
            client.$emit(
              "startBusy",
              "ConfigResource.importResources",
              `正在添加资源[${createResources[i].name}],请稍候...`
            );
            await client.send(proto.MESSAGE_TYPE.resourceMessage, {
              resType: 2,
              mcd: {
                operate: proto.OperateMode.createOpt,
                range: "-1",
              },
              resources: [createResources[i]],
            });
          }
        }
        if (updateResources.length) {
          for (let i in updateResources) {
            client.$emit(
              "startBusy",
              "ConfigResource.importResources",
              `正在更新资源[${updateResources[i].name}],请稍候...`
            );
            await client.send(proto.MESSAGE_TYPE.resourceMessage, {
              resType: 2,
              mcd: {
                operate: proto.OperateMode.updateOpt,
                range: "-1",
              },
              resources: [updateResources[i]],
            });
          }
        }
        this.getResources();
      } catch (error) {
        client.$emit("toast", error);
      }
      this.file = null;
      client.$emit("endBusy", "ConfigResource.importResources");
    },
    async download() {
      client.$emit(
        "startBusy",
        "ConfigResource.download",
        "正在下载资源,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.resourceMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: this.model.uuid,
          },
        });
        if (res.resources && res.resources.length) {
          let resource = res.resources[0];
          let url, mine, ext;
          if (resource.type == 3) {
            url = `data:image/gif;base64,${res.resources[0].content}`;
            mine = "image/gif";
            ext = "gif";
          } else {
            url = `data:image/png;base64,${res.resources[0].content}`;
            mine = "image/png";
            ext = "png";
          }
          let dlLink = document.createElement("a");
          dlLink.download = `${resource.name}.${ext}`;
          dlLink.href = url;
          dlLink.dataset.downloadurl = [
            mine,
            dlLink.download,
            dlLink.href,
          ].join(":");
          document.body.appendChild(dlLink);
          dlLink.click();
          document.body.removeChild(dlLink);
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigResource.download");
    },
    async shortcut() {
      client.$emit(
        "startBusy",
        "ConfigResource.shortcut",
        "正在生成缩略图,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.resourceMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: this.model.uuid,
          },
        });
        if (res.resources && res.resources.length) {
          let resource = res.resources[0];
          let url;
          if (resource.type == 3) {
            url = `data:image/gif;base64,${res.resources[0].content}`;
          } else {
            url = `data:image/png;base64,${res.resources[0].content}`;
          }
          resource.thumbnail = this.getBase64Data(await this.getThumbnail(url));
          res.resType = 2;
          res.mcd.operate = proto.OperateMode.updateOpt;
          res.mcd.range = "-1";
          await client.send(proto.MESSAGE_TYPE.resourceMessage, res);
          this.getResources();
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigResource.shortcut");
    },
    async deleteResources() {
      client.$emit(
        "startBusy",
        "ConfigResource.deleteResources",
        "正在删除资源,请稍候..."
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.resourceMessage, {
          mcd: {
            operate: proto.OperateMode.deleteOpt,
            range: "-1",
          },
          resources: [{ uuid: this.model.uuid }],
        });
        this.getResources();
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigResource.deleteResources");
    },
    getThumbnail(url) {
      return new Promise((resolve, reject) => {
        try {
          let canvas = document.createElement("canvas");
          canvas.width = 100;
          canvas.height = 100;
          let ctx = canvas.getContext("2d");
          let img = document.createElement("img");
          img.onload = function () {
            try {
              ctx.drawImage(img, 0, 0, 100, 100);
              let base64 = canvas.toDataURL();
              img = null;
              canvas = null;
              resolve(base64);
            } catch (e) {
              reject(e);
            }
          };
          img.onerror = function () {
            reject("图片内容已损坏");
          };
          img.src = url;
        } catch (e) {
          reject(e);
        }
      });
    },
    getBase64Data(base64) {
      let index = base64.indexOf("base64,");
      if (index != -1) {
        return base64.slice(index + 7);
      } else {
        return base64;
      }
    },
    getFileURL(file) {
      let url = null;
      if (window.createObjectURL !== undefined) {
        url = window.createObjectURL(file);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file);
      }
      return url;
    },
    getFileBlob(url) {
      return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
          if (xhr.status === 200) {
            let blob = xhr.response;
            let fileReader = new FileReader();
            fileReader.onloadend = function (e) {
              fileReader = null;
              xhr = null;
              resolve(e.target.result);
            };
            fileReader.readAsDataURL(blob);
          }
        };
        xhr.onerror = function () {
          xhr = null;
          reject();
        };
        xhr.send();
      });
    },
  },
};
</script>
