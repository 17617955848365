<style scoped>
.zht-ui {
  position: absolute;
  box-sizing: border-box;
}
.zht-ui.selected {
  outline: 3px dashed #2196f3;
  cursor: move;
}
.zht-ui > *:nth-child(1) {
  width: 100%;
  height: 100%;
}
.section-center {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
}
.section-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.center-bg {
  background-image: url("../assets/circle-small.png");
  background-repeat: repeat;
}
#editPage {
  overflow: hidden;
}
.toolbar-btn {
  min-width: 36px !important;
  padding: 0 !important;
}
.move {
  width: 8px;
  height: 8px;
  background-color: #2196f3;
  position: absolute;
  cursor: move;
  display: none;
}
.zht-ui.selected .move {
  display: block;
}
.move.top {
  top: -8px;
}
.move.right {
  right: -8px;
}
.move.bottom {
  bottom: -8px;
}
.move.left {
  left: -8px;
}
.overflow-scroll {
  overflow: scroll;
}
.section-header {
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.5)
    ),
    url("../assets/section.jpg");
  padding: 8px 12px;
}
.upload {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.no-select {
  user-select: none;
}
</style>

<template>
  <div class="main">
    <v-navbar>
      <v-tooltip top activator=".upload">
        <span>导入页面</span>
      </v-tooltip>
      <v-file-input
        v-show="!editPage"
        hide-input
        v-model="file"
        prepend-icon="mdi-file-import-outline"
        class="upload"
      ></v-file-input>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!editPage"
            icon
            v-bind="attrs"
            v-on="on"
            @click="exportFile()"
          >
            <v-icon>mdi-home-export-outline</v-icon>
          </v-btn>
        </template>
        <span>导出机构下所有页面</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!editPage"
            icon
            v-bind="attrs"
            v-on="on"
            @click="showAdd = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>新建页面</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-show="editPage" icon v-bind="attrs" v-on="on" @click="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>保存</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="editPage"
            icon
            v-bind="attrs"
            v-on="on"
            @click="goBack"
          >
            <v-icon>mdi-arrow-left-bold-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>返回</span>
      </v-tooltip>
    </v-navbar>
    <!-- List -->
    <v-container v-if="!editPage" fluid class="flex-grow-1 overflow-y-auto bg">
      <v-row v-if="pages.length">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
          v-for="page in pages"
          :key="page.uuid"
        >
          <v-card dark color="rgba(255,255,255,0.2)">
            <v-card-title>
              <div>{{ page.name }}</div>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :color="
                      page.uuid == homeId
                        ? 'rgba(180,255,0,1)'
                        : 'rgba(255,255,255,0.2)'
                    "
                    @click="setHomePage(page.uuid)"
                    icon
                  >
                    <v-icon>mdi-home</v-icon>
                  </v-btn>
                </template>
                <span v-if="page.uuid == homeId">当前主页</span>
                <span v-else>设为主页</span>
              </v-tooltip>
            </v-card-title>
            <v-card-subtitle>
              {{ page.size.width }} X {{ page.size.height }}
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-actions>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    tile
                    small
                    @click="edit(page)"
                    class="mr-1"
                  >
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>编辑页面</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    tile
                    small
                    @click="exportFile(page)"
                    class="mr-1"
                  >
                    <v-icon>mdi-file-export-outline</v-icon>
                  </v-btn>
                </template>
                <span>导出页面</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    tile
                    small
                    @click="
                      removeModel = page;
                      showRemove = true;
                    "
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>删除页面</span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <div
        v-else-if="!loading"
        class="fill-height d-flex align-center justify-center"
      >
        <h1 class="white--text">没有页面</h1>
      </div>
    </v-container>
    <!-- Editor -->
    <div
      v-else
      class="flex-grow-1 d-flex flex-nowrap overflow-hidden"
      style="background-color: white"
    >
      <!-- Left -->
      <div
        class="flex-grow-1 elevation-4"
        style="min-width: 150px; max-width: 200px; z-index: 2"
      >
        <div class="fill-height d-flex flex-column">
          <h5 class="section-header">控件箱</h5>
          <v-divider></v-divider>
          <div style="flex-grow: 1; position: relative">
            <div class="overflow-x-hidden overflow-y-auto section-absolute">
              <v-list dense class="py-0">
                <template v-for="(control, i) in toolbox">
                  <v-list-item
                    style="cursor: move"
                    draggable="true"
                    :key="control.key"
                    @dragstart="dragstart($event, 'create', control.key)"
                  >
                    <v-list-item-icon>
                      <v-icon
                        v-text="control.icon"
                        @click="createUI(control.key)"
                      ></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="control.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="i"></v-divider>
                </template>
              </v-list>
            </div>
          </div>
          <v-divider></v-divider>
          <h5 class="section-header">页面控件</h5>
          <v-divider></v-divider>
          <div style="flex-grow: 1; position: relative">
            <div class="overflow-x-hidden overflow-y-auto section-absolute">
              <v-list color="transparent" dense class="py-0">
                <template v-for="(control, i) in reverseControls">
                  <v-list-item
                    :key="i"
                    :style="{
                      background:
                        reverseSelected.indexOf(i) != -1 ? '#e1efff' : '',
                    }"
                    @click="
                      selectElement(
                        $event,
                        reverseControls.length - i - 1,
                        true
                      )
                    "
                  >
                    <v-list-item-icon>
                      <v-icon>{{ icon(control.type) }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="control.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="`divider${i}`"></v-divider>
                </template>
              </v-list>
            </div>
          </div>
        </div>
      </div>
      <!-- Center -->
      <div class="flex-grow-1 overflow-hidden d-flex flex-column">
        <!-- Top -->
        <v-tabs
          show-arrows
          class="flex-grow-0 elevation-4"
          style="z-index: 1"
          height="36"
        >
          <div
            class="fill-height d-flex flex-nowrap align-center flex-grow-1 px-3"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  plain
                  class="toolbar-btn"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="selected.length == 0"
                  @click="deleteElement"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <span>删除控件</span>
            </v-tooltip>
            <v-divider vertical class="mx-1"></v-divider>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  plain
                  class="toolbar-btn"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="selected.length == 0"
                  @click="copy"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>复制</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  plain
                  class="toolbar-btn"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="clipboard.length == 0"
                  @click="paste"
                >
                  <v-icon>mdi-content-paste</v-icon>
                </v-btn>
              </template>
              <span>粘贴</span>
            </v-tooltip>
            <v-divider vertical class="mx-1"></v-divider>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  plain
                  class="toolbar-btn"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="selected.length != 1"
                  @click="toBottom"
                >
                  <v-icon>mdi-arrow-collapse-down</v-icon>
                </v-btn>
              </template>
              <span>置于底层</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  plain
                  class="toolbar-btn"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="selected.length != 1"
                  @click="toLower"
                >
                  <v-icon>mdi-arrow-expand-down</v-icon>
                </v-btn>
              </template>
              <span>下一层</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  plain
                  class="toolbar-btn"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="selected.length != 1"
                  @click="toUpper"
                >
                  <v-icon>mdi-arrow-expand-up</v-icon>
                </v-btn>
              </template>
              <span>上一层</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  plain
                  class="toolbar-btn"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="selected.length != 1"
                  @click="toTop"
                >
                  <v-icon>mdi-arrow-collapse-up</v-icon>
                </v-btn>
              </template>
              <span>置于顶层</span>
            </v-tooltip>
            <v-divider vertical class="mx-1"></v-divider>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  plain
                  class="toolbar-btn"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="selected.length < 2"
                  @click="align('HorizontalLeft')"
                >
                  <v-icon>mdi-align-horizontal-left</v-icon>
                </v-btn>
              </template>
              <span>左端对齐</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  plain
                  class="toolbar-btn"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="selected.length < 2"
                  @click="align('HorizontalCenter')"
                >
                  <v-icon>mdi-align-horizontal-center</v-icon>
                </v-btn>
              </template>
              <span>水平居中</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  plain
                  class="toolbar-btn"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="selected.length < 2"
                  @click="align('HorizontalRight')"
                >
                  <v-icon>mdi-align-horizontal-right</v-icon>
                </v-btn>
              </template>
              <span>右端对齐</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  plain
                  class="toolbar-btn"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="selected.length < 2"
                  @click="align('VerticalTop')"
                >
                  <v-icon>mdi-align-vertical-top</v-icon>
                </v-btn>
              </template>
              <span>顶端对齐</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  plain
                  class="toolbar-btn"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="selected.length < 2"
                  @click="align('VerticalCenter')"
                >
                  <v-icon>mdi-align-vertical-center</v-icon>
                </v-btn>
              </template>
              <span>垂直居中</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  plain
                  class="toolbar-btn"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="selected.length < 2"
                  @click="align('VerticalBottom')"
                >
                  <v-icon>mdi-align-vertical-bottom</v-icon>
                </v-btn>
              </template>
              <span>底端对齐</span>
            </v-tooltip>
            <v-divider vertical class="mx-1"></v-divider>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  plain
                  class="toolbar-btn"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="selected.length != 1"
                  @click="copyStyle"
                >
                  <v-icon>mdi-arrange-send-backward</v-icon>
                </v-btn>
              </template>
              <span>复制样式</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  plain
                  class="toolbar-btn"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!clipboardStyle || selected.length == 0"
                  @click="pasteStyle"
                >
                  <v-icon>mdi-arrange-bring-forward</v-icon>
                </v-btn>
              </template>
              <span>粘贴样式</span>
            </v-tooltip>
            <v-divider vertical class="mx-1"></v-divider>
            <v-spacer></v-spacer>
            <div class="no-select black--text">
              缩放：{{ (scale * 100).toFixed(0) }}%
            </div>
            <v-btn icon class="scaleMode">
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
            <v-menu
              activator=".scaleMode"
              offset-x
              offset-y
              left
              bottom
              rounded
              min-width="100"
            >
              <v-list>
                <v-list-item
                  v-for="item in scaleModes"
                  :key="item.value"
                  @click="scale = item.value"
                >
                  <v-list-item-title
                    v-text="item.text"
                    :style="{
                      color: scale == item.value ? '#007BFF' : 'black',
                    }"
                  ></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-tabs>
        <!-- Page -->
        <div class="flex-grow-1 section-center center-bg">
          <div class="section-absolute overflow-scroll">
            <div
              :style="{
                width: (editPage.size.width || 0) + 'px',
                height: (editPage.size.height || 0) + 'px',
                transform: `scale(${this.scale})`,
              }"
              style="
                box-sizing: content-box;
                margin: 0px auto;
                transform-origin: top left;
              "
              class="pa-4"
            >
              <div
                id="editPage"
                class="elevation-3"
                :style="pageStyle"
                @mousedown="mousedown($event)"
                @mousemove="mousemove"
                @mouseup="mouseup"
                @dragover="dragover"
                @drop="drop"
                @click="selectElement($event, undefined)"
              >
                <template v-for="(control, i) in editPage.controls">
                  <div
                    :key="control.uuid"
                    class="zht-ui"
                    :class="{ selected: selected.indexOf(i) != -1 }"
                    :style="{
                      width: (control.size.width || 1) + 'px',
                      height: (control.size.height || 1) + 'px',
                      left: (control.location.x || 0) + 'px',
                      top: (control.location.y || 0) + 'px',
                    }"
                    @mousedown="mousedown($event, i)"
                    @click="selectElement($event, i)"
                  >
                    <component
                      :is="control.type"
                      :control="control"
                      :pageId="editPage.uuid"
                    ></component>
                    <div
                      v-show="selected.length == 1"
                      :draggable="selected.indexOf(i) != -1"
                      @mousedown="$event.stopPropagation()"
                      @dragstart="dragstart($event, 'resize1', i)"
                      class="move left top"
                    ></div>
                    <div
                      v-show="selected.length == 1"
                      :draggable="selected.indexOf(i) != -1"
                      @mousedown="$event.stopPropagation()"
                      @dragstart="dragstart($event, 'resize2', i)"
                      class="move right top"
                    ></div>
                    <div
                      v-show="selected.length == 1"
                      :draggable="selected.indexOf(i) != -1"
                      @mousedown="$event.stopPropagation()"
                      @dragstart="dragstart($event, 'resize3', i)"
                      class="move right bottom"
                    ></div>
                    <div
                      v-show="selected.length == 1"
                      :draggable="selected.indexOf(i) != -1"
                      @mousedown="$event.stopPropagation()"
                      @dragstart="dragstart($event, 'resize4', i)"
                      class="move left bottom"
                    ></div>
                  </div>
                </template>
                <div :style="indicatorStyle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Right -->
      <div
        class="flex-grow-1 elevation-4"
        style="min-width: 150px; max-width: 200px; z-index: 2"
      >
        <div v-if="editPage" class="fill-height d-flex flex-column">
          <div class="section-header d-flex">
            <h5>属性编辑器</h5>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="selectElement($event, undefined)"
                >
                  mdi-file-upload-outline
                </v-icon>
              </template>
              <span>页面属性</span>
            </v-tooltip>
          </div>
          <v-divider></v-divider>
          <div style="flex-grow: 1; position: relative">
            <div
              class="
                overflow-x-hidden overflow-y-auto
                section-absolute
                bg-editor
              "
            >
              <v-container class="pa-0">
                <template v-if="selected.length == 1">
                  <h5 class="px-3 pt-2">基本</h5>
                  <template
                    v-for="attr in ['uuid', 'type', 'name', 'spotName']"
                  >
                    <component
                      v-if="editors[attr]"
                      :is="editors[attr].editor"
                      :key="attr"
                      :label="editors[attr].name"
                      :options="editors[attr].options"
                      v-model="editPage.controls[[selected[0]]][attr]"
                    ></component>
                  </template>
                  <h5 class="px-3 pt-2">位置</h5>
                  <template v-for="attr in ['x', 'y']">
                    <component
                      v-if="editors[`location.${attr}`]"
                      :is="editors[`location.${attr}`].editor"
                      :key="`location.${attr}`"
                      :label="editors[`location.${attr}`].name"
                      :options="editors[`location.${attr}`].options"
                      v-model="editPage.controls[selected[0]].location[attr]"
                    ></component>
                  </template>
                  <h5 class="px-3 pt-2">大小</h5>
                  <template v-for="attr in ['width', 'height']">
                    <component
                      v-if="editors[`size.${attr}`]"
                      :is="editors[`size.${attr}`].editor"
                      :key="`size.${attr}`"
                      :label="editors[`size.${attr}`].name"
                      :options="editors[`size.${attr}`].options"
                      v-model="editPage.controls[selected[0]].size[attr]"
                    ></component>
                  </template>
                  <h5 class="px-3 pt-2">样式</h5>
                  <template
                    v-for="attr in Object.keys(
                      editPage.controls[selected[0]].style
                    )"
                  >
                    <component
                      v-if="editors[`style.${attr}`]"
                      :is="editors[`style.${attr}`].editor"
                      :key="`style.${attr}`"
                      :label="editors[`style.${attr}`].name"
                      :options="editors[`style.${attr}`].options"
                      v-model="editPage.controls[selected[0]].style[attr]"
                    ></component>
                  </template>
                  <h5
                    class="px-3 pt-2"
                    v-if="editPage.controls[selected[0]].config !== undefined"
                  >
                    高级参数
                  </h5>
                  <template
                    v-if="editPage.controls[selected[0]].config !== undefined"
                  >
                    <template
                      v-for="attr in Object.keys(
                        editPage.controls[selected[0]].config
                      )"
                    >
                      <component
                        v-if="editors[`config.${attr}`]"
                        :is="editors[`config.${attr}`].editor"
                        :key="`config.${attr}`"
                        :label="editors[`config.${attr}`].name"
                        :options="editors[`config.${attr}`].options"
                        v-model="editPage.controls[selected[0]].config[attr]"
                      ></component>
                    </template>
                  </template>
                  <!-- 事件 -->
                  <ActionEditor
                    v-if="
                      editPage.controls[selected[0]].config &&
                        editPage.controls[selected[0]].config.actions
                    "
                    v-model="editPage.controls[selected[0]].config.actions"
                    :controls="editPage.controls"
                  ></ActionEditor>
                </template>
                <template v-else>
                  <h5 class="px-3 pt-2">基本</h5>
                  <template v-for="attr in ['uuid', 'type', 'name']">
                    <component
                      v-if="editors[attr]"
                      :is="editors[attr].editor"
                      :key="attr"
                      :label="editors[attr].name"
                      :options="editors[attr].options"
                      v-model="editPage[attr]"
                    ></component>
                  </template>
                  <h5 class="px-3 pt-2">大小</h5>
                  <template v-for="attr in ['width', 'height']">
                    <component
                      v-if="editors[`size.${attr}`]"
                      :is="editors[`size.${attr}`].editor"
                      :key="`size.${attr}`"
                      :label="editors[`size.${attr}`].name"
                      :options="editors[`size.${attr}`].options"
                      v-model="editPage.size[attr]"
                    ></component>
                  </template>
                  <h5 class="px-3 pt-2">样式</h5>
                  <template v-for="attr in Object.keys(editPage.style)">
                    <component
                      v-if="editors[`style.${attr}`]"
                      :is="editors[`style.${attr}`].editor"
                      :key="`style.${attr}`"
                      :label="editors[`style.${attr}`].name"
                      :options="editors[`style.${attr}`].options"
                      v-model="editPage.style[attr]"
                    ></component>
                  </template>
                  <h5 class="px-3 pt-2">高级参数</h5>
                  <!-- 页面绑定 -->
                  <BindingEditor
                    v-model="editPage.bindings"
                    :controls="editPage.controls"
                  ></BindingEditor>
                </template>
                <div style="height: 32px"></div>
              </v-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- other-->
    <v-confirm
      v-model="showRemove"
      :label="`确定删除页面 ${removeModel.name} 吗?`"
      @ok="remove"
    ></v-confirm>
    <v-confirm
      v-model="showImport"
      :label="`请选择导入页面的方式：`"
      :okText="`覆盖现有页面`"
      :cancelText="`作为模板新建页面`"
      @ok="importUpdate"
      @cancel="importCreate"
    ></v-confirm>
    <AddPage v-if="!editPage && showAdd" @close="add"></AddPage>
  </div>
</template>

<script>
import RES from "../utils/res";
import uuid from "../utils/uuid";
import base64 from "../utils/base64";
import proto from "../utils/proto";
import client from "../utils/client";
import ZHTUI from "../zht-ui";
import toolbox from "../zht-ui/toolbox";
import editors from "../zht-ui/editor";
import AddPage from "../components/AddPage.vue";
import BindingEditor from "../zht-ui/editors/BindingEditor.vue";
import ActionEditor from "../zht-ui/editors/ActionEditor.vue";

export default {
  components: { AddPage, BindingEditor, ActionEditor },
  data() {
    return {
      loading: true,
      toolbox: toolbox,
      editors: editors,
      pageBgKey: "",
      pageBgUrl: "",
      homeId: "",
      pages: [],
      clipboard: [],
      clipboardStyle: undefined,
      clipboardAnchor: undefined,
      editPage: null,
      editPageOrigin: null,
      reverseSelected: [],
      /** dragdrop */
      action: "",
      create: { type: null },
      move: {
        enable: false,
        originX: 0,
        originY: 0,
        origin: {},
        time: 0,
      },
      resize: {
        index: 0,
        size: { width: 0, height: 0 },
        location: { x: 0, y: 0 },
        clientX: 0,
        clientY: 0,
      },
      isCtrl: false,
      indicator: {
        enable: false,
        clientX: 0,
        clientY: 0,
        pageX: 0,
        pageY: 0,
        time: 0,
      },
      indicatorStyle: {
        display: "none",
        position: "absolute",
        width: "100px",
        height: "100px",
        left: "0px",
        top: "0px",
        outline: "1px solid #888",
      },
      /** dialog */
      showAdd: false,
      showRemove: false,
      removeModel: { name: "" },
      showImport: false,
      importModel: [],

      binding: {
        show: false,
        list: [],
      },

      file: undefined,
      scale: 1.0,
      scaleModes: [
        { text: "25%", value: 0.25 },
        { text: "50%", value: 0.5 },
        { text: "75%", value: 0.75 },
        { text: "100%", value: 1.0 },
        { text: "150%", value: 1.5 },
        { text: "200%", value: 2.0 },
      ],
    };
  },
  computed: {
    pageStyle() {
      let result = JSON.parse(JSON.stringify(this.editPage.style));
      result.width = parseInt(this.editPage.size.width) + "px";
      result.height = parseInt(this.editPage.size.height) + "px";
      result.position = "relative";
      result["background-position"] = "center";
      result["background-size"] = "100% 100%";
      if (result["background-image"]) {
        let imgValue = result["background-image"];
        if (imgValue.indexOf("url") != 0 && imgValue.length == 36) {
          if (this.pageBgKey != imgValue || this.pageBgUrl == "") {
            this.getResource(imgValue);
          }
          result["background-image"] = `url('${this.pageBgUrl}')`;
        }
      }
      return result;
    },
    reverseControls() {
      let result = [];
      for (let i in this.editPage.controls) {
        result.splice(0, 0, this.editPage.controls[i]);
      }
      return result;
    },
    selected() {
      let result = [];
      for (let i in this.reverseSelected) {
        result.push(
          this.editPage.controls.length - this.reverseSelected[i] - 1
        );
      }
      return result;
    },
  },
  created() {
    client.$on("showOrganization", this.exitTips);
    client.$on("showMenu", this.exitTips);
    client.$on("orgChange", this.getHomePage);
    client.$on("orgChange", this.getPages);
    document.body.addEventListener("keydown", this.keydown);
    document.body.addEventListener("keyup", this.keyup);
    this.getHomePage();
    this.getPages();
  },
  beforeDestroy() {
    client.$off("showOrganization", this.exitTips);
    client.$off("showMenu", this.exitTips);
    client.$off("orgChange", this.getHomePage);
    client.$off("orgChange", this.getPages);
    RES.clear();
    document.body.removeEventListener("keydown", this.keydown);
    document.body.removeEventListener("keyup", this.keyup);
  },
  watch: {
    file: {
      handler(val) {
        if (val) {
          this.importFile(val);
        }
      },
    },
  },
  methods: {
    exitTips() {
      if (this.editPage) {
        let edit = JSON.stringify(this.editPage);
        if (this.editPageOrigin != edit) {
          client.$emit(
            "toast",
            "当前页面未保存，切换界面将导致当前编辑内容丢失！"
          );
        }
      }
    },
    edit(model) {
      this.reverseSelected = [];
      this.editPageOrigin = JSON.stringify(model);
      let page = JSON.parse(this.editPageOrigin);
      for (let i = 0; i < page.controls.length; i++) {
        let control = page.controls[i];
        ZHTUI.fix(control.type, control);
      }
      this.editPage = page;
    },
    /** binding methods */
    showBinding() {
      this.binding.list = JSON.parse(JSON.stringify(this.editPage.bindings));
      this.binding.show = true;
    },
    submitBinding() {
      this.editPage.bindings = JSON.parse(JSON.stringify(this.binding.list));
      this.binding.show = false;
    },
    removeBinding(item) {
      for (let i = 0; i < this.binding.list.length; i++) {
        if (this.binding.list[i] == item) {
          this.binding.list.splice(i, 1);
          break;
        }
      }
    },
    /** server methods */
    async getHomePage() {
      client.$emit(
        "startBusy",
        "ConfigPage.getHomePage",
        "正在获取主页,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.organizationMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "-1",
          },
          orgs: [{ id: client.org.id }],
        });
        if (res.orgs.length) {
          this.homeId = res.orgs[0].homepage;
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigPage.getHomePage");
    },
    async setHomePage(id) {
      client.$emit(
        "startBusy",
        "ConfigPage.setHomePage",
        "正在设置主页,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.organizationMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "-1",
          },
          orgs: [{ id: client.org.id }],
        });
        if (res.orgs.length) {
          res.orgs[0].homepage = id;
          res.mcd.operate = proto.OperateMode.updateOpt;
          res = await client.send(proto.MESSAGE_TYPE.organizationMessage, res);
          this.homeId = id;
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigPage.setHomePage");
    },
    async getPages() {
      this.loading = true;
      client.$emit(
        "startBusy",
        "ConfigPage.getPages",
        "正在加载页面,请稍候..."
      );
      await proto.sleep(100);
      let result = [];
      try {
        let res = await client.send(proto.MESSAGE_TYPE.pageMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
            orgid: client.org.id,
          },
        });
        if (res.pages) {
          res.pages.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i in res.pages) {
            let page = res.pages[i];
            let thumbnail = page.thumbnail;
            if (thumbnail && thumbnail.indexOf("aXZpc3Vhb") == 0) {
              thumbnail = base64.decode(thumbnail);
              let uuid = page.uuid;
              let name = page.name;
              let width = page.geometry.width;
              let height = page.geometry.height;
              let backgroundColor = "";
              let backgroundImage = "";
              if (page.bkGround.bkType == 1) {
                backgroundColor = page.bkGround.content;
              } else if (page.bkGround.bkType == 2) {
                backgroundImage = page.bkGround.content;
              }
              let model = JSON.parse(thumbnail.substr(7));
              result.push({
                uuid,
                type: "zht-page",
                name,
                size: { width, height },
                style: {
                  "background-color": backgroundColor,
                  "background-image": backgroundImage,
                },
                controls: model.controls || [],
                bindings: model.bindings || [],
              });
            }
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      this.pages = result;
      this.loading = false;
      client.$emit("endBusy", "ConfigPage.getPages");
    },
    goBack() {
      this.editPage = null;
      setTimeout(() => {
        this.getPages();
      }, 100);
    },
    async add(name) {
      this.showAdd = false;
      if (name) {
        client.$emit("startBusy", "ConfigPage.add", "正在创建页面,请稍候...");
        await proto.sleep(100);
        try {
          let page = {};
          page.uuid = uuid();
          page.name = name;
          page.geometry = { width: 1920, height: 1080, x: 0, y: 0 };
          page.bkGround = { bkType: 1, content: "#FFFFFF" };
          let thumbnail = base64.encode(
            "ivisual" + JSON.stringify({ controls: [], bindings: [] })
          );
          page.thumbnail = thumbnail;
          page.orgid = client.org.id;
          await client.send(proto.MESSAGE_TYPE.pageMessage, {
            mcd: {
              operate: proto.OperateMode.createOpt,
              range: "-1",
              parentId: client.project.id,
            },
            pages: [page],
          });
          this.getPages();
        } catch (error) {
          client.$emit("toast", error);
        }
        client.$emit("endBusy", "ConfigPage.add");
      }
    },
    async remove() {
      client.$emit("startBusy", "ConfigPage.remove", "正在删除页面,请稍候...");
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.pageMessage, {
          mcd: {
            operate: proto.OperateMode.deleteOpt,
            range: "-1",
          },
          pages: [{ uuid: this.removeModel.uuid }],
        });
        this.getPages();
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigPage.remove");
    },
    async save() {
      client.$emit(
        "startBusy",
        "ConfigPage.save",
        "正在保存页面配置,请稍候..."
      );
      await proto.sleep(100);
      try {
        this.editPageOrigin = JSON.stringify(this.editPage);
        let page = {};
        page.uuid = this.editPage.uuid;
        page.name = this.editPage.name;
        page.geometry = {
          width: parseInt(this.editPage.size.width),
          height: parseInt(this.editPage.size.height),
          x: 0,
          y: 0,
        };
        if (this.editPage.style["background-image"]) {
          page.bkGround = {
            bkType: 2,
            content: this.editPage.style["background-image"],
          };
        } else {
          page.bkGround = {
            bkType: 1,
            content: this.editPage.style["background-color"],
          };
        }
        let thumbnail = base64.encode(
          "ivisual" +
            JSON.stringify({
              controls: this.editPage.controls,
              bindings: this.editPage.bindings,
            })
        );
        page.thumbnail = thumbnail;
        page.orgid = client.org.id;
        await client.send(proto.MESSAGE_TYPE.pageMessage, {
          mcd: {
            operate: proto.OperateMode.updateOpt,
            range: "-1",
          },
          pages: [page],
        });
        client.$emit("toast", "保存成功", "success");
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigPage.save");
    },
    async getResource(id) {
      this.pageBgKey = id;
      this.pageBgUrl = RES.loading;
      await proto.sleep(10);
      this.pageBgUrl = await RES.getResource(id);
    },
    /** toolbar methods */
    deleteElement() {
      for (let i in this.selected) {
        let j = this.selected[i];
        let model = this.editPage.controls[j];
        let k = 0;
        while (k < this.editPage.bindings.length) {
          let binding = this.editPage.bindings[k];
          if (model.uuid == binding.uuid) {
            this.editPage.bindings.splice(k, 1);
          } else {
            k++;
          }
        }
        this.editPage.controls.splice(j, 1);
      }
      if (this.editPage.controls.length) {
        this.reverseSelected = [0];
      } else {
        this.reverseSelected = [];
      }
    },
    toUpper() {
      if (this.selected.length == 0) return;
      let index = this.selected[0];
      let item = this.editPage.controls[index];
      if (index < this.editPage.controls.length - 1) {
        this.reverseSelected = [];
        this.editPage.controls.splice(index, 1);
        this.editPage.controls.splice(index + 1, 0, item);
        this.reverseSelected = [
          this.editPage.controls.length - (index + 1) - 1,
        ];
      }
    },
    toLower() {
      if (this.selected.length == 0) return;
      let index = this.selected[0];
      let item = this.editPage.controls[index];
      if (index > 0) {
        this.reverseSelected = [];
        this.editPage.controls.splice(index, 1);
        this.editPage.controls.splice(index - 1, 0, item);
        this.reverseSelected = [
          this.editPage.controls.length - (index - 1) - 1,
        ];
      }
    },
    toTop() {
      if (this.selected.length == 0) return;
      let index = this.selected[0];
      let item = this.editPage.controls[index];
      if (index < this.editPage.controls.length - 1) {
        this.reverseSelected = [];
        this.editPage.controls.splice(index, 1);
        this.editPage.controls.push(item);
        this.reverseSelected = [0];
      }
    },
    toBottom() {
      if (this.selected.length == 0) return;
      let index = this.selected[0];
      let item = this.editPage.controls[index];
      if (index > 0) {
        this.reverseSelected = [];
        this.editPage.controls.splice(index, 1);
        this.editPage.controls.splice(0, 0, item);
        this.reverseSelected = [this.editPage.controls.length - 1];
      }
    },
    copy() {
      let result = [];
      for (let i in this.selected) {
        let index = this.selected[i];
        let item = this.editPage.controls[index];
        result.push(item);
      }
      this.clipboard = JSON.parse(JSON.stringify(result));
    },
    paste() {
      if (this.clipboard.length) {
        let selectArray = [];
        for (let i in this.clipboard) {
          let newItem = JSON.parse(JSON.stringify(this.clipboard[i]));
          newItem.uuid = uuid();
          newItem.name = this.newName(`${newItem.name}-副本`);
          this.editPage.controls.push(newItem);
          selectArray.push(parseInt(i));
        }
        this.reverseSelected = selectArray;
      }
    },
    copyStyle() {
      let index = this.selected[0];
      let item = this.editPage.controls[index];
      this.clipboardStyle = JSON.parse(JSON.stringify(item));
    },
    pasteStyle() {
      for (let i in this.selected) {
        let index = this.selected[i];
        let item = this.editPage.controls[index];
        if (this.clipboardStyle && item.type == this.clipboardStyle.type) {
          item.size = JSON.parse(JSON.stringify(this.clipboardStyle.size));
          item.style = JSON.parse(JSON.stringify(this.clipboardStyle.style));
        } else {
          client.$emit("toast", "粘贴样式必须是相同类型的页面控件");
          break;
        }
      }
    },
    align(type) {
      let minX, maxX, minY, maxY;
      for (let i in this.selected) {
        let index = this.selected[i];
        let item = this.editPage.controls[index];
        let x = item.location.x;
        let y = item.location.y;
        let width = item.size.width;
        let height = item.size.height;
        if (minX == undefined || minX > x) minX = x;
        if (maxX == undefined || maxX < x + width) maxX = x + width;
        if (minY == undefined || minY > y) minY = y;
        if (maxY == undefined || maxY < y + height) maxY = y + height;
      }
      for (let i in this.selected) {
        let index = this.selected[i];
        let item = this.editPage.controls[index];
        let width = item.size.width;
        let height = item.size.height;
        switch (type) {
          case "HorizontalLeft":
            item.location.x = minX;
            break;
          case "HorizontalCenter":
            item.location.x = Math.round(minX + (maxX - minX) / 2 - width / 2);
            break;
          case "HorizontalRight":
            item.location.x = maxX - width;
            break;
          case "VerticalTop":
            item.location.y = minY;
            break;
          case "VerticalCenter":
            item.location.y = Math.round(minY + (maxY - minY) / 2 - height / 2);
            break;
          case "VerticalBottom":
            item.location.y = maxY - height;
            break;
        }
      }
    },
    icon(type) {
      for (let i in this.toolbox) {
        if (this.toolbox[i].key == type) {
          return this.toolbox[i].icon;
        }
      }
      return "mdi-file";
    },
    /** UI methods */
    keydown(event) {
      this.isCtrl = event.ctrlKey;
    },
    keyup(event) {
      this.isCtrl = event.ctrlKey;
    },
    mousedown(event, i) {
      if (i == undefined) {
        let offset = this.offsetPage(event.target);
        let pageX = offset.x + event.offsetX;
        let pageY = offset.y + event.offsetY;
        this.indicator.enable = true;
        this.indicator.clientX = event.clientX;
        this.indicator.clientY = event.clientY;
        this.indicator.pageX = pageX;
        this.indicator.pageY = pageY;
        this.indicatorStyle.left = `${pageX}px`;
        this.indicatorStyle.top = `${pageY}px`;
      } else if (this.selected.indexOf(i) != -1) {
        event.stopPropagation();
        this.move.originX = event.clientX;
        this.move.originY = event.clientY;
        this.move.origin = {};
        for (let i in this.selected) {
          let index = this.selected[i];
          let item = this.editPage.controls[index];
          this.move.origin[item.uuid] = {
            x: item.location.x,
            y: item.location.y,
          };
        }
        this.move.time = Date.now();
        this.move.enable = true;
      }
    },
    mousemove(event) {
      if (this.indicator.enable) {
        let time = Date.now();
        if (time - this.indicator.time > 50) {
          this.indicator.time = time;
          let dX = Math.round(
            (event.clientX - this.indicator.clientX) / this.scale
          );
          let dY = Math.round(
            (event.clientY - this.indicator.clientY) / this.scale
          );
          if (dX < 0) {
            this.indicatorStyle.left = `${this.indicator.pageX + dX}px`;
          }
          if (dY < 0) {
            this.indicatorStyle.top = `${this.indicator.pageY + dY}px`;
          }
          this.indicatorStyle.width = `${Math.abs(dX)}px`;
          this.indicatorStyle.height = `${Math.abs(dY)}px`;
          this.indicatorStyle.display = "block";
        }
      }
      if (this.move.enable) {
        let time = Date.now();
        if (time - this.move.time > 50) {
          this.move.time = time;
          let dX = Math.round((event.clientX - this.move.originX) / this.scale);
          let dY = Math.round((event.clientY - this.move.originY) / this.scale);
          for (let i in this.selected) {
            let index = this.selected[i];
            let item = this.editPage.controls[index];
            let origin = this.move.origin[item.uuid];
            if (origin) {
              item.location.x = origin.x + dX;
              item.location.y = origin.y + dY;
            }
          }
        }
      }
    },
    mouseup(event) {
      if (this.indicator.enable) {
        this.indicator.enable = false;
        this.indicatorStyle.display = "none";
        let minX = this.indicator.pageX;
        let minY = this.indicator.pageY;
        let dX = Math.round(
          (event.clientX - this.indicator.clientX) / this.scale
        );
        let dY = Math.round(
          (event.clientY - this.indicator.clientY) / this.scale
        );
        let maxX = minX + dX;
        let maxY = minY + dY;
        if (minX > maxX) {
          let tmp = minX;
          minX = maxX;
          maxX = tmp;
        }
        if (minY > maxY) {
          let tmp = minY;
          minY = maxY;
          maxY = tmp;
        }
        if (Math.abs(dX) > 2 && Math.abs(dY) > 2) {
          let result = [];
          for (let i in this.editPage.controls) {
            let item = this.editPage.controls[i];
            let x = item.location.x;
            let y = item.location.y;
            let w = item.size.width;
            let h = item.size.height;
            if (x < minX || y < minY || x + w > maxX || y + h > maxY) continue;
            result.push(this.editPage.controls.length - parseInt(i) - 1);
          }
          setTimeout(() => {
            this.reverseSelected = result;
          }, 100);
        }
      }
      if (this.move.enable) {
        this.move.enable = false;
        let dX = Math.round((event.clientX - this.move.originX) / this.scale);
        let dY = Math.round((event.clientY - this.move.originY) / this.scale);
        for (let i in this.selected) {
          let index = this.selected[i];
          let item = this.editPage.controls[index];
          let origin = this.move.origin[item.uuid];
          if (origin) {
            item.location.x = origin.x + dX;
            item.location.y = origin.y + dY;
          }
        }
      }
    },
    dragstart(event, action, param) {
      event.stopPropagation();
      event.dataTransfer.setData("text", "ZHTUI");
      this.action = action;
      switch (action) {
        case "create":
          this.create.type = param;
          break;
        case "resize1":
        case "resize2":
        case "resize3":
        case "resize4":
          this.resize.index = param;
          this.resize.size.width = this.editPage.controls[param].size.width;
          this.resize.size.height = this.editPage.controls[param].size.height;
          this.resize.location.x = this.editPage.controls[param].location.x;
          this.resize.location.y = this.editPage.controls[param].location.y;
          this.resize.clientX = event.clientX;
          this.resize.clientY = event.clientY;
          break;
      }
    },
    dragover(event) {
      event.preventDefault();
    },
    createUI(type) {
      let element = ZHTUI.create(type);
      if (element) {
        element.name = this.newName(element.name);
        this.editPage.controls.push(element);
      }
    },
    drop(event) {
      event.preventDefault();
      let data = event.dataTransfer.getData("text");
      if (data != "ZHTUI") return;
      let dX, dY, offset, element;
      switch (this.action) {
        case "create":
          element = ZHTUI.create(this.create.type);
          if (element) {
            offset = this.offsetPage(event.target);
            offset.x += event.offsetX;
            offset.y += event.offsetY;
            element.location.x = Math.round(offset.x - element.size.width / 2);
            element.location.y = Math.round(offset.y - element.size.height / 2);
            element.name = this.newName(element.name);
            this.editPage.controls.push(element);
            this.reverseSelected = [0];
          }
          break;
        case "resize1":
          element = this.editPage.controls[this.resize.index];
          dX = Math.round((event.clientX - this.resize.clientX) / this.scale);
          dY = Math.round((event.clientY - this.resize.clientY) / this.scale);
          element.size.width = this.resize.size.width - dX;
          element.size.height = this.resize.size.height - dY;
          element.location.x = this.resize.location.x + dX;
          element.location.y = this.resize.location.y + dY;
          if (element.size.width < 0) element.size.width = 1;
          if (element.size.height < 0) element.size.height = 1;
          break;
        case "resize2":
          element = this.editPage.controls[this.resize.index];
          dX = Math.round((event.clientX - this.resize.clientX) / this.scale);
          dY = Math.round((event.clientY - this.resize.clientY) / this.scale);
          element.size.width = this.resize.size.width + dX;
          element.size.height = this.resize.size.height - dY;
          element.location.y = this.resize.location.y + dY;
          if (element.size.width < 0) element.size.width = 1;
          if (element.size.height < 0) element.size.height = 1;
          break;
        case "resize3":
          element = this.editPage.controls[this.resize.index];
          dX = Math.round((event.clientX - this.resize.clientX) / this.scale);
          dY = Math.round((event.clientY - this.resize.clientY) / this.scale);
          element = this.editPage.controls[this.resize.index];
          element.size.width = this.resize.size.width + dX;
          element.size.height = this.resize.size.height + dY;
          if (element.size.width < 0) element.size.width = 1;
          if (element.size.height < 0) element.size.height = 1;
          break;
        case "resize4":
          element = this.editPage.controls[this.resize.index];
          dX = Math.round((event.clientX - this.resize.clientX) / this.scale);
          dY = Math.round((event.clientY - this.resize.clientY) / this.scale);
          element.size.width = this.resize.size.width - dX;
          element.size.height = this.resize.size.height + dY;
          element.location.x = this.resize.location.x + dX;
          if (element.size.width < 0) element.size.width = 1;
          if (element.size.height < 0) element.size.height = 1;
          break;
      }
    },
    listChange(select) {
      let isArray = Object.prototype.toString.call(select) == "[object Array]";
      if (isArray) this.reverseSelected = select;
      else this.reverseSelected = [select];
    },
    selectElement(event, i, toggle) {
      event.stopPropagation();
      if (i == undefined) {
        this.reverseSelected = [];
        return;
      }
      let hasSelected = this.selected.indexOf(i) != -1;
      if (this.isCtrl) {
        if (hasSelected) {
          let index = this.reverseSelected.indexOf(
            this.editPage.controls.length - i - 1
          );
          if (index != -1) {
            this.reverseSelected.splice(index, 1);
          }
        } else {
          this.reverseSelected.push(this.editPage.controls.length - i - 1);
        }
      } else if (!hasSelected) {
        this.reverseSelected = [this.editPage.controls.length - i - 1];
      } else if (toggle) {
        this.reverseSelected = [];
      }
    },
    offsetPage(element) {
      let result = { x: 0, y: 0 };
      if (element.id == "editPage") {
        return result;
      }
      if (element.offsetParent) {
        let offset = this.offsetPage(element.offsetParent);
        result.x += offset.x;
        result.y += offset.y;
      }
      result.x += element.offsetLeft;
      result.y += element.offsetTop;
      return result;
    },
    newName(name) {
      let index = 1;
      let result = name;
      let exist = true;
      while (exist) {
        result = `${name}${index}`;
        exist = false;
        for (let i in this.editPage.controls) {
          let control = this.editPage.controls[i];
          if (control.name == result) {
            exist = true;
            break;
          }
        }
        if (exist) index++;
        else break;
      }
      return result;
    },
    exportFile(page) {
      let file, filename;
      if (page) {
        file = base64.encode(JSON.stringify(page));
        filename = `${page.name}-页面.json`;
      } else {
        file = base64.encode(JSON.stringify(this.pages));
        filename = `${client.org.name}-页面.json`;
      }
      let url = `data:application/json;base64,${file}`;
      let dlLink = document.createElement("a");
      dlLink.download = filename;
      dlLink.href = url;
      dlLink.dataset.downloadurl = [
        "application/json",
        dlLink.download,
        dlLink.href,
      ].join(":");
      document.body.appendChild(dlLink);
      dlLink.click();
      document.body.removeChild(dlLink);
    },
    async importFile(file) {
      try {
        let url = this.getFileURL(file);
        let blob = await this.getFileBlob(url);
        let token = ";base64,";
        let index = blob.indexOf(token);
        if (index <= 0) throw "文件内容错误";
        blob = blob.slice(index + token.length);
        blob = base64.decode(blob);
        blob = JSON.parse(blob);
        let isArray = Object.prototype.toString.call(blob) == "[object Array]";
        let array = isArray ? blob : [blob];
        this.importModel = array;
        this.showImport = true;
      } catch (error) {
        client.$emit("toast", error);
      }
      this.file = null;
    },
    async importCreate() {
      client.$emit(
        "startBusy",
        "ConfigPage.importFile",
        "正在导入页面,请稍候..."
      );
      await proto.sleep(100);
      try {
        let array = this.importModel;
        let pages = [];
        let idDict = {};
        for (let i in array) {
          let model = array[i];
          if (!model || model.type != "zht-page") throw "文件内容错误";
          let newId = uuid();
          idDict[model.uuid] = newId;
          model.uuid = newId;
          model.name += "(New)";
        }
        let idList = Object.keys(idDict);
        for (let i in array) {
          let json = JSON.stringify(array[i]);
          for (let j in idList) {
            json = json.replace(new RegExp(idList[j], "g"), idDict[idList[j]]);
          }
          array[i] = JSON.parse(json);
        }
        for (let i in array) {
          let model = array[i];
          if (!model || model.type != "zht-page") throw "文件内容错误";
          let page = {};
          page.uuid = model.uuid;
          page.name = model.name;
          page.geometry = {
            width: model.size.width,
            height: model.size.height,
            x: 0,
            y: 0,
          };
          if (model.style["background-image"]) {
            page.bkGround = {
              bkType: 2,
              content: model.style["background-image"],
            };
          } else {
            page.bkGround = {
              bkType: 1,
              content: model.style["background-color"],
            };
          }
          let thumbnail = base64.encode(
            "ivisual" +
              JSON.stringify({
                controls: model.controls,
                bindings: model.bindings,
              })
          );
          page.thumbnail = thumbnail;
          page.orgid = client.org.id;
          pages.push(page);
        }
        await client.send(proto.MESSAGE_TYPE.pageMessage, {
          mcd: {
            operate: proto.OperateMode.createOpt,
            range: "-1",
            parentId: client.project.id,
          },
          pages,
        });
        this.getPages();
      } catch (error) {
        client.$emit("toast", error);
      }
      this.importModel = [];
      client.$emit("endBusy", "ConfigPage.importFile");
    },
    async importUpdate() {
      client.$emit(
        "startBusy",
        "ConfigPage.importFile",
        "正在导入页面,请稍候..."
      );
      await proto.sleep(100);
      try {
        let array = this.importModel;
        let pages = [];
        for (let i in array) {
          let model = array[i];
          if (!model || model.type != "zht-page") throw "文件内容错误";
          let page = {};
          page.uuid = model.uuid;
          page.name = model.name;
          page.geometry = {
            width: model.size.width,
            height: model.size.height,
            x: 0,
            y: 0,
          };
          if (model.style["background-image"]) {
            page.bkGround = {
              bkType: 2,
              content: model.style["background-image"],
            };
          } else {
            page.bkGround = {
              bkType: 1,
              content: model.style["background-color"],
            };
          }
          let thumbnail = base64.encode(
            "ivisual" +
              JSON.stringify({
                controls: model.controls,
                bindings: model.bindings,
              })
          );
          page.thumbnail = thumbnail;
          page.orgid = client.org.id;
          pages.push(page);
        }
        let res = await client.send(proto.MESSAGE_TYPE.queryMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
            parentId: client.project.id,
          },
          queryCondition: {
            queryObjType: 4,
            act: 1,
            project: client.project.id,
            scope: 3,
            scopeexpr: "id<>''",
          },
          rpp: 10000,
          pageindex: 0,
        });
        let existPages = res.results || [];
        existPages = existPages.map((p) => p.uuid);
        let addPages = [];
        let updatePages = [];
        for (let i in pages) {
          if (existPages.indexOf(pages[i].uuid) != -1) {
            updatePages.push(pages[i]);
          } else {
            addPages.push(pages[i]);
          }
        }
        if (addPages.length) {
          await client.send(proto.MESSAGE_TYPE.pageMessage, {
            mcd: {
              operate: proto.OperateMode.createOpt,
              range: "-1",
              parentId: client.project.id,
            },
            pages: addPages,
          });
        }
        if (updatePages.length) {
          await client.send(proto.MESSAGE_TYPE.pageMessage, {
            mcd: {
              operate: proto.OperateMode.updateOpt,
              range: "-1",
              parentId: client.project.id,
            },
            pages: updatePages,
          });
        }
        this.getPages();
      } catch (error) {
        client.$emit("toast", error);
      }
      this.importModel = [];
      client.$emit("endBusy", "ConfigPage.importFile");
    },
    getFileURL(file) {
      let url = null;
      if (window.createObjectURL !== undefined) {
        url = window.createObjectURL(file);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file);
      }
      return url;
    },
    getFileBlob(url) {
      return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.responseType = "blob";
        xhr.onload = function() {
          if (xhr.status === 200) {
            let blob = xhr.response;
            let fileReader = new FileReader();
            fileReader.onloadend = function(e) {
              fileReader = null;
              xhr = null;
              resolve(e.target.result);
            };
            fileReader.readAsDataURL(blob);
          }
        };
        xhr.onerror = function() {
          xhr = null;
          reject();
        };
        xhr.send();
      });
    },
  },
};
</script>
