<style scoped>
.bind-tips {
  max-width: 400px;
}
.bind-tips > p {
  word-break: break-all;
}
</style>
<template>
  <v-dialog v-model="show" persistent max-width="480">
    <v-card>
      <v-card-title>编辑绑定</v-card-title>
      <v-divider></v-divider>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="控件"
              :value="controlName"
              disabled
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="属性"
              :value="propertyName"
              disabled
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="源类型"
              :value="srcTypes[this.value.srcType]"
              disabled
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="数据类型"
              :value="valueTypes[this.value.valueType] || this.value.valueType"
              disabled
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <div
          class="d-flex align-center"
          v-if="value.valueType != 'statistics' && value.srcType != 'api'"
        >
          <div>绑定源</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="selectorShow = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        <ObjectSelector
          v-model="selectorShow"
          @add="addSrc"
          :type="value.srcType"
        ></ObjectSelector>
        <v-card
          outlined
          class="mb-4"
          v-if="value.valueType != 'statistics' && value.srcType != 'api'"
        >
          <v-data-table
            dense
            :headers="headers"
            :items="src"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon @click="up(item)">
                <v-icon>mdi-arrow-up-bold</v-icon>
              </v-btn>
              <v-btn icon @click="down(item)">
                <v-icon>mdi-arrow-down-bold</v-icon>
              </v-btn>
              <v-btn icon @click="removeSrc(item)">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
        <v-text-field
          v-if="value.srcType == 'api'"
          label="执行间隔(单位：秒，至少间隔5秒)"
          v-model="interval"
          hide-details
          outlined
          dense
          class="mt-3"
        ></v-text-field>
        <v-textarea
          v-if="value.srcType == 'api' && value.valueType != '客户端脚本'"
          label="API参数"
          v-model="param"
          no-resize
          rows="3"
          hide-details
          outlined
          class="mt-3"
        ></v-textarea>
        <div class="d-flex mb-1">
          <v-spacer></v-spacer>
          <v-btn
            text
            class="link-text"
            @click="JScript = `return values.join(',')`"
          >
            实时数据
          </v-btn>
          <v-btn
            text
            class="link-text"
            @click="JScript = `return values[0].map((d)=>d.time).join(',')`"
          >
            历史数据时间
          </v-btn>
          <v-btn
            text
            class="link-text"
            @click="JScript = `return values[0].map((d)=>d.value).join(',')`"
          >
            历史数据值
          </v-btn>
          <v-btn
            text
            class="link-text"
            @click="JScript = `return values[0].s6`"
          >
            实时统计
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on">
                mdi-comment-question
              </v-icon>
            </template>
            <div class="bind-tips">
              <p>脚本传入参数为数组values，返回参数为绑定属性值。</p>
              <p>其中values结构如下：</p>
              <p>实时数据 values = ["测点1值","测点2值","测点3值"]</p>
              <p>
                告警状态 values = [1,2,3,4,5,6,7,8,9,10,11,12] 对应ALARM_TYPE
                其中7,8为正常,其余为各类型告警
              </p>
              <p>
                联机状态 values = [0,1,2] 对应STATUS_VALUE
                其中0为离线,1为在线,2为禁用
              </p>
              <p>
                历史数据 values = [[{time:'2020-02-02
                02:02:02',value:'1'},{time:'2020-02-02 02:02:03',value:'2'}]]
                注意2层数据，第1层为测点数组，第2层为历史数据数组
              </p>
              <p>
                实时统计 values =
                [{s1:0,s2:0,s3:0,s4:0,s5:0,s6:0,s7:0,s8:0,s9:0,s10:0,s11:0,s12:0}]
                对应StatisticalType s1:监控主机总数 s2:监控主机在线数
                s3:监控主机离线数 s4:监控主机禁用数 s5:监控主机告警数
                s6:当前告警数 s7:未确认事件数 s8:未确认告警数 s9:设备总数
                s10:设备在线数 s11:设备离线数 s12:设备告警数
              </p>
            </div>
          </v-tooltip>
        </div>
        <v-textarea
          label="数据转换脚本"
          v-model="JScript"
          no-resize
          rows="3"
          hide-details
          outlined
        ></v-textarea>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain @click="ok">确定</v-btn>
        <v-btn plain @click="cancel">取消</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import editors from "../zht-ui/editor";
import ObjectSelector from "./ObjectSelector.vue";
export default {
  components: { ObjectSelector },
  props: { value: Object, controls: Array },
  data() {
    return {
      show: false,
      selectorShow: false,
      srcTypes: {
        org: "机构",
        agent: "监控单元",
        device: "设备",
        spot: "测点",
        statistics: "实时统计",
        api: "API",
      },
      valueTypes: {
        alarm: "告警状态",
        status: "联机状态",
        data: "实时数据",
        history: "历史数据",
        statistics: "实时统计值",
      },
      headers: [
        {
          text: "名称",
          value: "name",
          sortable: false,
        },
        {
          text: "操作",
          value: "actions",
          sortable: false,
        },
      ],
      src: [],
      JScript: "",
      interval: "",
      param: "",
    };
  },
  computed: {
    controlName() {
      for (let i in this.controls) {
        if (this.controls[i].uuid == this.value.uuid)
          return this.controls[i].name;
      }
      return "已删除";
    },
    propertyName() {
      let prop = this.value.prop;
      if (editors[prop]) return editors[prop].name;
      return "未知属性";
    },
  },
  mounted() {
    this.show = true;
    this.src = JSON.parse(JSON.stringify(this.value.src));
    this.JScript = this.value.JScript;
    this.interval = this.value.interval || "10";
    this.param = this.value.param || `return {"参数":"值"}`;
  },
  methods: {
    addSrc(item) {
      this.src.push(item);
    },
    removeSrc(item) {
      for (let i = 0; i < this.src.length; i++) {
        if (this.src[i] == item) {
          this.src.splice(i, 1);
          break;
        }
      }
    },
    up(item) {
      for (let i = 0; i < this.src.length; i++) {
        if (this.src[i] == item) {
          if (i > 0) {
            this.src.splice(i, 1);
            this.src.splice(i - 1, 0, item);
          }
          break;
        }
      }
    },
    down(item) {
      for (let i = 0; i < this.src.length; i++) {
        if (this.src[i] == item) {
          if (i < this.src.length - 1) {
            this.src.splice(i, 1);
            this.src.splice(i + 1, 0, item);
          }
          break;
        }
      }
    },
    ok() {
      this.show = false;
      this.$emit("close", {
        src: this.src,
        JScript: this.JScript,
        interval: this.interval,
        param: this.param,
      });
    },
    cancel() {
      this.show = false;
      this.$emit("close", null);
    },
  },
};
</script>
