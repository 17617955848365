<template>
  <v-container
    fluid
    :style="{ display: show ? 'flex' : 'none' }"
    class="login-bg fill-height align-center justify-center"
    style="overflow: auto"
  >
    <v-card max-width="400" class="pa-3 flex-grow-1">
      <v-form @submit.prevent="login">
        <v-card-title class="flex-column">
          <div>
            <h2 class="my-3">IMCP综合监控平台</h2>
            <div class="d-flex align-center">
              <div
                class="flex-grow-1"
                style="border-top: 1px solid black"
              ></div>
              <h4 class="mx-3">iVisual</h4>
              <div
                class="flex-grow-1"
                style="border-top: 1px solid black"
              ></div>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="host" label="服务器地址"></v-text-field>
          <v-text-field v-model="port" label="服务器端口"></v-text-field>
          <v-text-field v-model="account" label="用户名"></v-text-field>
          <v-text-field
            v-model="password"
            label="密码"
            type="password"
          ></v-text-field>
          <v-select
            v-model="developer"
            label="用户类型"
            :items="[
              { text: '开发者', value: true },
              { text: '工程用户', value: false },
            ]"
            hide-details
          ></v-select>
        </v-card-text>
        <v-card-text>
          <v-btn block color="primary" type="submit">登录</v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import client from "../utils/client";
import store from "../utils/store";
export default {
  props: { show: Boolean },
  data() {
    return {
      host: "localhost",
      port: 6780,
      account: "",
      password: "",
      developer: false,
    };
  },
  created() {
    this.host = store.get("login-host") || 'localhost';
    this.port = store.get("login-port") || 6780;
    this.account = store.get("login-account") || "";
    this.developer = store.get("login-developer") === "true";
  },
  watch: {
    show() {
      this.password = "";
    },
  },
  methods: {
    async login() {
      store.set("login-host", this.host);
      store.set("login-port", this.port);
      store.set("login-account", this.account);
      store.set("login-developer", this.developer);
      try {
        await client.login(
          this.host,
          this.port,
          this.account,
          this.password,
          this.developer
        );
      } catch (error) {
        client.$emit("toast", error);
      }
    },
  },
};
</script>
