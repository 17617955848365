<template>
  <v-dialog :value="show" max-width="480" persistent>
    <v-card>
      <v-card-title>修改密码</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-text-field
          label="密码"
          v-model="password"
          type="password"
        ></v-text-field>
        <v-text-field
          label="确认密码"
          v-model="passwordRe"
          type="password"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="success" @click="submit">确定</v-btn>
        <v-btn text @click="close">取消</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  props: { show: Boolean, userId: String },
  model: {
    prop: "show",
    event: "change",
  },
  data() {
    return {
      password: "",
      passwordRe: "",
    };
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.password = "";
          this.passwordRe = "";
        }
      },
    },
  },
  methods: {
    async submit() {
      try {
        if (!this.userId) throw "用户信息有误";
        if (!this.password) throw "密码不能为空";
        if (this.password != this.passwordRe) throw "密码不一致";
        client.$emit(
          "startBusy",
          "UserInfoDialog.submit",
          "正在修改密码,请稍候..."
        );
        await proto.sleep(100);
        let res = await client.send(proto.MESSAGE_TYPE.userMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: this.userId,
          },
        });
        if (!res.users || !res.users.length) throw "未找到用户信息";
        let user = res.users[0];
        user.password = this.password;
        await client.send(proto.MESSAGE_TYPE.userMessage, {
          mcd: {
            operate: proto.OperateMode.updateOpt,
          },
          users: [user],
        });
        this.close();
      } catch (e) {
        client.$emit("toast", e.toString());
      }
      client.$emit("endBusy", "UserInfoDialog.submit");
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>
