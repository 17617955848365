import model from './model';

export default {
    /** 组件注册 */
    install: function (Vue) {
        /** 页面控件 */
        Vue.component('zht-div', () => import('./ZhtDiv.vue'));
        Vue.component('zht-component', () => import('./ZhtComponent.vue'));
        Vue.component('zht-tabs', () => import('./ZhtTabs.vue'));
        Vue.component('zht-rtsp', () => import('./ZhtRtsp.vue'));
        Vue.component('zht-rtsp-playback', () => import('./ZhtRtspPlayback.vue'));
        Vue.component('zht-chart', () => import('./ZhtChart.vue'));
        Vue.component('zht-line', () => import('./ZhtLine.vue'));
        Vue.component('zht-bar', () => import('./ZhtBar.vue'));
        Vue.component('zht-pie', () => import('./ZhtPie.vue'));
        Vue.component('zht-gauge', () => import('./ZhtGauge.vue'));
        Vue.component('zht-map', () => import('./ZhtMap.vue'));
        Vue.component('zht-bmap', () => import('./ZhtBMap.vue'));
        Vue.component('zht-textbox', () => import('./ZhtTextBox.vue'));
        Vue.component('zht-combobox', () => import('./ZhtComboBox.vue'));
        Vue.component('zht-button', () => import('./ZhtButton.vue'));
        Vue.component('zht-icon', () => import('./ZhtIcon.vue'));
        Vue.component('zht-csv', () => import('./ZhtCSV.vue'));
        Vue.component('zht-field', () => import('./ZhtField.vue'));
        Vue.component('zht-spotlist', () => import('./ZhtSpotList.vue'));
        Vue.component('zht-browser', () => import('./ZhtBrowser.vue'));
        Vue.component('zht-svg', () => import('./ZhtSVG.vue'));
        /** 属性编辑控件 */
        Vue.component('zht-editor-text', () => import('./editors/TextEditor.vue'));
        Vue.component('zht-editor-textarea', () => import('./editors/TextareaEditor.vue'));
        Vue.component('zht-editor-date', () => import('./editors/DateEditor.vue'));
        Vue.component('zht-editor-options', () => import('./editors/OptionsEditor.vue'));
        Vue.component('zht-editor-password', () => import('./editors/PasswordEditor.vue'));
        Vue.component('zht-editor-color', () => import('./editors/ColorEditor.vue'));
        Vue.component('zht-editor-enum', () => import('./editors/EnumEditor.vue'));
        Vue.component('zht-editor-image', () => import('./editors/ResourceEditor.vue'));
        Vue.component('zht-editor-video', () => import('./editors/ResourceEditor.vue'));
        Vue.component('zht-editor-rtsp', () => import('./editors/RtspEditor.vue'));
        Vue.component('zht-editor-tabs', () => import('./editors/TabsEditor.vue'));
        Vue.component('zht-editor-page', () => import('./editors/PageEditor.vue'));
        Vue.component('zht-editor-device', () => import('./editors/DeviceEditor.vue'));
        Vue.component('zht-editor-icon', () => import('./editors/IconEditor.vue'));
        Vue.component('zht-editor-mappoint', () => import('./editors/MapPointEditor.vue'));
        Vue.component('zht-editor-shadow', () => import('./editors/ShadowEditor.vue'));
        Vue.component('zht-editor-object', () => import('./editors/ObjectEditor.vue'));
        Vue.component('zht-editor-lnglat', () => import('./editors/LngLatEditor.vue'));
        Vue.component('zht-editor-transform', () => import('./editors/TransformEditor.vue'));
    },
    /** 页面控件模型创建 */
    create: function (type) {
        switch (type) {
            case 'zht-div': return new model.ZhtDiv();
            case 'zht-component': return new model.ZhtComponent();
            case 'zht-tabs': return new model.ZhtTabs();
            case 'zht-rtsp': return new model.ZhtRtsp();
            case 'zht-rtsp-playback': return new model.ZhtRtspPlayback();
            case 'zht-chart': return new model.ZhtChart();
            case 'zht-line': return new model.ZhtLine();
            case 'zht-bar': return new model.ZhtBar();
            case 'zht-pie': return new model.ZhtPie();
            case 'zht-gauge': return new model.ZhtGauge();
            case 'zht-map': return new model.ZhtMap();
            case 'zht-bmap': return new model.ZhtBMap();
            case 'zht-textbox': return new model.ZhtTextBox();
            case 'zht-combobox': return new model.ZhtComboBox();
            case 'zht-button': return new model.ZhtButton();
            case 'zht-icon': return new model.ZhtIcon();
            case 'zht-csv': return new model.ZhtCSV();
            case 'zht-field': return new model.ZhtField();
            case 'zht-spotlist': return new model.ZhtSpotList();
            case 'zht-browser': return new model.ZhtBrowser();
            case 'zht-svg': return new model.ZhtSVG();
            default: return null;
        }
    },
    /** 页面控件兼容修复 */
    fix: function (type, model) {
        let base = this.create(type);
        if (base) this.deepClone(base, model);
    },
    /** 深度克隆 */
    deepClone: function (src, dst) {
        for (let attr in src) {
            let srcValue = src[attr];
            let dstValue = dst[attr];
            let srcType = typeof (srcValue);
            let dstType = typeof (dstValue);
            if (srcType != dstType || dstType == "undefined") {
                if (srcType !== 'function' && srcType !== 'undefined')
                    dst[attr] = JSON.parse(JSON.stringify(src[attr]));
                else
                    dst[attr] = src[attr];
            } else if (srcType == dstType && srcType == "object") {
                this.deepClone(src[attr], dst[attr]);
            }
        }
    },
};
