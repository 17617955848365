var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[(!_vm.fullscreen)?_c('v-navbar',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.history.length > 1),expression:"history.length > 1"}],attrs:{"icon":""},on:{"click":_vm.goBack}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left-bold-circle-outline")])],1)]}}],null,false,1107416943)},[_c('span',[_vm._v("返回前一页")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.goHome}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home-outline")])],1)]}}],null,false,2452007621)},[_c('span',[_vm._v("返回主页")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.unConfirmAlarmShow = true}}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"value":_vm.unConfirmAlarmCount,"content":_vm.unConfirmAlarmCount,"color":"error","overlap":""}},[_c('v-icon',[_vm._v("mdi-alert-outline")])],1)],1)]}}],null,false,2696366086)},[_c('span',[_vm._v("未确认告警")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.alarmDetailShow = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted-triangle")])],1)]}}],null,false,3601982690)},[_c('span',[_vm._v("告警列表")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.audioSwitch}},'v-btn',attrs,false),on),[(_vm.speakEnable)?_c('v-icon',[_vm._v("mdi-account-voice")]):_c('v-icon',[_vm._v("mdi-voice-off")])],1)]}}],null,false,3526078625)},[(_vm.speakEnable)?_c('span',[_vm._v("关闭AI语音播报")]):_c('span',[_vm._v("开启AI语音播报")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showAlarm = !_vm.showAlarm}}},'v-btn',attrs,false),on),[(_vm.showAlarm)?_c('v-icon',[_vm._v("mdi-playlist-check")]):_c('v-icon',[_vm._v("mdi-playlist-remove")])],1)]}}],null,false,2570033351)},[(_vm.showAlarm)?_c('span',[_vm._v("关闭告警字幕")]):_c('span',[_vm._v("开启告警字幕")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.inspection.show = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-restore-outline")])],1)]}}],null,false,532211259)},[_c('span',[_vm._v("页面轮询")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"fillMode",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-aspect-ratio")])],1),_c('v-menu',{attrs:{"activator":".fillMode","offset-x":"","offset-y":"","left":"","bottom":"","rounded":"","min-width":"100"}},[_c('v-list',_vm._l((_vm.fillModes),function(item){return _c('v-list-item',{key:item.value,on:{"click":function($event){_vm.fillMode = item.value}}},[_c('v-list-item-title',{style:({
                  color: _vm.fillMode == item.value ? '#007BFF' : 'black',
                }),domProps:{"textContent":_vm._s(item.text)}})],1)}),1)],1)]}}],null,false,1762622311)},[_c('span',[_vm._v("拉伸方式")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.setFullscreen(true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-fullscreen")])],1)]}}],null,false,504953170)},[_c('span',[_vm._v("全屏浏览")])])],1):_vm._e(),_c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"container",staticClass:"monitor-page"},[_c('zht-component',{attrs:{"control":_vm.control,"pageId":_vm.pageId,"isPage":true,"isView":true,"rootWidth":_vm.rootWidth,"rootHeight":_vm.rootHeight,"fillMode":_vm.fillMode}})],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.fullscreen),expression:"fullscreen"}],staticClass:"white--text ma-3",attrs:{"fab":"","small":"","right":"","absolute":"","elevation":"0","color":"rgba(128,128,128,0.2)"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}])},[_c('div',{staticClass:"d-flex flex-column"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.history.length > 1),expression:"history.length > 1"}],staticClass:"mt-1",attrs:{"fab":"","small":"","elevation":"0","color":"rgba(255,255,255,0.8)"},on:{"click":_vm.goBack}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left-bold-circle-outline")])],1)]}}])},[_c('span',[_vm._v("返回前一页")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","small":"","elevation":"0","color":"rgba(255,255,255,0.8)"},on:{"click":_vm.goHome}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home")])],1)]}}])},[_c('span',[_vm._v("返回主页")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","small":"","elevation":"0","color":"rgba(255,255,255,0.8)"},on:{"click":function($event){_vm.unConfirmAlarmShow = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-alert")])],1)]}}])},[_c('span',[_vm._v("未确认告警")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","small":"","elevation":"0","color":"rgba(255,255,255,0.8)"},on:{"click":function($event){_vm.alarmDetailShow = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted-triangle")])],1)]}}])},[_c('span',[_vm._v("告警列表")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","small":"","elevation":"0","color":"rgba(255,255,255,0.8)"},on:{"click":function($event){_vm.speakEnable = !_vm.speakEnable}}},'v-btn',attrs,false),on),[(_vm.speakEnable)?_c('v-icon',[_vm._v("mdi-account-voice")]):_c('v-icon',[_vm._v("mdi-voice-off")])],1)]}}])},[(_vm.speakEnable)?_c('span',[_vm._v("关闭AI语音播报")]):_c('span',[_vm._v("开启AI语音播报")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","small":"","elevation":"0","color":"rgba(255,255,255,0.8)"},on:{"click":function($event){_vm.showAlarm = !_vm.showAlarm}}},'v-btn',attrs,false),on),[(_vm.showAlarm)?_c('v-icon',[_vm._v("mdi-playlist-check")]):_c('v-icon',[_vm._v("mdi-playlist-remove")])],1)]}}])},[(_vm.showAlarm)?_c('span',[_vm._v("关闭告警字幕")]):_c('span',[_vm._v("开启告警字幕")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","small":"","elevation":"0","color":"rgba(255,255,255,0.8)"},on:{"click":function($event){_vm.inspection.show = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-restore-outline")])],1)]}}])},[_c('span',[_vm._v("页面轮询")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","small":"","elevation":"0","color":"rgba(255,255,255,0.8)"},on:{"click":function($event){return _vm.setFullscreen(false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-fullscreen-exit")])],1)]}}])},[_c('span',[_vm._v("退出全屏")])])],1)]),_c('AlarmDetailDialog',{model:{value:(_vm.alarmDetailShow),callback:function ($$v) {_vm.alarmDetailShow=$$v},expression:"alarmDetailShow"}}),_c('UnConfirmAlarmDialog',{model:{value:(_vm.unConfirmAlarmShow),callback:function ($$v) {_vm.unConfirmAlarmShow=$$v},expression:"unConfirmAlarmShow"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.inspection.show),callback:function ($$v) {_vm.$set(_vm.inspection, "show", $$v)},expression:"inspection.show"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("页面轮询")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.inspection.addShow = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("添加轮询页面")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.inspection.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-data-table',{staticStyle:{"height":"360px","overflow-y":"auto"},attrs:{"dense":"","headers":[
          { text: '名称', value: 'name' },
          { text: '操作', value: 'actions', align: 'right' } ],"items":_vm.inspection.pages,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.inspectionUp(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.inspectionDown(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-down")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.inspectionRemove(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)}),_c('v-divider'),_c('v-card-actions',[_c('span',[_vm._v("轮询状态："+_vm._s(_vm.inspection.enable ? "已启动" : "已停止"))]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.inspection.enable = !_vm.inspection.enable;
            _vm.inspection.show = false;}}},[_vm._v(" "+_vm._s(_vm.inspection.enable ? "停止轮询" : "启动轮询")+" ")])],1)],1)],1),_c('ObjectSelector',{attrs:{"type":"page","single":false},on:{"add":_vm.inspectionAdd},model:{value:(_vm.inspection.addShow),callback:function ($$v) {_vm.$set(_vm.inspection, "addShow", $$v)},expression:"inspection.addShow"}}),_c('VoiceDialog',{on:{"voice":_vm.audioStart},model:{value:(_vm.voiceShow),callback:function ($$v) {_vm.voiceShow=$$v},expression:"voiceShow"}}),_c('audio',{ref:"audio",staticStyle:{"display":"none"},on:{"ended":_vm.audioEnd,"error":_vm.audioError}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }