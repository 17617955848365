<template>
  <v-dialog :value="show" persistent max-width="540">
    <v-card>
      <v-card-title>快速替换绑定源</v-card-title>
      <v-card-subtitle>
        选择一个设备，自动匹配控件测点名与设备测点名，名称相同并且源类型为测点的绑定进行源替换。
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <v-text-field label="设备" :value="device.nam" readonly hide-details>
          <template slot="append">
            <v-btn icon tile @click="deviceShow = true">
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <div>预计替换绑定：</div>
        <v-data-table
          :headers="headers"
          :items="mayChange"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.prop`]="{ item }">
            <div v-text="propertyName(item.prop)"></div>
          </template>
          <template v-slot:[`item.srcType`]="{ item }">
            <div v-text="srcTypes[item.srcType]"></div>
          </template>
          <template v-slot:[`item.valueType`]="{ item }">
            <div v-text="valueTypes[item.valueType]"></div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="success" @click="submit">确定</v-btn>
        <v-btn text @click="close">取消</v-btn>
      </v-card-actions>
    </v-card>
    <ObjectSelector
      v-model="deviceShow"
      type="device"
      :single="true"
      @select="deviceChange"
    ></ObjectSelector>
  </v-dialog>
</template>

<script>
import ObjectSelector from "./ObjectSelector.vue";
import proto from "../utils/proto";
import client from "../utils/client";
import editors from "../zht-ui/editor";

export default {
  components: { ObjectSelector },
  model: {
    prop: "show",
    event: "change",
  },
  props: ["show", "bindings", "controls"],
  data() {
    return {
      deviceShow: false,
      device: { id: "", name: "未选择" },
      spots: [],
      headers: [
        {
          text: "控件",
          value: "controlName",
        },
        {
          text: "属性",
          value: "prop",
        },
        {
          text: "源类型",
          value: "srcType",
        },
        {
          text: "数据类型",
          value: "valueType",
        },
        {
          text: "源数量",
          value: "srcCount",
        },
      ],
      srcTypes: {
        org: "机构",
        agent: "监控单元",
        device: "设备",
        spot: "测点",
        statistics: "实时统计",
      },
      valueTypes: {
        alarm: "告警状态",
        status: "联机状态",
        data: "实时数据",
        history: "历史数据",
        statistics: "实时统计值",
      },
    };
  },
  computed: {
    mayChange() {
      let result = [];
      for (let i in this.bindings) {
        let binding = this.bindings[i];
        if (binding.srcType == "spot") {
          for (let j in this.controls) {
            let control = this.controls[j];
            if (control.uuid == binding.uuid) {
              for (let k in this.spots) {
                let spot = this.spots[k];
                if (spot.name == control.spotName) {
                  result.push({
                    uuid: binding.uuid,
                    prop: binding.prop,
                    srcType: binding.srcType,
                    valueType: binding.valueType,
                    srcCount: binding.src.length,
                    controlName: control.name,
                    spotId: spot.uuid,
                    spotName: `${this.device.name}-${spot.name}`,
                  });
                }
              }
              break;
            }
          }
        }
      }
      return result;
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.device = { id: "", name: "未选择" };
          this.spots = [];
        }
      },
    },
  },
  methods: {
    propertyName(prop) {
      if (editors[prop]) return editors[prop].name;
      return "未知属性";
    },
    deviceChange(model) {
      this.device = model;
      this.getSpots();
    },
    async getSpots() {
      client.$emit(
        "startBusy",
        "ReplaceBinding.getSpots",
        "正在获取测点,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.spotMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
            parentId: this.device.id,
          },
          act: 0,
        });
        if (res.spots && res.spots.length) {
          res.spots.sort((a, b) => {
            return a.no > b.no ? 1 : -1;
          });
          this.spots = res.spots;
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ReplaceBinding.getSpots");
    },
    submit() {
      this.$emit("submit", this.mayChange);
      this.close();
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>