<template>
  <div class="main">
    <div class="pa-4" style="max-width: 360px">
      <v-select dark label="数据保存策略" v-model="pp" :items="pps"></v-select>
      <v-text-field
        dark
        v-if="pp != 1 && pp != 3"
        label="正常保存周期"
        v-model="timePeriod"
      >
        <template slot="append">秒</template>
      </v-text-field>
      <v-text-field
        dark
        v-if="pp != 1 && pp != 3"
        label="告警保存周期"
        v-model="alarmpersist"
      >
        <template slot="append">秒</template>
      </v-text-field>
      <v-text-field
        dark
        v-if="pp != 1 && pp != 2"
        label="变化率"
        v-model="varianceRatio"
      >
        <template slot="append">%</template>
      </v-text-field>
      <v-select dark label="数据清除策略" v-model="cp" :items="cps"></v-select>
      <v-text-field dark v-if="cp != 1" label="保留时长" v-model="saveTerm">
        <template slot="append">
          <span v-if="cp == 2">天</span>
          <span v-if="cp == 3">月</span>
          <span v-if="cp == 4">年</span>
        </template>
      </v-text-field>
      <v-btn block dark color="rgba(255,255,255,0.2)" @click="submit">
        提交
      </v-btn>
    </div>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  data() {
    return {
      pp: 1,
      pps: [
        { text: "全部保存", value: 1 },
        { text: "周期性保存", value: 2 },
        { text: "变化率保存", value: 3 },
        { text: "周期性与变化率保存", value: 4 },
        { text: "周期性或变化率保存", value: 5 },
      ],
      timePeriod: 0,
      alarmpersist: 0,
      varianceRatio: 0,

      cp: 1,
      cps: [
        { text: "永久保存", value: 1 },
        { text: "保存天数", value: 2 },
        { text: "保存月数", value: 3 },
        { text: "保存年数", value: 4 },
      ],
      saveTerm: 0,
    };
  },
  mounted() {
    this.getSetting();
  },
  methods: {
    async getSetting() {
      client.$emit(
        "startBusy",
        "SystemStorage.getSetting",
        "正在删除资源,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(
          proto.MESSAGE_TYPE.commonRtdPersistPolicyMessage,
          {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
              parentId: client.project.id,
            },
          }
        );
        this.pp = res.pp || 1;
        this.timePeriod = res.timePeriod || 0;
        this.alarmpersist = res.alarmpersist || 0;
        this.varianceRatio = res.varianceRatio || 0;
        this.cp = res.cp || 1;
        this.saveTerm = res.saveTerm || 0;
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "SystemStorage.getSetting");
    },
    async submit() {
      client.$emit(
        "startBusy",
        "SystemStorage.submit",
        "正在保存策略,请稍候..."
      );
      await proto.sleep(100);
      try {
        let timePeriod = parseInt(this.timePeriod);
        let alarmpersist = parseInt(this.alarmpersist);
        let varianceRatio = parseInt(this.varianceRatio);
        let saveTerm = parseInt(this.saveTerm);
        if (
          !(
            timePeriod >= 0 &&
            alarmpersist >= 0 &&
            varianceRatio >= 0 &&
            saveTerm >= 0
          )
        ) {
          throw "提交的数据不合法";
        }
        await client.send(
          proto.MESSAGE_TYPE.commonRtdPersistPolicyMessage,
          {
            mcd: {
              operate: proto.OperateMode.createOpt,
              parentId: client.project.id,
            },
            pp: this.pp,
            timePeriod,
            alarmpersist,
            varianceRatio,
            cp: this.cp,
            saveTerm,
          }
        );
        client.$emit("toast", "保存成功", "success");
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "SystemStorage.submit");
    },
  },
};
</script>
