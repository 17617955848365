<template>
  <v-text-field
    :label="label"
    :value="valueDisplay"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>
            <div>{{ label }}编辑</div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              label="联动目标"
              :value="name"
              readonly
              filled
              background-color="transparent"
              hide-details
            >
              <template slot="append">
                <v-btn icon tile @click="selectorShow = true">
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <v-text-field
              label="控制值"
              v-model="dstvalue"
              filled
              background-color="transparent"
              hide-details
            >
            </v-text-field>
            <zht-editor-text
              label="优先级"
              v-model="priority"
            ></zht-editor-text>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
          <ObjectSelector
            v-model="selectorShow"
            type="spot"
            :single="true"
            @select="select"
          ></ObjectSelector>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import ObjectSelector from "./ObjectSelector.vue";
export default {
  components: {
    ObjectSelector,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      show: false,

      uuid: "",
      name: "",
      dstvalue: "",
      priority: 1,

      selectorShow: false,
    };
  },
  computed: {
    valueDisplay() {
      if (this.value && this.value.uuid) return this.value.name;
      return "未配置";
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.uuid = this.value ? this.value.uuid : "";
          this.name = this.value ? this.value.name : "";
          this.dstvalue = this.value ? this.value.dstvalue : "";
          this.priority = this.value ? this.value.priority : 1;
        }
      },
    },
  },
  methods: {
    select(item) {
      this.uuid = item.id;
      this.name = item.name;
    },
    submit() {
      this.$emit("change", {
        uuid: this.uuid,
        name: this.name,
        dstvalue: this.dstvalue,
        priority: this.priority,
      });
      this.show = false;
    },
  },
};
</script>
