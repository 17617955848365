<template>
  <v-dialog :value="show" max-width="480" persistent>
    <v-card>
      <v-card-title>
        <span>用户信息</span>
        <v-spacer></v-spacer>
        <v-btn text @click="pwdShow = true">修改密码</v-btn>
        <ChangePasswordDialog
          v-model="pwdShow"
          :userId="userId"
        ></ChangePasswordDialog>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-6" v-show="personId">
        <v-text-field dense label="姓名" v-model="info.name"></v-text-field>
        <v-select
          dense
          label="性别"
          v-model="info.sex"
          :items="[
            { text: '男', value: 1 },
            { text: '女', value: 2 },
          ]"
        ></v-select>
        <v-text-field dense label="工号" v-model="info.empno"></v-text-field>
        <v-text-field dense label="部门" v-model="info.company"></v-text-field>
        <v-text-field dense label="职位" v-model="info.position"></v-text-field>
        <v-text-field
          dense
          label="语音号码"
          v-model="info.telephone"
        ></v-text-field>
        <v-text-field
          dense
          label="短信号码"
          v-model="info.mobilephone"
        ></v-text-field>
        <v-text-field dense label="邮箱" v-model="info.email"></v-text-field>
      </v-card-text>
      <v-card-text class="pt-6" v-show="!personId">
        <div>开发者帐户没有对应的配置项</div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="success" @click="submit">确定</v-btn>
        <v-btn text @click="close">取消</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ChangePasswordDialog from "./ChangePasswordDialog.vue";
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  components: { ChangePasswordDialog },
  props: { show: Boolean },
  model: {
    prop: "show",
    event: "change",
  },
  data() {
    return {
      info: {},
      pwdShow: false,
    };
  },
  computed: {
    userId() {
      return client.user.uuid;
    },
    personId() {
      return client.user.person ? client.user.person.id : "";
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.getInfo();
      },
    },
  },
  methods: {
    async getInfo() {
      let result = {};
      if (this.personId) {
        client.$emit(
          "startBusy",
          "UserInfoDialog.getInfo",
          "正在获取用户信息,请稍候..."
        );
        await proto.sleep(100);
        try {
          let res = await client.send(proto.MESSAGE_TYPE.personMessage, {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
              range: "-1",
              parentId: client.project.id,
            },
            Persons: [{ uuid: this.personId, orgid: "orgid" }],
          });
          if (res.Persons && res.Persons.length) {
            result = res.Persons[0];
          }
        } catch (error) {
          client.$emit("toast", error);
        }
        client.$emit("endBusy", "UserInfoDialog.getInfo");
      }
      this.info = result;
    },
    async submit() {
      if (this.personId) {
        client.$emit(
          "startBusy",
          "UserInfoDialog.submit",
          "正在修改用户信息,请稍候..."
        );
        await proto.sleep(100);
        try {
          await client.send(proto.MESSAGE_TYPE.personMessage, {
            mcd: {
              operate: proto.OperateMode.updateOpt,
            },
            Persons: [this.info],
          });
          this.close();
        } catch (e) {
          client.$emit("toast", e.toString());
        }
        client.$emit("endBusy", "UserInfoDialog.submit");
      } else {
        this.close();
      }
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>