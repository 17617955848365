<template>
  <div class="main">
    <v-navbar></v-navbar>
    <v-container fluid class="flex-grow-1">
      <v-row class="fill-height">
        <v-col cols="5" sm="3">
          <v-card
            dark
            color="rgba(255,255,255,0.1)"
            class="fill-height d-flex flex-column"
          >
            <v-card-title>
              <span>时段</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="periodAdd">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>
            <div class="scroll-panel">
              <v-list
                class="scroll-content"
                style="background-color: transparent"
              >
                <v-list-item-group v-model="period" @change="getTimes">
                  <template v-for="item in periods">
                    <v-divider :key="`d-${item.id}`"></v-divider>
                    <v-list-item :key="item.id">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="my-0">
                        <div>
                          <v-btn icon @click="periodEdit(item)">
                            <v-icon>mdi-square-edit-outline</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            @click="
                              deletePeriod.model = item;
                              deletePeriod.show = true;
                            "
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </div>
          </v-card>
        </v-col>
        <v-col cols="7" sm="9">
          <v-card
            dark
            color="rgba(255,255,255,0.1)"
            class="fill-height d-flex flex-column"
          >
            <v-card-title>
              <span>时间</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="timeAdd"><v-icon>mdi-plus</v-icon></v-btn>
            </v-card-title>
            <div class="scroll-panel">
              <v-data-table
                :headers="headers"
                :items="times"
                class="scroll-content"
                style="background-color: transparent"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:[`item.week`]="{ item }">
                  <span v-if="item.week == 1">星期一</span>
                  <span v-if="item.week == 2">星期二</span>
                  <span v-if="item.week == 3">星期三</span>
                  <span v-if="item.week == 4">星期四</span>
                  <span v-if="item.week == 5">星期五</span>
                  <span v-if="item.week == 6">星期六</span>
                  <span v-if="item.week == 7">星期天</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn icon @click="timeEdit(item)">
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click="
                      deleteTime.model = item;
                      deleteTime.show = true;
                    "
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-confirm
      v-model="deletePeriod.show"
      label="是否删除时段?"
      @ok="periodDelete"
    ></v-confirm>
    <v-confirm
      v-model="deleteTime.show"
      label="是否删除时间?"
      @ok="timeDelete"
    ></v-confirm>
    <v-dialog v-model="editPeriod.show" persistent width="360">
      <v-card v-if="editPeriod.model">
        <v-card-title>
          {{ editPeriod.model.id ? "编辑" : "添加" }}时段
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="名称"
            v-model="editPeriod.model.name"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="periodSubmit">确定</v-btn>
          <v-btn text @click="editPeriod.show = false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editTime.show" persistent width="360">
      <v-card v-if="editTime.model">
        <v-card-title>
          {{ editTime.model.id ? "编辑" : "添加" }}时间
        </v-card-title>
        <v-card-text>
          <v-select
            label="星期"
            v-model="editTime.model.week"
            :items="weeks"
          ></v-select>
          <v-menu
            v-model="begintimeShow"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="editTime.model.begintime"
                label="时间"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="editTime.model.begintime"
              format="24hr"
              use-seconds
            ></v-time-picker>
          </v-menu>
          <v-menu
            v-model="endtimeShow"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="editTime.model.endtime"
                label="时间"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="editTime.model.endtime"
              format="24hr"
              use-seconds
            ></v-time-picker>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="timeSubmit">确定</v-btn>
          <v-btn text @click="editTime.show = false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
import uuid from "../utils/uuid";
export default {
  data() {
    return {
      headers: [
        { text: "星期", value: "week" },
        { text: "开始时间", value: "begintime" },
        { text: "结束时间", value: "endtime" },
        { text: "操作", value: "actions", sortable: false, align: "right" },
      ],
      period: undefined,
      periods: [],
      times: [],

      editPeriod: {
        show: false,
        model: null,
      },

      deletePeriod: {
        show: false,
        model: null,
      },

      editTime: {
        show: false,
        model: null,
      },

      deleteTime: {
        show: false,
        model: null,
      },

      weeks: [
        { text: "星期一", value: 1 },
        { text: "星期二", value: 2 },
        { text: "星期三", value: 3 },
        { text: "星期四", value: 4 },
        { text: "星期五", value: 5 },
        { text: "星期六", value: 6 },
        { text: "星期天", value: 7 },
      ],
      begintimeShow: false,
      endtimeShow: false,
    };
  },
  mounted() {
    client.$on("orgChange", this.getPeriods);
    this.getPeriods();
  },
  beforeDestroy() {
    client.$off("orgChange", this.getPeriods);
  },
  methods: {
    async getPeriods() {
      let result = [];
      client.$emit(
        "startBusy",
        "ConfigPeriod.getPeriods",
        "正在获取时段,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.periodMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
        });
        if (res.periods && res.periods.length) {
          res.periods.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.periods.length; i++) {
            let period = res.periods[i];
            result.push(period);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigPeriod.getPeriods");
      this.periods = result;
    },
    async getTimes() {
      let result = [];
      if (this.period !== undefined) {
        client.$emit(
          "startBusy",
          "ConfigPeriod.getTimes",
          "正在获取时间,请稍候..."
        );
        await proto.sleep(100);
        try {
          let res = await client.send(
            proto.MESSAGE_TYPE.periodsItemMessage,
            {
              mcd: {
                operate: proto.OperateMode.retrieveOpt,
                range: "0",
                parentId: this.periods[this.period].id,
              },
            }
          );
          if (res.items && res.items.length) {
            res.items.sort((a, b) => {
              return a.week - b.week;
            });
            for (let i = 0; i < res.items.length; i++) {
              let item = res.items[i];
              result.push(item);
            }
          }
        } catch (error) {
          client.$emit("toast", error);
        }
        client.$emit("endBusy", "ConfigPeriod.getTimes");
      }
      this.times = result;
    },
    periodAdd() {
      this.editPeriod.model = {
        id: undefined,
        name: "时段",
        description: "",
        bexclude: false,
        orgid: client.org.id,
      };
      this.editPeriod.show = true;
    },
    periodEdit(item) {
      this.editPeriod.model = JSON.parse(JSON.stringify(item));
      this.editPeriod.show = true;
    },
    async periodSubmit() {
      let model = JSON.parse(JSON.stringify(this.editPeriod.model));
      let operate;
      if (model.id) {
        operate = proto.OperateMode.updateOpt;
      } else {
        operate = proto.OperateMode.createOpt;
        model.id = uuid();
      }
      client.$emit(
        "startBusy",
        "ConfigPeriod.periodSubmit",
        "正在提交时段,请稍候..."
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.periodMessage, {
          mcd: {
            operate,
          },
          periods: [model],
        });
        this.getPeriods();
        this.editPeriod.show = false;
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigPeriod.periodSubmit");
    },
    async periodDelete() {
      client.$emit(
        "startBusy",
        "ConfigPeriod.periodDelete",
        "正在删除时段,请稍候..."
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.periodMessage, {
          mcd: {
            operate: proto.OperateMode.deleteOpt,
            range: "-1",
          },
          periods: [this.deletePeriod.model],
        });
        this.getPeriods();
        this.deletePeriod.show = false;
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigPeriod.periodDelete");
    },
    timeAdd() {
      let period = this.periods[this.period];
      if (!period) return;
      this.editTime.model = {
        id: undefined,
        begintime: "00:00:00",
        endtime: "23:59:59",
        week: 1,
        groupid: period.id,
      };
      this.editTime.show = true;
    },
    timeEdit(item) {
      this.editTime.model = JSON.parse(JSON.stringify(item));
      this.editTime.show = true;
    },
    async timeSubmit() {
      let model = JSON.parse(JSON.stringify(this.editTime.model));
      let items = [];
      if (model.id) {
        for (let i in this.times) {
          let time = this.times[i];
          if (time.id == model.id) continue;
          items.push(time);
        }
        items.push(model);
      } else {
        model.id = uuid();
        for (let i in this.times) {
          let time = this.times[i];
          items.push(time);
        }
        items.push(model);
      }
      client.$emit(
        "startBusy",
        "ConfigPeriod.timeSubmit",
        "正在提交时间,请稍候..."
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.periodsItemMessage, {
          mcd: {
            operate: proto.OperateMode.updateOpt,
            parentId: model.groupid,
          },
          items,
        });
        this.getTimes();
        this.editTime.show = false;
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigPeriod.timeSubmit");
    },
    async timeDelete() {
      let items = [];
      for (let i in this.times) {
        let time = this.times[i];
        if (time.id == this.deleteTime.model.id) continue;
        items.push(time);
      }
      client.$emit(
        "startBusy",
        "ConfigPeriod.timeDelete",
        "正在删除时间,请稍候..."
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.periodsItemMessage, {
          mcd: {
            operate: proto.OperateMode.updateOpt,
            parentId: this.deleteTime.model.groupid,
          },
          items,
        });
        this.getTimes();
        this.deleteTime.show = false;
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigPeriod.timeDelete");
    },
  },
};
</script>
