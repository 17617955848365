var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('v-navbar',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.breadcrumbs.length > 1),expression:"breadcrumbs.length > 1"}],attrs:{"icon":""},on:{"click":_vm.backword}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left-bold-circle-outline")])],1)]}}])},[_c('span',[_vm._v("返回上一级")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.unConfirmAlarmShow = true}}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"value":_vm.unConfirmAlarmCount,"content":_vm.unConfirmAlarmCount,"color":"error","overlap":""}},[_c('v-icon',[_vm._v("mdi-alert")])],1)],1)]}}])},[_c('span',[_vm._v("未确认告警")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.alarmDetailShow = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted-triangle")])],1)]}}])},[_c('span',[_vm._v("告警列表")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.audioSwitch}},'v-btn',attrs,false),on),[(_vm.speakEnable)?_c('v-icon',[_vm._v("mdi-account-voice")]):_c('v-icon',[_vm._v("mdi-voice-off")])],1)]}}])},[(_vm.speakEnable)?_c('span',[_vm._v("关闭AI语音播报")]):_c('span',[_vm._v("开启AI语音播报")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showAlarm = !_vm.showAlarm}}},'v-btn',attrs,false),on),[(_vm.showAlarm)?_c('v-icon',[_vm._v("mdi-playlist-check")]):_c('v-icon',[_vm._v("mdi-playlist-remove")])],1)]}}])},[(_vm.showAlarm)?_c('span',[_vm._v("关闭告警字幕")]):_c('span',[_vm._v("开启告警字幕")])]),_c('v-text-field',{staticStyle:{"max-width":"150px"},attrs:{"label":"搜索","hide-details":"","dense":"","outlined":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-breadcrumbs',{staticClass:"pb-0 px-0",attrs:{"dark":"","items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{staticClass:"white--text",domProps:{"textContent":_vm._s(item.name)}})]}}])}),_c('div',{staticClass:"scroll"},[_c('v-data-table',{staticStyle:{"background-color":"transparent"},attrs:{"headers":_vm.headers,"items":_vm.matchItems,"dark":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.modelType != 'spot')?_c('a',{staticStyle:{"color":"#FFF"},on:{"click":function($event){return _vm.view(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.modelType",fn:function(ref){
var item = ref.item;
return [(item.modelType == 'agent')?_c('span',[_vm._v("监控主机")]):_vm._e(),(item.modelType == 'device')?_c('span',[_vm._v("设备")]):_vm._e(),(item.modelType == 'spot')?_c('span',[_vm._v("测点")]):_vm._e()]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [(item.modelType == 'agent')?_c('span',[_vm._v(_vm._s(item.ip))]):_vm._e(),(item.modelType == 'device')?_c('span',[_vm._v(" "+_vm._s(((item.ipaddr) + ":" + (item.ipport)))+" ")]):_vm._e()]}},{key:"item.stateType",fn:function(ref){
var item = ref.item;
return [(item.stateType == 0)?_c('span',{staticClass:"offline--text"},[_vm._v("离线")]):_vm._e(),(item.stateType == 1)?_c('span',{staticClass:"online--text"},[_vm._v("在线")]):_vm._e(),(item.stateType == 2)?_c('span',{staticClass:"offline--text"},[_vm._v("禁用")]):_vm._e()]}},{key:"item.alarmType",fn:function(ref){
var item = ref.item;
return [(item.alarmType == 1)?_c('span',{staticClass:"error--text"},[_vm._v(" 超上限告警 ")]):_vm._e(),(item.alarmType == 2)?_c('span',{staticClass:"error--text"},[_vm._v(" 超下限告警 ")]):_vm._e(),(item.alarmType == 3)?_c('span',{staticClass:"error--text"},[_vm._v(" 临上限告警 ")]):_vm._e(),(item.alarmType == 4)?_c('span',{staticClass:"alarm--text"},[_vm._v(" 临下限告警 ")]):_vm._e(),(item.alarmType == 5)?_c('span',{staticClass:"alarm--text"},[_vm._v(" 数据异常告警 ")]):_vm._e(),(item.alarmType == 6)?_c('span',{staticClass:"alarm--text"},[_vm._v(" 状态异常告警 ")]):_vm._e(),(item.alarmType == 7)?_c('span',{staticClass:"reset--text"},[_vm._v("正常")]):_vm._e(),(item.alarmType == 8)?_c('span',{staticClass:"reset--text"},[_vm._v("正常")]):_vm._e(),(item.alarmType == 9)?_c('span',{staticClass:"alarm--text"},[_vm._v("脱机告警")]):_vm._e(),(item.alarmType == 10)?_c('span',{staticClass:"alarm--text"},[_vm._v("文本告警")]):_vm._e(),(item.alarmType == 11)?_c('span',{staticClass:"alarm--text"},[_vm._v(" 极上限告警 ")]):_vm._e(),(item.alarmType == 12)?_c('span',{staticClass:"alarm--text"},[_vm._v(" 极下限告警 ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.modelType != 'spot')?_c('v-btn',{staticClass:"link-text",attrs:{"text":""},on:{"click":function($event){return _vm.view(item)}}},[_c('span',[_vm._v("查看")])]):_vm._e(),(item.modelType == 'spot' && item.rw == 2)?_c('v-btn',{staticClass:"link-text",attrs:{"text":""},on:{"click":function($event){return _vm.control(item)}}},[_c('span',[_vm._v("控制")])]):_vm._e()]}}],null,true)})],1),_c('SpotControlDialog',{attrs:{"uuid":_vm.controlId,"name":_vm.controlName},on:{"submit":_vm.spotControl},model:{value:(_vm.controlShow),callback:function ($$v) {_vm.controlShow=$$v},expression:"controlShow"}}),_c('AlarmDetailDialog',{model:{value:(_vm.alarmDetailShow),callback:function ($$v) {_vm.alarmDetailShow=$$v},expression:"alarmDetailShow"}}),_c('UnConfirmAlarmDialog',{model:{value:(_vm.unConfirmAlarmShow),callback:function ($$v) {_vm.unConfirmAlarmShow=$$v},expression:"unConfirmAlarmShow"}}),_c('VoiceDialog',{on:{"voice":_vm.audioStart},model:{value:(_vm.voiceShow),callback:function ($$v) {_vm.voiceShow=$$v},expression:"voiceShow"}}),_c('audio',{ref:"audio",staticStyle:{"display":"none"},on:{"ended":_vm.audioEnd,"error":_vm.audioError}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }