<template>
  <v-text-field
    :label="label"
    :value="valueDisplay"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>
            <div>{{ label }}编辑</div>
            <v-spacer></v-spacer>
            <v-btn icon @click="add">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-data-table :headers="headers" :items="items">
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                @click="
                  editModel = item;
                  editShow = true;
                "
              >
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-btn>
              <v-btn icon @click="remove(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editShow" width="480" persistent>
        <v-card>
          <v-card-title>
            <div>时间编辑</div>
            <v-spacer></v-spacer>
            <v-btn icon @click="editShow = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text v-if="editModel">
            <v-select
              label="星期"
              v-model="editModel.weekday"
              :items="weekdays"
            ></v-select>
            <v-select
              label="时"
              v-model="editModel.hour"
              :items="hours"
            ></v-select>
            <v-select
              label="分"
              v-model="editModel.minute"
              :items="minutes"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="editShow = false">确定</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      show: false,

      headers: [
        { text: "星期", value: "weekday" },
        { text: "时", value: "hour" },
        { text: "分", value: "minute" },
        { text: "操作", value: "actions", align: "end" },
      ],
      items: [],

      weekdays: [],
      hours: [],
      minutes: [],

      editModel: null,
      editShow: false,
    };
  },
  computed: {
    valueDisplay() {
      if (this.value && this.value.length) return "已配置";
      return "未配置";
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          let list = this.value;
          this.items = JSON.parse(JSON.stringify(list));
        }
      },
    },
  },
  mounted() {
    for (let i = 1; i <= 7; i++) {
      this.weekdays.push(i);
    }
    for (let i = 0; i <= 23; i++) {
      this.hours.push(i);
    }
    for (let i = 0; i <= 59; i++) {
      this.minutes.push(i);
    }
  },
  methods: {
    add() {
      let model = { weekday: 1, hour: 0, minute: 0 };
      this.items.push(model);
      this.editModel = model;
      this.editShow = true;
    },
    remove(item) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i] == item) {
          this.items.splice(i, 1);
        }
      }
    },
    submit() {
      this.$emit("change", this.items);
      this.show = false;
    },
  },
};
</script>
