<template>
  <v-dialog v-model="show" persistent max-width="480">
    <v-card>
      <v-card-title>添加页面</v-card-title>
      <v-card-text>
        <v-text-field label="页面名称" v-model="name"></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain @click="ok">确定</v-btn>
        <v-btn plain @click="cancel">取消</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import client from "../utils/client";
export default {
  data() {
    return {
      show: false,
      name: "",
    };
  },
  mounted() {
    this.show = true;
  },
  methods: {
    ok() {
      if (!this.name) {
        client.$emit("toast", "请输入名称");
        return;
      }
      this.show = false;
      this.$emit("close", this.name);
    },
    cancel() {
      this.show = false;
      this.$emit("close", null);
    },
  },
};
</script>
