<template>
  <div class="main">
    <v-navbar>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="parent.modelType != 'org'"
            icon
            v-bind="attrs"
            v-on="on"
            @click="backword"
          >
            <v-icon>mdi-arrow-left-bold-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>返回上一级</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="parent.modelType == 'org'"
            icon
            v-bind="attrs"
            v-on="on"
            @click="addAgentShow = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>添加监控主机</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="parent.modelType == 'port'"
            icon
            v-bind="attrs"
            v-on="on"
            @click="addDeviceShow = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>添加设备</span>
      </v-tooltip>
      <v-text-field
        v-model="filter"
        label="搜索"
        hide-details
        dense
        outlined
        prepend-inner-icon="mdi-magnify"
        style="max-width: 150px"
      ></v-text-field>
    </v-navbar>
    <v-breadcrumbs dark class="pb-0 px-0" :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          class="white--text"
          v-text="item.name"
        ></v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <div class="scroll">
      <v-data-table
        :headers="headers"
        :items="matchItems"
        dark
        style="background-color: transparent"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.name`]="{ item }">
          <a
            v-if="item.modelType != 'spot'"
            style="color:#FFF"
            @click="view(item)"
          >
            {{ item.name }}
          </a>
          <span v-else>{{ item.name }}</span>
        </template>
        <template v-slot:[`item.modelType`]="{ item }">
          <span>{{ item.modelType == "agent" ? "监控主机" : "设备" }}</span>
        </template>
        <template v-slot:[`item.portType`]="{ item }">
          <span v-if="item.portType == 1">以太网口</span>
          <span v-if="item.portType == 2">串口</span>
          <span v-if="item.portType == 3">DI口</span>
          <span v-if="item.portType == 4">DO口</span>
          <span v-if="item.portType == 5">内部端口</span>
          <span v-if="item.portType == 6">通用端口</span>
          <span v-if="item.portType == 7">AI口</span>
        </template>
        <template v-slot:[`item.devices`]="{ item }">
          <span>{{ item.devices.length }}</span>
        </template>
        <template v-slot:[`item.sv`]="{ item }">
          <span v-if="item.sv.valueType == 1">模拟量</span>
          <span v-if="item.sv.valueType == 2">字符串</span>
          <span v-if="item.sv.valueType == 3">开关量</span>
          <span v-if="item.sv.valueType == 4">数据块</span>
          <span v-if="item.sv.valueType == 5">状态量</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            text
            class="link-text"
            v-if="item.modelType == 'agent'"
            @click="syncToCore(item)"
          >
            <span>向上同步</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            v-if="item.modelType == 'agent'"
            @click="syncToAgent(item)"
          >
            <span>向下同步</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            v-if="item.modelType == 'agent' || item.modelType == 'device'"
            @click="
              moveModel = item;
              moveShow = true;
            "
          >
            <span>迁出</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            v-if="item.modelType == 'agent' || item.modelType == 'device'"
            @click="remove(item)"
          >
            <span>删除</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            @click="
              current = item;
              editorShow = true;
            "
          >
            <span>编辑</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            v-if="item.modelType != 'spot'"
            @click="view(item)"
          >
            <span>查看</span>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-navigation-drawer
      v-model="editorShow"
      absolute
      temporary
      right
      width="240"
    >
      <div v-if="editModel" class="d-flex flex-column fill-height">
        <div class="flex-grow-0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ editModel.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                text
                class="link-text"
                @click="
                  cancel = true;
                  editorShow = false;
                "
              >
                放弃修改
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
        <v-divider></v-divider>
        <div class="flex-grow-1 bg-editor" style="overflow: auto">
          <template v-for="attr in basicEditors">
            <component
              v-if="
                editModel[attr.key] !== undefined &&
                  showEditor(editModel, attr.key)
              "
              :is="attr.editor"
              :key="`model-${attr.key}`"
              :label="attr.name"
              :options="attr.options"
              :model="editModel"
              v-model="editModel[attr.key]"
            ></component>
          </template>
          <template v-for="attr in sapEditors">
            <component
              v-if="
                editModel.sap !== undefined &&
                  editModel.sap[attr.key] !== undefined &&
                  showEditor(editModel, `sap.${attr.key}`)
              "
              :is="attr.editor"
              :key="`sap-${attr.key}`"
              :label="attr.name"
              :options="attr.options"
              :model="editModel"
              v-model="editModel.sap[attr.key]"
            ></component>
          </template>
        </div>
      </div>
    </v-navigation-drawer>
    <AddAgentDialog v-model="addAgentShow" @submit="addAgent"></AddAgentDialog>
    <AddDeviceDialog
      v-model="addDeviceShow"
      @submit="addDevice"
    ></AddDeviceDialog>
    <OrgSelector
      v-model="moveShow"
      label="请选择要迁入的机构"
      @select="changeOrg"
    ></OrgSelector>
    <v-confirm
      v-model="removeAgentShow"
      label="是否删除监控主机?"
      @ok="removeAgent"
    ></v-confirm>
    <v-confirm
      v-model="removeDeviceShow"
      label="是否删除设备?"
      @ok="removeDevice"
    ></v-confirm>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
import uuid from "../utils/uuid";
import EnumText from "../components/EnumText.vue";
import PluginEditor from "../components/PluginEditor.vue";
import PortParamEditor from "../components/PortParamEditor.vue";
import NotifierEditor from "../components/NotifierEditor.vue";
import AlarmDescEditor from "../components/AlarmDescEditor.vue";
import StateAlarmEditor from "../components/StateAlarmEditor.vue";
import AddAgentDialog from "../components/AddAgentDialog.vue";
import AddDeviceDialog from "../components/AddDeviceDialog.vue";
import OrgSelector from "../components/OrgSelector.vue";

const basicEditors = [
  {
    key: "uuid",
    name: "ID",
    editor: "zht-editor-text",
    options: { readonly: true },
  },
  {
    key: "name",
    name: "名称",
    editor: "zht-editor-text",
  },
  {
    key: "description",
    name: "描述",
    editor: "zht-editor-text",
  },
  {
    key: "commport",
    name: "挂载点",
    editor: "zht-editor-object",
    options: { readonly: true },
  },
  {
    key: "no",
    name: "编号",
    editor: "zht-editor-text",
    options: { readonly: true },
  },
  {
    key: "portType",
    name: "端口类型",
    editor: "enum-text",
    options: [
      { text: "以太网口", value: 1 },
      { text: "串口", value: 2 },
      { text: "DI", value: 3 },
      { text: "DO", value: 4 },
      { text: "内部端口", value: 5 },
      { text: "通用端口", value: 6 },
      { text: "AI", value: 7 },
    ],
  },
  {
    key: "port",
    name: "端口编号",
    editor: "zht-editor-text",
    options: { readonly: true },
  },
  {
    key: "devoverride",
    name: "倍率",
    editor: "zht-editor-text",
  },
  {
    key: "devAddr",
    name: "设备地址",
    editor: "zht-editor-text",
  },
  {
    key: "ipaddr",
    name: "IP地址",
    editor: "zht-editor-text",
  },
  {
    key: "ipport",
    name: "IP端口",
    editor: "zht-editor-text",
  },
  {
    key: "useraccount",
    name: "帐户",
    editor: "zht-editor-text",
  },
  {
    key: "password",
    name: "密码",
    editor: "zht-editor-password",
  },
  {
    key: "site",
    name: "安装位置",
    editor: "zht-editor-text",
  },
  {
    key: "period",
    name: "采集周期",
    editor: "zht-editor-text",
  },
  {
    key: "param",
    name: "端口参数",
    editor: "port-param-editor",
  },
  {
    key: "plugin",
    name: "插件",
    editor: "plugin-editor",
  },
  {
    key: "spotOverride",
    name: "倍率",
    editor: "zht-editor-text",
  },
  {
    key: "exceedValue",
    name: "溢出范围",
    editor: "zht-editor-text",
  },
  {
    key: "edr",
    name: "异常数据处理规则",
    editor: "zht-editor-enum",
    options: [
      { text: "抛弃", value: 1 },
      { text: "告警", value: 2 },
    ],
  },
  {
    key: "other",
    name: "其他属性",
    editor: "zht-editor-text",
  },
  {
    key: "persist",
    name: "数据保存",
    editor: "zht-editor-enum",
    options: [
      { text: "保存", value: true },
      { text: "不保存", value: false },
    ],
  },
  {
    key: "PartPp",
    name: "保存策略",
    editor: "zht-editor-enum",
    options: [
      { text: "全局策略", value: 0 },
      { text: "全部保存", value: 1 },
      { text: "周期性", value: 2 },
      { text: "变化率", value: 3 },
      { text: "周期性与变化率", value: 4 },
      { text: "周期性或变化率", value: 5 },
    ],
  },
  {
    key: "PartTimePeriod",
    name: "正常保存周期(秒)",
    editor: "zht-editor-text",
  },
  {
    key: "PartAlarmPeriod",
    name: "告警保存周期(秒)",
    editor: "zht-editor-text",
  },
  {
    key: "PartVarianceRatio",
    name: "变化率(%)",
    editor: "zht-editor-text",
  },
  {
    key: "isDataExtern",
    name: "数据共享",
    editor: "zht-editor-enum",
    options: [
      { text: "共享", value: true },
      { text: "不共享", value: false },
    ],
  },
  {
    key: "notifier",
    name: "作为发送器",
    editor: "notifier-editor",
  },
  {
    key: "alarm",
    name: "是否告警",
    editor: "zht-editor-enum",
    options: [
      { text: "告警", value: true },
      { text: "不告警", value: false },
    ],
  },
  {
    key: "statealarms",
    name: "状态告警配置",
    editor: "state-alarm-editor",
  },
];

const sapEditors = [
  {
    key: "normalState",
    name: "常态值",
    editor: "zht-editor-enum",
    options: [
      { text: "true", value: true },
      { text: "false", value: false },
    ],
  },
  {
    key: "limitway",
    name: "告警限级",
    editor: "zht-editor-enum",
    options: [
      { text: "默认", value: 0 },
      { text: "一限级", value: 1 },
      { text: "二限级", value: 2 },
      { text: "三限级", value: 3 },
    ],
  },
  {
    key: "ultraHilimit",
    name: "极上限",
    editor: "zht-editor-text",
  },
  {
    key: "upperlimit",
    name: "上限",
    editor: "zht-editor-text",
  },
  {
    key: "nearupperlimit",
    name: "临上限",
    editor: "zht-editor-text",
  },
  {
    key: "nearlowerlimit",
    name: "临下限",
    editor: "zht-editor-text",
  },
  {
    key: "lowerlimit",
    name: "下限",
    editor: "zht-editor-text",
  },
  {
    key: "ultraLowlimit",
    name: "极下限",
    editor: "zht-editor-text",
  },
  {
    key: "alarmLevel",
    name: "告警级别",
    editor: "zht-editor-text",
  },
  {
    key: "ultraLevel",
    name: "极上下限告警级别",
    editor: "zht-editor-text",
  },
  {
    key: "ulAlarmLevel",
    name: "告警级别",
    editor: "zht-editor-text",
  },
  {
    key: "nulAlarmLevel",
    name: "临上下限告警级别",
    editor: "zht-editor-text",
  },
  {
    key: "alarmdesc",
    name: "告警描述",
    editor: "alarm-desc-editor",
  },
  {
    key: "alarmdelayway",
    name: "滞回方式",
    editor: "zht-editor-enum",
    options: [
      { text: "默认", value: 0 },
      { text: "突变过滤", value: 1 },
      { text: "禁止重复", value: 2 },
    ],
  },
  {
    key: "hysteresis",
    name: "滞回时间",
    editor: "zht-editor-text",
  },
  {
    key: "valueHys",
    name: "滞回值",
    editor: "zht-editor-text",
  },
  {
    key: "periodsid",
    name: "告警时段",
    editor: "zht-editor-object",
    options: {
      type: "period",
    },
  },
  {
    key: "notifier",
    name: "告警发送器",
    editor: "zht-editor-object",
    options: {
      type: "notifier",
    },
  },
  {
    key: "receiver",
    name: "接警组",
    editor: "zht-editor-object",
    options: {
      type: "receiver",
    },
  },
];

export default {
  components: {
    EnumText,
    PluginEditor,
    PortParamEditor,
    NotifierEditor,
    AlarmDescEditor,
    StateAlarmEditor,
    AddAgentDialog,
    AddDeviceDialog,
    OrgSelector,
  },
  data() {
    return {
      filter: "",
      items: [],
      current: null,
      parent: { uuid: "", modelType: "org" },
      breadcrumbs: [],
      basicEditors,
      sapEditors,
      editorShow: false,
      editModel: null,
      cancel: false,
      addAgentShow: false,
      removeAgentShow: false,
      removeAgentModel: null,
      addDeviceShow: false,
      removeDeviceShow: false,
      removeDeviceModel: null,
      moveShow: false,
      moveModel: {},
    };
  },
  computed: {
    matchItems() {
      if (this.filter) {
        return this.items.filter(this.matches);
      } else {
        return this.items;
      }
    },
    headers() {
      let result = [];
      let type = this.parent.modelType;
      result.push({ text: "名称", value: "name" });
      switch (type) {
        case "org":
          result.push({ text: "类型", value: "modelType" });
          result.push({ text: "编号", value: "no" });
          break;
        case "agent":
          result.push({ text: "编号", value: "portno" });
          result.push({ text: "类型", value: "portType" });
          result.push({ text: "设备数", value: "devices", sortable: false });
          break;
        case "port":
          result.push({ text: "设备地址", value: "devAddr" });
          result.push({ text: "IP地址", value: "ipaddr" });
          result.push({ text: "IP端口", value: "ipport" });
          break;
        case "device":
          result.push({ text: "编号", value: "no" });
          result.push({ text: "类型", value: "sv", sortable: false });
          break;
      }
      result.push({
        text: "操作",
        value: "actions",
        sortable: false,
        align: "end",
      });
      return result;
    },
  },
  watch: {
    editorShow: {
      handler(val) {
        if (val) {
          this.editModel = JSON.parse(JSON.stringify(this.current));
          this.cancel = false;
        } else if (!this.cancel) {
          let newVal = JSON.stringify(this.editModel);
          let oldVal = JSON.stringify(this.current);
          if (newVal != oldVal) {
            this.save();
          }
        }
      },
    },
  },
  mounted() {
    client.$on("orgChange", this.orgChange);
    client.$on("brocast", this.brocast);
    this.orgChange();
  },
  beforeDestroy() {
    client.$off("orgChange", this.orgChange);
    client.$off("brocast", this.brocast);
  },
  methods: {
    showEditor(current, attr) {
      switch (attr) {
        //三限级
        case "sap.ultraHilimit":
        case "sap.ultraLowlimit":
        case "sap.ultraLevel":
          return (
            current.modelType == "spot" &&
            current.sv.valueType == 1 &&
            current.sap.limitway >= 3
          );
        case "sap.nearupperlimit":
        case "sap.nearlowerlimit":
        case "sap.nulAlarmLevel":
          return (
            current.modelType == "spot" &&
            current.sv.valueType == 1 &&
            current.sap.limitway >= 2
          );
        //数据保存
        case "PartPp":
          return current.persist;
        case "PartTimePeriod":
        case "PartAlarmPeriod":
          return (
            current.persist &&
            (current.PartPp == 2 || current.PartPp == 4 || current.PartPp == 5)
          );
        case "PartVarianceRatio":
          return (
            current.persist &&
            (current.PartPp == 3 || current.PartPp == 4 || current.PartPp == 5)
          );
        //模拟量
        case "sap.limitway":
        case "sap.upperlimit":
        case "sap.lowerlimit":
        case "sap.valueHys":
        case "spotOverride":
        case "exceedValue":
        case "edr":
          return current.modelType == "spot" && current.sv.valueType == 1;
        //开关量
        case "sap.normalState":
          return current.modelType == "spot" && current.sv.valueType == 3;
        //状态量
        case "statealarms":
          return current.modelType == "spot" && current.sv.valueType == 5;
        //告警开关和通知
        case "alarm":
        case "sap.periodsid":
        case "sap.alarmdelayway":
        case "sap.notifier":
        case "sap.receiver":
          return !(
            current.modelType == "spot" &&
            (current.sv.valueType == 2 || current.sv.valueType == 4)
          );
        //告警级别
        case "sap.alarmLevel":
          return current.modelType == "agent" || current.modelType == "device";
        case "sap.ulAlarmLevel":
          return (
            current.modelType == "spot" &&
            (current.sv.valueType == 1 ||
              current.sv.valueType == 3 ||
              current.sv.valueType == 5)
          );
        //端口参数
        case "param":
          return (
            current.modelType == "port" &&
            (current.portType == 1 || current.portType == 2)
          );
        //告警描述
        case "sap.alarmdesc":
          return (
            current.modelType == "agent" ||
            current.modelType == "device" ||
            (current.modelType == "spot" &&
              (current.sv.valueType == 1 || current.sv.valueType == 3))
          );
        //告警描述
        case "sap.hysteresis":
          return current.sap && current.sap.alarmdelayway != 0;
      }
      return true;
    },
    matches(item) {
      return item.name.indexOf(this.filter) != -1;
    },
    icon(item) {
      switch (item.modelType) {
        case "agent":
          return "mdi-server";
        case "port":
          if (item.portType == 1) {
            return "mdi-access-point-network";
          } else {
            return "mdi-video-input-component";
          }
        case "device":
          return "mdi-devices";
        case "spot":
          return "mdi-alarm-light";
      }
      return "";
    },
    async orgChange() {
      this.breadcrumbs = [];
      await this.view({
        uuid: "org",
        name: "",
        modelType: "org",
      });
    },
    async view(item) {
      if (!item) return;
      this.items = [];
      this.filter = "";
      let exist;
      for (let i in this.breadcrumbs) {
        let breadcrumb = this.breadcrumbs[i];
        if (breadcrumb.uuid == item.uuid) {
          exist = breadcrumb;
          this.breadcrumbs = this.breadcrumbs.slice(0, parseInt(i) + 1);
          break;
        }
      }
      if (!exist) {
        exist = item;
        this.breadcrumbs.push(item);
      }
      this.parent = item;
      await this.getAgents(item);
      await this.getPorts(item);
      await this.getDevices(item);
      await this.getSpots(item);
    },
    async getAgents(item) {
      if (item.modelType != "org") return;
      client.$emit(
        "startBusy",
        "ConfigDevice.getAgents",
        "正在获取监控主机,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.agentMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
          },
        });
        if (res.agents && res.agents.length) {
          res.agents.sort((a, b) => {
            return a.no > b.no ? 1 : -1;
          });
          for (let i = 0; i < res.agents.length; i++) {
            let agent = res.agents[i];
            agent.modelType = "agent";
            this.items.push(agent);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.getAgents");
    },
    async getPorts(item) {
      if (item.modelType != "agent") return;
      client.$emit(
        "startBusy",
        "ConfigDevice.getPorts",
        "正在获取端口,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.commPortMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
            parentId: item.uuid,
          },
        });
        if (res.commPorts && res.commPorts.length) {
          res.commPorts.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.commPorts.length; i++) {
            let port = res.commPorts[i];
            port.modelType = "port";
            this.items.push(port);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.getPorts");
    },
    async getDevices(item) {
      if (item.modelType != "org" && item.modelType != "port") return;
      client.$emit(
        "startBusy",
        "ConfigDevice.getDevices",
        "正在获取设备,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.deviceMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
            parentId: item.modelType == "org" ? "" : item.uuid,
          },
          act: item.modelType == "org" ? 2 : 0,
        });
        if (res.devices && res.devices.length) {
          res.devices.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.devices.length; i++) {
            let device = res.devices[i];
            device.modelType = "device";
            this.items.push(device);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.getDevices");
    },
    async getSpots(item) {
      if (item.modelType != "device") return;
      client.$emit(
        "startBusy",
        "ConfigDevice.getSpots",
        "正在获取测点,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.spotMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
            parentId: item.uuid,
          },
          act: 0,
        });
        if (res.spots && res.spots.length) {
          res.spots.sort((a, b) => {
            return a.no > b.no ? 1 : -1;
          });
          for (let i = 0; i < res.spots.length; i++) {
            let spot = res.spots[i];
            spot.modelType = "spot";
            this.items.push(spot);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.getSpots");
    },
    async save() {
      client.$emit("startBusy", "ConfigDevice.save", "正在保存配置,请稍候...");
      await proto.sleep(100);
      try {
        let type;
        let msg = {
          mcd: {
            operate: proto.OperateMode.modifyOpt,
          },
        };
        switch (this.editModel.modelType) {
          case "agent":
            type = proto.MESSAGE_TYPE.agentMessage;
            msg.agents = [this.editModel];
            break;
          case "port":
            type = proto.MESSAGE_TYPE.commPortMessage;
            msg.commPorts = [this.editModel];
            break;
          case "device":
            type = proto.MESSAGE_TYPE.deviceMessage;
            msg.devices = [this.editModel];
            msg.act = 0;
            break;
          case "spot":
            type = proto.MESSAGE_TYPE.spotMessage;
            msg.spots = [this.editModel];
            msg.act = 0;
            break;
          default:
            throw "对象模型不正确";
        }
        await client.send(type, msg);
        this.view(this.parent);
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.save");
    },
    backword() {
      let len = this.breadcrumbs.length;
      if (len > 1) {
        this.view(this.breadcrumbs[len - 2]);
      }
    },
    brocast(type, msg) {
      if (type == proto.MESSAGE_TYPE.progressMessage) {
        let eventName =
          msg.currentStep < msg.totalSteps ? "startBusy" : "endBusy";
        client.$emit(eventName, msg.uuid, msg.description);
        if (msg.currentStep == msg.totalSteps) {
          client.$emit(
            "toast",
            msg.description,
            msg.optResult ? "success" : "error"
          );
        }
      }
    },
    async syncToAgent(agent) {
      client.$emit(
        "startBusy",
        "ConfigDevice.syncToAgent",
        "正在保存配置,请稍候..."
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.agentMessage, {
          mcd: {
            operate: proto.OperateMode.other,
            range: agent.uuid,
          },
          act: 1,
        });
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.syncToAgent");
    },
    async syncToCore(agent) {
      client.$emit(
        "startBusy",
        "ConfigDevice.syncToCore",
        "正在保存配置,请稍候..."
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.agentMessage, {
          mcd: {
            operate: proto.OperateMode.other,
            range: agent.uuid,
          },
          act: 2,
        });
        this.view(this.parent);
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.syncToCore");
    },
    remove(item) {
      if (item.modelType == "agent") {
        this.removeAgentModel = item;
        this.removeAgentShow = true;
      }
      if (item.modelType == "device") {
        this.removeDeviceModel = item;
        this.removeDeviceShow = true;
      }
    },
    async addAgent(agent) {
      client.$emit(
        "startBusy",
        "ConfigDevice.addAgent",
        "正在添加监控主机,请稍候..."
      );
      await proto.sleep(100);
      try {
        agent.aeid = agent.uuid;
        agent.uuid = uuid();
        agent.orgid = client.org.id;
        await client.send(proto.MESSAGE_TYPE.agentMessage, {
          mcd: {
            operate: proto.OperateMode.addOpt,
            parentId: client.project.id,
          },
          agents: [agent],
        });
        this.view(this.parent);
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.addAgent");
    },
    async removeAgent() {
      if (this.removeAgentModel) {
        client.$emit(
          "startBusy",
          "ConfigDevice.removeAgent",
          "正在删除监控主机,请稍候..."
        );
        await proto.sleep(100);
        try {
          await client.send(proto.MESSAGE_TYPE.agentMessage, {
            mcd: {
              operate: proto.OperateMode.removeOpt,
              range: "-1",
            },
            agents: [this.removeAgentModel],
          });
          this.view(this.parent);
        } catch (error) {
          client.$emit("toast", error);
        }
        client.$emit("endBusy", "ConfigDevice.removeAgent");
      }
    },
    async addDevice(device) {
      client.$emit(
        "startBusy",
        "ConfigDevice.addDevice",
        "正在添加设备,请稍候..."
      );
      await proto.sleep(100);
      try {
        device.deid = device.uuid;
        device.uuid = uuid();
        device.commport = { id: this.parent.uuid };
        device.organization = { id: client.org.id };
        await client.send(proto.MESSAGE_TYPE.deviceMessage, {
          mcd: {
            operate: proto.OperateMode.addOpt,
            parentId: this.parent.uuid,
          },
          act: 0,
          devices: [device],
        });
        this.view(this.parent);
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.addDevice");
    },
    async removeDevice() {
      if (this.removeDeviceModel) {
        client.$emit(
          "startBusy",
          "ConfigDevice.removeDevice",
          "正在删除设备,请稍候..."
        );
        await proto.sleep(100);
        try {
          await client.send(proto.MESSAGE_TYPE.deviceMessage, {
            mcd: {
              operate: proto.OperateMode.removeOpt,
              range: "-1",
            },
            act: 0,
            devices: [this.removeDeviceModel],
          });
          this.view(this.parent);
        } catch (error) {
          client.$emit("toast", error);
        }
        client.$emit("endBusy", "ConfigDevice.removeDevice");
      }
    },
    async changeOrg(org) {
      client.$emit(
        "startBusy",
        "ConfigDevice.changeOrg",
        "正在迁移对象,请稍候..."
      );
      await proto.sleep(100);
      try {
        if (!org || !org.id) throw "机构信息有误";
        if (!this.moveModel) throw "用户信息缺失";
        let member = {
          msgType:
            this.moveModel.modelType == "agent"
              ? proto.MESSAGE_TYPE.agentMessage
              : proto.MESSAGE_TYPE.deviceMessage,
          id: this.moveModel.uuid,
          targetId: org.id,
        };
        await client.send(proto.MESSAGE_TYPE.orgMemberMessage, {
          mcd: {
            operate: proto.OperateMode.updateOpt,
          },
          members: [member],
        });
        this.view(this.parent);
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.changeOrg");
    },
  },
};
</script>
