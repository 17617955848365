export default {
    "uuid": { name: "ID", editor: "zht-editor-text", options: { readonly: true } },
    "type": { name: "类型", editor: "zht-editor-text", options: { readonly: true } },
    "name": { name: "名称", editor: "zht-editor-text" },
    "spotName": { name: "测点名称", editor: "zht-editor-text" },

    "location.x": { name: "水平坐标", editor: "zht-editor-text" },
    "location.y": { name: "垂直坐标", editor: "zht-editor-text" },

    "size.width": { name: "宽度", editor: "zht-editor-text" },
    "size.height": { name: "高度", editor: "zht-editor-text" },

    "style.opacity": { name: "透明度", editor: "zht-editor-text" },
    "style.box-shadow": { name: "阴影", editor: "zht-editor-shadow" },
    "style.transform": { name: "变换", editor: "zht-editor-transform" },

    "style.padding": { name: "内边距", editor: "zht-editor-text" },
    "style.margin": { name: "外边距", editor: "zht-editor-text" },

    "style.border-style": {
        name: "边框样式",
        editor: "zht-editor-enum",
        options: [
            { text: "实线", value: "solid" },
            { text: "虚线", value: "dashed" },
            { text: "点状", value: "dotted" },
            { text: "双线", value: "double" },
        ],
    },
    "style.border-width": { name: "边框大小", editor: "zht-editor-text" },
    "style.border-color": { name: "边框颜色", editor: "zht-editor-color" },
    "style.border-radius": { name: "边框圆角", editor: "zht-editor-text" },

    "style.color": { name: "前景色", editor: "zht-editor-color" },
    "style.background-color": { name: "背景色", editor: "zht-editor-color" },
    "style.background-image": { name: "背景图", editor: "zht-editor-image" },
    "style.background-repeat": {
        name: "背景重复",
        editor: "zht-editor-enum",
        options: [
            { text: "不重复", value: "no-repeat" },
            { text: "水平重复", value: "repeat-x" },
            { text: "垂直重复", value: "repeat-y" },
            { text: "重复", value: "repeat" },
        ],
    },
    "style.background-position": {
        name: "背景位置",
        editor: "zht-editor-enum",
        options: [
            { text: "居中", value: "center center" },
            { text: "上", value: "top center" },
            { text: "下", value: "bottom center" },
            { text: "左", value: "center left" },
            { text: "右", value: "center right" },
        ],
    },
    "style.background-size": {
        name: "背景尺寸",
        editor: "zht-editor-enum",
        options: [
            { text: "原图", value: "auto" },
            { text: "拉伸", value: "100% 100%" },
            { text: "包含", value: "contain" },
            { text: "覆盖", value: "cover" },
        ],
    },

    "style.text-align": {
        name: "文本对齐",
        editor: "zht-editor-enum",
        options: [
            { text: "左对齐", value: "left" },
            { text: "右对齐", value: "right" },
            { text: "居中", value: "center" },
            { text: "两端对齐", value: "justify" },
        ],
    },

    "style.font-style": {
        name: "字体样式",
        editor: "zht-editor-enum",
        options: [
            { text: "默认", value: "normal" },
            { text: "斜体", value: "italic" },
            { text: "倾斜", value: "oblique" },
        ],
    },
    "style.font-size": { name: "字体大小", editor: "zht-editor-text" },
    "style.font-weight": {
        name: "字体粗细",
        editor: "zht-editor-enum",
        options: [
            { text: "默认", value: "normal" },
            { text: "粗体", value: "bold" },
            { text: "加粗", value: "bolder" },
            { text: "加细", value: "lighter" },
        ],
    },
    "style.animation-name": {
        name: "动画特效",
        editor: "zht-editor-enum",
        options: [
            { text: "无", value: "" },
            { text: "路径动画", value: "path-animate" },
            { text: "顺时针旋转", value: "spin" },
            { text: "逆时针旋转", value: "spin-reverse" },
            { text: "闪烁1", value: "twinkle" },
            { text: "闪烁2", value: "twinkle-reverse" },
            { text: "水平晃动", value: "shake-horizon" },
            { text: "垂直晃动", value: "shake-vertical" },
            { text: "左移", value: "move-left" },
            { text: "右移", value: "move-right" },
            { text: "上移", value: "move-top" },
            { text: "下移", value: "move-bottom" },
            { text: "左上移", value: "move-left-top" },
            { text: "右上移", value: "move-right-top" },
            { text: "右下移", value: "move-right-bottom" },
            { text: "左下移", value: "move-left-bottom" },
        ],
    },
    "style.offset-path": { name: "动画路径", editor: "zht-editor-textarea" },
    "style.animation-duration": { name: "动画时长", editor: "zht-editor-text" },
    "style.animation-delay": { name: "动画延迟", editor: "zht-editor-text" },
    "style.animation-timing-function": {
        name: "动画速度曲线",
        editor: "zht-editor-enum",
        options: [
            { text: "linear", value: "linear" },
            { text: "ease", value: "ease" },
            { text: "ease-in", value: "ease-in" },
            { text: "ease-out", value: "ease-out" },
            { text: "ease-in-out", value: "ease-in-out" },
        ],
    },
    "style.animation-direction": {
        name: "动画重复方向",
        editor: "zht-editor-enum",
        options: [
            { text: "正向", value: "normal" },
            { text: "正反向", value: "alternate" },
        ],
    },

    "config.text": { name: "内容", editor: "zht-editor-text" },
    "config.selected": { name: "选项值", editor: "zht-editor-text" },
    "config.options": { name: "选项", editor: "zht-editor-options" },
    "config.map": {
        name: "行政区划",
        editor: "zht-editor-enum",
        options: [
            { text: "安徽", value: "anhui" },
            { text: "澳门", value: "aomen" },
            { text: "北京", value: "beijing" },
            { text: "重庆", value: "chongqing" },
            { text: "福建", value: "fujian" },
            { text: "甘肃", value: "gansu" },
            { text: "广东", value: "guangdong" },
            { text: "广西", value: "guangxi" },
            { text: "贵州", value: "guizhou" },
            { text: "海南", value: "hainan" },
            { text: "河北", value: "hebei" },
            { text: "黑龙江", value: "heilongjiang" },
            { text: "河南", value: "henan" },
            { text: "湖北", value: "hubei" },
            { text: "湖南", value: "hunan" },
            { text: "江苏", value: "jiangsu" },
            { text: "江西", value: "jiangxi" },
            { text: "吉林", value: "jilin" },
            { text: "辽宁", value: "liaoning" },
            { text: "内蒙古", value: "neimenggu" },
            { text: "宁夏", value: "ningxia" },
            { text: "青海", value: "qinghai" },
            { text: "山东", value: "shandong" },
            { text: "上海", value: "shanghai" },
            { text: "山西", value: "shanxi" },
            { text: "陕西", value: "shanxi1" },
            { text: "四川", value: "sichuan" },
            { text: "台湾", value: "taiwan" },
            { text: "天津", value: "tianjin" },
            { text: "香港", value: "xianggang" },
            { text: "新疆", value: "xinjiang" },
            { text: "西藏", value: "xizang" },
            { text: "云南", value: "yunnan" },
            { text: "浙江", value: "zhejiang" },
            { text: "其他", value: "other" },
        ],
    },
    "config.mapJson": { name: "地图数据", editor: "zht-editor-text" },
    "config.stateData": { name: "联机状态数据", editor: "zht-editor-text" },
    "config.alarmData": { name: "告警状态数据", editor: "zht-editor-text" },
    "config.legend": {
        name: "图例位置",
        editor: "zht-editor-enum",
        options: [
            { text: "左上", value: "left-top" },
            { text: "右上", value: "right-top" },
            { text: "左下", value: "left-bottom" },
            { text: "右下", value: "right-bottom" },
            { text: "隐藏", value: "hidden" },
        ],
    },
    "config.xData": { name: "横轴数据", editor: "zht-editor-text" },
    "config.yData": { name: "纵轴数据", editor: "zht-editor-text" },
    "config.pieRadius": { name: "饼图半径", editor: "zht-editor-text" },
    "config.min": { name: "最小值", editor: "zht-editor-text" },
    "config.max": { name: "最大值", editor: "zht-editor-text" },
    "config.startAngle": { name: "起始角度", editor: "zht-editor-text" },
    "config.endAngle": { name: "结束角度", editor: "zht-editor-text" },
    "config.value": { name: "值", editor: "zht-editor-text" },
    "config.unit": { name: "单位", editor: "zht-editor-text" },
    "config.rtsp": { name: "视频地址", editor: "zht-editor-rtsp" },
    "config.pageId": { name: "页面", editor: "zht-editor-page" },
    "config.tabs": { name: "导航项", editor: "zht-editor-tabs" },
    "config.pageContainer": { name: "子页浏览器ID", editor: "zht-editor-text" },
    "config.mapPoint": { name: "地图坐标点", editor: "zht-editor-mappoint" },
    "config.mapCenter": { name: "地图中心", editor: "zht-editor-lnglat" },
    "config.mapZoom": { name: "地图缩放级别", editor: "zht-editor-text" },
    "config.gaugeRadius": { name: "仪表直径", editor: "zht-editor-text" },
    "config.gaugeCenter": { name: "仪表中心", editor: "zht-editor-text" },
    "config.splitNumber": { name: "刻度分段", editor: "zht-editor-text" },

    "config.theme": {
        name: "风格",
        editor: "zht-editor-enum",
        options: [
            { text: "明亮", value: "light" },
            { text: "黑暗", value: "dark" },
        ],
    },
    "config.direction": {
        name: "方向",
        editor: "zht-editor-enum",
        options: [
            { text: "水平", value: "horizontal" },
            { text: "垂直", value: "vertical" },
        ],
    },
    "config.host": { name: "IP地址", editor: "zht-editor-text" },
    "config.port": { name: "IP端口", editor: "zht-editor-text" },
    "config.account": { name: "用户名", editor: "zht-editor-text" },
    "config.password": { name: "密码", editor: "zht-editor-password" },
    "config.manufacturer": {
        name: "视频厂商",
        editor: "zht-editor-enum",
        options: [
            { text: "海康", value: "1" },
        ],
    },
    "config.icon": { name: "图标", editor: "zht-editor-icon" },
    "config.csv": { name: "CSV数据", editor: "zht-editor-textarea" },
    "config.points": { name: "点坐标", editor: "zht-editor-textarea" },
    "config.pointsData": { name: "点数据", editor: "zht-editor-text" },
    "config.dense": {
        name: "间距",
        editor: "zht-editor-enum",
        options: [
            { text: "宽松", value: false },
            { text: "紧凑", value: true },
        ],
    },
    "config.chartOption": { name: "图表选项", editor: "zht-editor-textarea" },
    "config.deviceId": { name: "设备ID", editor: "zht-editor-device" },
    "config.spotNumbers": { name: "测点编号", editor: "zht-editor-text" },
    "config.cols": {
        name: "列数",
        editor: "zht-editor-enum",
        options: [
            { text: "1", value: 12 },
            { text: "2", value: 6 },
            { text: "3", value: 4 },
            { text: "4", value: 3 },
            { text: "6", value: 2 },
            { text: "12", value: 1 },
        ],
    },
    "config.alarmColor": { name: "告警前景色", editor: "zht-editor-color" },
    "config.alarmBorderColor": { name: "告警边框色", editor: "zht-editor-color" },
    "config.alarmBackgroundColor": { name: "告警背景色", editor: "zht-editor-color" },
    "config.alarmBoxShadow": { name: "告警阴影", editor: "zht-editor-shadow" },
    "config.lines": {
        name: "行数",
        editor: "zht-editor-enum",
        options: [
            { text: "单行", value: "single" },
            { text: "多行", value: "multi" },
        ],
    },
    "config.url": { name: "地址", editor: "zht-editor-text" },
    "config.svg": { name: "SVG", editor: "zht-editor-textarea" },
}