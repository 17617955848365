<template>
  <v-dialog :value="show" persistent max-width="480">
    <v-card>
      <v-card-title>
        <div>添加设备</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        v-model="device"
        :headers="headers"
        :items="devices"
        item-key="uuid"
        show-select
        single-select
        hide-default-header
      ></v-data-table>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="submit">确定</v-btn>
        <v-btn text @click="close">取消</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  props: { show: Boolean },
  model: {
    prop: "show",
    event: "change",
  },
  data() {
    return {
      headers: [{ text: "名称", value: "name" }],
      device: [],
      devices: [],
    };
  },
  watch: {
    show: function () {
      if (this.show) {
        this.device = [];
        this.init();
      }
    },
  },
  methods: {
    async init() {
      let result = [];
      client.$emit(
        "startBusy",
        "AddDeviceDialog.init",
        "正在获取设备,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.deviceMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
          act: 0,
        });
        if (res.devices) {
          res.devices.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i in res.devices) {
            result.push(res.devices[i]);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "AddDeviceDialog.init");
      this.devices = result;
    },
    submit() {
      if (this.device.length) {
        this.$emit("submit", this.device[0]);
      }
      this.close();
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>
