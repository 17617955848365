import Vue from 'vue';
import proto from './proto';
import base64 from './base64';
import store from './store';
const debug = false;

const client = new Vue({
    data() {
        return {
            /** socket layer */
            socket: null,
            /** states */
            appState: proto.AppState.init,
            chnState: proto.ChnState.close,
            /** proto layer */
            session: null,
            requests: {},
            loginRequest: null,
            timeoutChecker: null,
            heartTime: 0,
            /** caches */
            loginInfo: {},
            user: {},
            view: { id: '', name: '' },
            project: { id: '', name: '' },
            org: { id: '', name: '' },
            statistics: {
                's1': 0,//网点总数量
                's2': 0,//网点在线数量
                's3': 0,//网点离线数量
                's4': 0,//网点禁用数量
                's5': 0,//告警网点数量
                's6': 0,//当前告警数量 
                's7': 0,//未确认事件条数
                's8': 0,//未确认告警事件条数，除去告警恢复条数
                's9': 0,//监控对象总数量
                's10': 0,//监控对象在线数量
                's11': 0,//监控对象离线数量
                's12': 0,//告警监控对象数量
            },
            fullscreen: false,
        };
    },
    methods: {
        getAddress() {
            let isMini = navigator.userAgent.toLowerCase().indexOf('miniprogram') != -1;
            let protocol = window.location.protocol;
            let host = this.loginInfo.host;
            let port = this.loginInfo.port;
            let url;
            if (isMini) {
                url = `${protocol}//wx-ivisual.szzht.com/${host}/${port}`;
            } else if (port == 80) {
                url = `http://${host}`;
            } else if (port == 443) {
                url = `https://${host}`;
            } else {
                url = `${protocol}//${host}:${port}`;
            }
            return url;
        },
        init() {
            document.title = `iVisual`;
            this.timeoutChecker = setInterval(this.checkTimeout, 1000);
            let token = store.get('isLogin');
            if (token) {
                token = token.split('|');
                if (token.length == 5) {
                    this.loginInfo.host = base64.decode(token[0]);
                    this.loginInfo.port = base64.decode(token[1]);
                    this.loginInfo.account = base64.decode(token[2]);
                    this.loginInfo.password = base64.decode(token[3]);
                    this.loginInfo.developer = base64.decode(token[4]) == 'true';
                    this.appState = proto.AppState.login;
                    this.$emit('appState', this.appState);
                    this.connect();
                    return;
                }
            }
            this.appState = proto.AppState.anonymous;
            this.$emit('appState', this.appState);
        },
        cleanUp() {
            clearInterval(this.timeoutChecker);
        },
        connect() {
            if (this.chnState == proto.ChnState.close) {
                try {
                    this.chnState = proto.ChnState.connecting;
                    this.$emit('chnState', this.chnState);
                    let protocol = window.location.protocol == "http:" ? "ws:" : "wss:";
                    let host = this.loginInfo.host;
                    let port = this.loginInfo.port;
                    let url = `${protocol}//${host}:${port}/imcp`;
                    let isMini = navigator.userAgent.toLowerCase().indexOf('miniprogram') != -1;
                    if (isMini) {
                        url = `${protocol}//wx-ivisual.szzht.com/imcp/${host}/${port}`;
                    }
                    if (debug) console.log('socket.connect', url);
                    this.socket = new WebSocket(url);
                    this.socket.onopen = this.onopen.bind(this);
                    this.socket.onmessage = this.onmessage.bind(this);
                    this.socket.onclose = this.onclose.bind(this);
                } catch (e) {
                    console.log(e);
                    this.onclose(e);
                }
            }
        },
        onopen() {
            this.chnState = proto.ChnState.handshake;
            this.$emit('chnState', this.chnState);
            this.session = null;
        },
        async onmessage(msg) {
            let data = await new Response(msg.data).arrayBuffer();
            if (debug) console.log('socket.recv', data.byteLength);
            if (this.chnState == proto.ChnState.handshake) {
                let handshake = String.fromCharCode.apply(null, new Uint8Array(data));
                this.session = parseInt(handshake);
                if (handshake <= 0) {
                    if (this.loginRequest && this.loginRequest.reject) this.loginRequest.reject('通讯握手失败');
                    this.loginRequest = null;
                    if (debug) console.log('socket.close onmessage1');
                    this.socket.close();
                    return;
                }
                handshake = 'IMOBILE/' + handshake;
                var handshakeData = new ArrayBuffer(handshake.length);
                var view = new Uint8Array(handshakeData);
                for (var i = 0, strLen = handshake.length; i < strLen; i++) {
                    view[i] = handshake.charCodeAt(i);
                }
                if (debug) console.log('socket.send', handshakeData.byteLength);
                this.socket.send(handshakeData);
                this.chnState = proto.ChnState.logining;
                this.$emit('chnState', this.chnState);
                setTimeout(function () {
                    let loginMsg = this.encodeWithHeader(proto.MESSAGE_TYPE.userAuthen, {
                        mcd: { operate: proto.OperateMode.createOpt },
                        authmode: 1,
                        user: {
                            uuid: "IMCP",
                            account: this.loginInfo.account,
                            password: this.loginInfo.password,
                            type: this.loginInfo.developer ? proto.USER_TYPE.SYSTEM : proto.USER_TYPE.PROJECT,
                        },
                    }, this.session);
                    if (debug) console.log('socket.send', loginMsg.length);
                    this.socket.send(loginMsg);
                }.bind(this), 1000);
            } else {
                if (data.byteLength <= 12) {
                    if (this.loginRequest && this.loginRequest.reject) {
                        this.loginRequest.reject('消息长度有误');
                        this.loginRequest = null;
                    } else {
                        this.$emit('toast', '消息长度有误', data.byteLength);
                    }
                    if (debug) console.log('socket.close onmessage2');
                    this.socket.close();
                    return;
                }
                let headerData = new Uint8Array(data, 0, 12);
                let bodyData = new Uint8Array(data, 12);
                let header = this.decode(0, headerData);
                if (header.length != bodyData.length) {
                    if (this.loginRequest && this.loginRequest.reject) {
                        this.loginRequest.reject('消息内容长度有误');
                        this.loginRequest = null;
                    } else {
                        this.$emit('toast', '消息内容长度有误', data.byteLength);
                    }
                    if (debug) console.log('socket.close onmessage3');
                    this.socket.close();
                    return;
                }
                let message = this.decode(header.type, bodyData);
                if (this.chnState == proto.ChnState.logining) {
                    if (debug) console.log('recvMessage', header.type, undefined, message);
                    if (message.mcd.optResult) {
                        let token = [
                            base64.encode(this.loginInfo.host),
                            base64.encode(this.loginInfo.port.toString()),
                            base64.encode(this.loginInfo.account),
                            base64.encode(this.loginInfo.password),
                            base64.encode(this.loginInfo.developer ? "true" : "false")
                        ].join('|');
                        store.set('isLogin', token);
                        this.user = message.user;
                        if (this.loginRequest && this.loginRequest.resolve) this.loginRequest.resolve(message);
                        this.loginRequest = null;
                        this.appState = proto.AppState.login;
                        this.chnState = proto.ChnState.ready;
                        this.$emit('appState', this.appState);
                        this.$emit('chnState', this.chnState);
                        this._sendLast();
                    } else {
                        if (this.loginRequest && this.loginRequest.reject) this.loginRequest.reject(message.mcd.errMsg);
                        else this.$emit('toast', message.mcd.errMsg);
                        this.loginRequest = null;
                        if (debug) console.log('socket.close onmessage4');
                        this.socket.close();
                        return;
                    }
                }
                let id, info;
                if (message.mcd) {
                    id = message.mcd.msgsequence;
                    info = this.requests['' + id];
                }
                if (info) {
                    if (message.mcd.optResult) {
                        info.resolve(message);
                    } else {
                        info.reject(message.mcd.errMsg);
                    }
                    delete this.requests['' + id];
                    if (debug) console.log('recvMessage', header.type, id, message);
                } else {
                    this.$emit('brocast', header.type, message);
                    if (header.type == 58) {
                        if (message.rtstatistics) {
                            for (let i in message.rtstatistics) {
                                let kv = message.rtstatistics[i];
                                this.statistics[`s${kv.key}`] = kv.value;
                            }
                        }
                    }
                    if (header.type == 6) {
                        if (message.mcd.range == 'auth') {
                            this.$emit('toast', '软件未授权, 请联系供应商! Unauthorized software, please contact the supplier! ');
                        }
                    }
                    if (debug) console.log('pushMessage', header.type, id, message);
                }
                if (debug) console.log('remain', Object.keys(this.requests));
            }
        },
        onclose(e) {
            let code = e?.code || '';
            if (code) code = `[${code}] `;
            console.log(code, 'onclose');
            let tips;
            if (this.chnState == proto.ChnState.connecting) {
                tips = `${code}iVisual Server连接失败`;
            } else if (this.chnState == proto.ChnState.handshake) {
                tips = `${code}iCore连接失败`;
            }
            else if (this.chnState == proto.ChnState.logining && this.loginRequest && this.loginRequest.reject) {
                tips = `${code}发送登录消息时，iCore主动断开连接`;
            }
            if (tips) {
                if (this.loginRequest && this.loginRequest.reject) this.loginRequest.reject(tips);
                else this.$emit('toast', tips);
                this.loginRequest = null;
            }
            this.chnState = proto.ChnState.close;
            this.$emit('chnState', this.chnState);
            setTimeout(function () {
                if (this.appState == proto.AppState.login) {
                    this.connect();
                }
            }.bind(this), 5000);
        },
        decode(messageType, buffer) {
            let Type = proto.Types[messageType];
            if (!Type) throw new Error('PB Decode Unknown MessageType: ' + messageType);
            return Type.toObject(Type.decode(buffer), { defaults: true, enums: Number, bytes: String });
        },
        encode(messageType, message) {
            let Type = proto.Types[messageType];
            if (!Type) throw new Error('PB Encode Unknown MessageType: ' + messageType);
            let error = Type.verify(message);
            if (error) throw Error(error);
            let pbMessage = Type.create(message);
            return Type.encode(pbMessage).finish();
        },
        encodeWithHeader(messageType, message, session) {
            if (debug) console.log('sendMessage', messageType, message.mcd.msgsequence, message);
            let HeaderType = proto.Types[0];
            if (!HeaderType) throw new Error('PB Encode With Header Null HeaderType');
            let bodyBuffer = this.encode(messageType, message);
            let header = { type: messageType, length: bodyBuffer.length, session };
            let error = HeaderType.verify(header);
            if (error) throw Error(error);
            let headerMessage = HeaderType.create(header);
            let headerBuffer = HeaderType.encode(headerMessage).finish();
            let a = proto.bufferToArray(headerBuffer);
            let b = proto.bufferToArray(bodyBuffer);
            return proto.arrayToBuffer(a.concat(b));
        },
        async login(host, port, account, password, developer) {
            this.loginInfo.host = host || 'localhost';
            this.loginInfo.port = port || 6780;
            this.loginInfo.account = account || '';
            this.loginInfo.password = password || '';
            this.loginInfo.developer = developer || false;
            return new Promise((resolve, reject) => {
                this.loginRequest = { resolve, reject };
                this.connect();
            });
        },
        logout() {
            store.del('isLogin');
            this.requests = {};
            this.project = { id: '', name: '' };
            this.org = { id: '', name: '' };
            this.appState = proto.AppState.anonymous;
            this.$emit('appState', this.appState);
            if (this.socket) {
                if (this.chnState != proto.ChnState.close) {
                    this.chnState = proto.ChnState.closing;
                    this.$emit('chnState', this.chnState);
                }
                if (debug) console.log('socket.close logout');
                this.socket.close();
            }
            document.title = `iVisual`;
        },
        print(type, message) {
            let id = '1' + Math.random().toFixed(7).substr(2);
            let msg = JSON.parse(JSON.stringify(message));
            msg.mcd.msgsequence = parseInt(id);
            msg.mcd.orgid = this.org.id;
            msg.mcd.projectId = this.project.id;
            let buf = this.encode(type, msg);
            let model = this.decode(type, buf);
            console.log('print', model);
        },
        send(type, message) {
            return new Promise((resolve, reject) => {
                if (!message) {
                    reject('message undefined');
                    return;
                }
                try {
                    this.checkMessage(message, type);
                } catch (e) {
                    reject(e.toString());
                    return;
                }
                let id = '1' + Math.random().toFixed(7).substr(2);
                this.requests[id] = { id, type, message, time: Date.now(), resolve, reject };
                this._send(id);
            });
        },
        checkMessage(message, path) {
            let type = Object.prototype.toString.call(message);
            switch (type) {
                case "[object Object]":
                    for (let key in message) {
                        this.checkMessage(message[key], `${path}.${key}`);
                    }
                    break;
                case "[object Array]":
                    for (let i in message) {
                        this.checkMessage(message[i], `${path}`);
                    }
                    break;
                case "[object String]":
                    if (message.match(/[']+/)) {
                        if (path.indexOf('conditionClause') == -1 && path.indexOf('scopeexpr') == -1) {
                            console.log('Warm:', path, message);
                            throw '此请求已被屏蔽：提交参数带有非法字符，会损害系统数据';
                        }
                    }
                    break;
            }
        },
        setProject(id, name) {
            this.project = { id, name };
            this.$emit("projectChange");
        },
        setOrg(id, name) {
            this.org = { id, name };
            this.$emit("orgChange");
        },
        checkTimeout() {
            if (this.chnState == proto.ChnState.ready) {
                let keys = Object.keys(this.requests);
                let now = Date.now();
                let timeout = now - 30000;
                if (this.heartTime < timeout) {
                    this.heartTime = now;
                    this.send(proto.MESSAGE_TYPE.realTimeDataMessage, {
                        mcd: {
                            operate: proto.OperateMode.retrieveOpt,
                        },
                        rtdata: [],
                    });
                }
                for (let i in keys) {
                    let key = keys[i];
                    let info = this.requests[key];
                    if (info && info.time < timeout) {
                        this.$emit('toast', `[${info.type}]请求超时`);
                        info.time = Number.MAX_VALUE;
                        if (debug) console.log('socket.close checkTimeout');
                        if (this.socket) this.socket.close();
                    }
                }
            }
        },
        _sendLast() {
            let keys = Object.keys(this.requests);
            for (let i in keys) {
                let key = keys[i];
                let info = this.requests[key];
                if (!info.resend) {
                    info.time = Date.now();
                    info.resend = true;
                    this._send(key);
                }
                else {
                    info.reject(`[${info.type}]因服务端多次无响应，客户端取消此操作`);
                    delete this.requests[key];
                }
            }
        },
        _send(id) {
            if (this.chnState == proto.ChnState.ready) {
                let info = this.requests[id];
                let msg = JSON.parse(JSON.stringify(info.message));
                msg.mcd.msgsequence = parseInt(id);
                if (!msg.mcd.orgid) msg.mcd.orgid = this.org.id;
                msg.mcd.projectId = this.project.id;
                let data = this.encodeWithHeader(info.type, msg, this.session);
                if (debug) console.log('socket.send', data.length);
                this.socket.send(data);
            }
        },
    },
})
export default client;