<style>
.assets.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: #41737b !important;
}
</style>
<template>
  <div class="main">
    <v-navbar>
      <v-btn text class="link-text" @click="save">
        <span>保存</span>
      </v-btn>
      <v-btn text class="link-text" @click="edit">
        <span>编辑</span>
      </v-btn>
    </v-navbar>
    <div class="scroll">
      <v-data-table
        dark
        :headers="headers"
        :items="items"
        v-model="selected"
        item-key="id"
        show-select
        style="background-color: transparent"
        hide-default-footer
        disable-pagination
        class="assets"
      >
      </v-data-table>
    </div>
    <v-navigation-drawer
      v-model="editorShow"
      absolute
      temporary
      right
      width="240"
    >
      <div v-if="editModel" class="d-flex flex-column fill-height">
        <div class="flex-grow-1 bg-editor" style="overflow: auto">
          <v-text-field
            v-for="attr in basicEditors"
            :key="`model-${attr.key}`"
            :label="attr.name"
            v-model="editModel[attr.key]"
            :readonly="attr.readonly || false"
            filled
            background-color="transparent"
            hide-details
            @input="onInput($event, attr.key)"
          ></v-text-field>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
import base64 from "../utils/base64";

const basicEditors = [
  { key: "id", name: "ID", readonly: true },
  { key: "name", name: "名称", readonly: true },
  { key: "brand", name: "品牌" },
  { key: "model", name: "型号" },
  { key: "vendor", name: "供应商" },
  { key: "purchaseDate", name: "购买日期" },
  { key: "expiredDate", name: "质保日期" },
  { key: "responsible", name: "负责人" },
  { key: "contact", name: "联系方式" },
  { key: "maintenance", name: "维修记录" },
  { key: "description", name: "备注" },
];

export default {
  data() {
    return {
      selected: [],
      items: [],
      basicEditors,
      editorShow: false,
      editModel: basicEditors.reduce((p, c) => {
        if (p.key == "id") {
          let obj = {};
          obj[p.key] = "";
          obj[c.key] = "";
          return obj;
        } else {
          p[c.key] = "";
          return p;
        }
      }),
      headers: basicEditors
        .filter((h) => h.key != "id")
        .map((h) => {
          return { text: h.name, value: h.key };
        }),
      json: "{}",
      changes: false,
      isAdd: true,
    };
  },
  mounted() {
    client.$on("orgChange", this.orgChange);
    this.init();
  },
  beforeDestroy() {
    client.$off("orgChange", this.orgChange);
    this.save();
  },
  methods: {
    async orgChange() {
      await this.save();
      client.$emit("startBusy", "Asset.orgChange", "正在获取设备,请稍候...");
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.deviceMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
            parentId: client.org.id,
          },
          act: 2,
        });
        let items = [];
        let dict;
        try {
          dict = JSON.parse(this.json);
        } catch (e) {
          dict = {};
        }
        if (res.devices && res.devices.length) {
          res.devices.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.devices.length; i++) {
            let device = res.devices[i];
            let model;
            if (dict[device.uuid]) {
              model = dict[device.uuid];
            } else {
              model = {};
            }
            model.id = device.uuid;
            model.name = device.name;
            items.push(model);
          }
        }
        this.selected = [];
        this.items = items;
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "Asset.orgChange");
    },
    async init() {
      client.$emit("startBusy", "Asset.init", "正在获取资产信息,请稍候...");
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.resourceMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: client.project.id,
          },
          resType: 2,
        });
        if (res.resources && res.resources.length == 1) {
          this.isAdd = false;
          let item = res.resources[0];
          try {
            this.json = base64.decode(item.content);
          } catch (e) {
            this.json = "{}";
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "Asset.init");
      this.orgChange();
    },
    async save() {
      if (this.changes) {
        this.changes = false;
        client.$emit("startBusy", "Asset.save", "正在保存资产信息,请稍候...");
        await proto.sleep(100);
        try {
          let dict;
          try {
            dict = JSON.parse(this.json);
          } catch (e) {
            dict = {};
          }
          for (let i in this.items) {
            let model = JSON.parse(JSON.stringify(this.items[i]));
            for (let attr in model) {
              if (model[attr] == "") delete model[attr];
            }
            dict[model.id] = model;
          }
          this.json = JSON.stringify(dict);
          await client.send(proto.MESSAGE_TYPE.resourceMessage, {
            mcd: {
              operate: this.isAdd
                ? proto.OperateMode.createOpt
                : proto.OperateMode.updateOpt,
              range: "-1",
            },
            resType: 2,
            resources: [
              {
                uuid: client.project.id,
                libtype: 1,
                name: "工程资产信息请勿删除",
                content: base64.encode(this.json),
              },
            ],
          });
        } catch (error) {
          client.$emit("toast", error);
        }
        client.$emit("endBusy", "Asset.save");
      }
    },
    edit() {
      if (this.selected.length == 1) {
        for (let i in basicEditors) {
          let attr = basicEditors[i];
          this.editModel[attr.key] = this.selected[0][attr.key] || "";
        }
      } else if (this.selected.length > 1) {
        for (let i in basicEditors) {
          let attr = basicEditors[i];
          this.editModel[attr.key] = "";
        }
        this.editModel.id = "<多条选中项>";
        this.editModel.name = "<多条选中项>";
      } else {
        return;
      }
      this.editorShow = true;
    },
    onInput(value, attr) {
      this.changes = true;
      for (let i in this.selected) {
        this.selected[i][attr] = value;
      }
    },
  },
};
</script>
